$dark: #000000;
$secondary: #9c9c9b;
$danger: #f21e00;

@import "../../node_modules/bootstrap/scss/bootstrap.scss";

.container-fluid {
  padding: 0 2rem !important;
}

::selection,
::-webkit-selection {
  color: #00190a;
  background: #f5ff01;
  border-radius: 5rem;
}
