@charset "UTF-8";

:root {
    --color-text: #1a1818;
    --font-family-poppins: 'Poppins', sans-serif !important;
}
@font-face 
{
    font-family: 'Poppins', sans-serif;
    src: url("../fonts/poppins-regular.ttf");
}
body 
{
    font-family: var(--font-family-poppins);
}
a
{
    font-family: var(--font-family-poppins);
    text-decoration: none !important;
}

.owl-carousel .owl-item img {
    height: auto;
}

.owl-loaded .owl-stage::after {
    content: "";
}

.owl-carousel:not(.owl-loaded) {
    flex-wrap: nowrap;
    overflow: hidden;
}

.owl-carousel:not(.owl-loaded)[class*="cols-"]:not(.gutter-no) {
    margin-left: -10px !important;
    margin-right: -10px !important;
    width: auto;
}

.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 1.5rem;
}

.owl-theme.owl-shadow-carousel .owl-nav.disabled+.owl-dots {
    margin-top: 4rem;
}

.owl-theme .owl-nav .owl-prev,
.owl-theme .owl-nav .owl-next {
    margin: 0 !important;
    transform: translateY(-50%) !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    color: var(--base-color) !important;
}

.cat_new_carosal button.owl-prev {
    margin: 0 !important;
    transform: translateY(-50%) !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    color: var(--base-color) !important;
}

.cat_new_carosal button.owl-next {
    margin: 0 !important;
    transform: translateY(-50%) !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    color: var(--base-color) !important;
}

/* .cat_new_carosal .owl-nav
{
        position: absolute;
        content: '';
        top: 300px;
        left: -60px;
} */
.cat_new_carosal .owl-nav .owl-next {
    position: absolute;
    content: '';
    top: 50%;
    right: -5%;
}

.cat_new_carosal .owl-nav .owl-prev {
    position: absolute;
    content: '';
    top: 50%;
    left: -5%;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin: 0;
    margin-top: -15px;
    color: white;
    font-size: 16px;
    line-height: 1;
    padding: 0;
    border: 0.1rem solid #ebebeb;
    background: var(--base-color) !important;
    cursor: pointer;
    border-radius: 50px;
    transition: all .3s ease;
}

.cat_new_carosal button.owl-prev.disabled span,
button.owl-next span {
    color: #000;
    background: var(--base-color) !important;
}

.cat_new_carosal button.owl-prev.disabled {
    position: absolute;
    top: 50%;
    left: -5%;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin: 0;
    margin-top: -15px;
    color: #2b579a;
    font-size: 16px;
    line-height: 1;
    padding: 0;
    border: 0.1rem solid #ebebeb;
    background: var(--base-color) !important;
    cursor: pointer;
    border-radius: 50px;
    transition: all .3s ease;
}

.cat_new_carosal button.owl-next {

    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin: 0;
    margin-top: -15px;
    color: #2b579a;
    font-size: 16px;
    line-height: 1;
    padding: 0;
    border: 0.1rem solid #ebebeb;
    background: var(--base-color) !important;
    cursor: pointer;
    border-radius: 50px;
    transition: all .3s ease;
}

.owl-theme .owl-nav .owl-prev i::before,
.owl-theme .owl-nav .owl-next i::before {
    margin: 0;
}

.owl-theme .owl-nav .owl-prev:not(.disabled):hover,
.owl-theme .owl-nav .owl-prev:not(.disabled):active .owl-theme .owl-nav .owl-prev:not(.disabled):focus,
.owl-theme .owl-nav .owl-next:not(.disabled):hover,
.owl-theme .owl-nav .owl-prev:not(.disabled):active .owl-theme .owl-nav .owl-next:not(.disabled):focus,
.owl-theme .owl-nav .owl-next:not(.disabled):active .owl-theme .owl-nav .owl-prev:not(.disabled):focus,
.owl-theme .owl-nav .owl-next:not(.disabled):active .owl-theme .owl-nav .owl-next:not(.disabled):focus {
    background: var(--base-color) !important;
    border: none !important;
    color: #fff !important;
}

.owl-theme .owl-nav .owl-prev.disabled,
.owl-theme .owl-nav .owl-next.disabled {
    color: #ccc !important;
    background: var(--base-color) !important;
}

.owl-theme .owl-nav .owl-prev {
    left: -60px !important;
    top: -200px !important;
}

.owl-theme .owl-nav .owl-next {
    right: -60px !important;
    top: -200px !important;
}

.owl-theme .owl-dots .owl-dot.active span {
    background-color: #4dae67;
    border-color: #4dae67;
}

.owl-theme .owl-dots .owl-dot span {
    margin: 5px;
}

@media (max-width: 1300px) {
    .owl-theme .owl-nav .owl-prev {
        left: 20px !important;
    }

    .owl-theme .owl-nav .owl-next {
        right: 20px !important;
    }
}

.owl-nav-full .owl-nav {
    opacity: 0;
    transition: opacity 0.4s;
}

.owl-nav-full .owl-nav .owl-prev,
.owl-nav-full .owl-nav .owl-next {
    margin: 0;
    top: 0;
    height: 100%;
    transform: none;
    border: 0;
    font-size: 3rem;
    font-weight: 400;
    color: #333;
    border-radius: 0;
    background-color: rgba(255, 255, 255, 0.8);
    transition: 0.4s;
}

.owl-nav-full .owl-nav .owl-prev:not(.disabled):hover,
.owl-nav-full .owl-nav .owl-next:not(.disabled):hover {
    color: #4dae67;
    background-color: rgba(255, 255, 255, 0.8);
}

.owl-nav-full .owl-nav .owl-prev.disabled,
.owl-nav-full .owl-nav .owl-next.disabled {
    visibility: hidden;
    opacity: 0;
}

.owl-nav-full .owl-nav .owl-prev {
    left: 0;
    box-shadow: 4px 0px 7px -5px rgba(0, 0, 0, 0.2);
}

.owl-nav-full .owl-nav .owl-next {
    right: 0;
    box-shadow: -4px 0px 7px -5px rgba(0, 0, 0, 0.2);
}

.owl-nav-full:hover .owl-nav {
    opacity: 1;
}

.owl-nav-full:hover .owl-prev {
    left: 0;
}

.owl-nav-full:hover .owl-next {
    right: 0;
}

.owl-nav-arrow .owl-nav .owl-next,
.owl-nav-arrow .owl-nav .owl-prev {
    margin: 0;
    color: #666;
    font-weight: 400;
    width: 1em;
    height: 1em;
    font-size: 4.5rem;
    border: none;
}

.owl-nav-arrow .owl-nav .owl-next i,
.owl-nav-arrow .owl-nav .owl-prev i {
    display: none;
}

.owl-nav-arrow .owl-nav .owl-next::before,
.owl-nav-arrow .owl-nav .owl-prev::before {
    font-family: "riode";
}

.owl-nav-arrow .owl-nav .owl-next:not(.disabled):hover,
.owl-nav-arrow .owl-nav .owl-next:not(.disabled):active,
.owl-nav-arrow .owl-nav .owl-next:not(.disabled):focus,
.owl-nav-arrow .owl-nav .owl-prev:not(.disabled):hover,
.owl-nav-arrow .owl-nav .owl-prev:not(.disabled):active,
.owl-nav-arrow .owl-nav .owl-prev:not(.disabled):focus {
    background-color: transparent;
    color: #4dae67;
}

.owl-nav-arrow .owl-nav .owl-next.disabled,
.owl-nav-arrow .owl-nav .owl-prev.disabled {
    color: #aaa;
}

.owl-nav-arrow .owl-nav .owl-next {
    right: 5%;
}

.owl-nav-arrow .owl-nav .owl-next::before {
    content: "";
}

.owl-nav-arrow .owl-nav .owl-prev {
    left: 5%;
    margin-right: 20px;
}

.owl-nav-arrow .owl-nav .owl-prev::before {
    content: "";
}

.owl-nav-inner .owl-nav .owl-prev,
.owl-nav-inner .owl-nav .owl-next {
    opacity: 0;
    visibility: hidden;
}

.owl-nav-inner .owl-nav .owl-prev {
    left: 0;
}

.owl-nav-inner .owl-nav .owl-next {
    right: 0;
}

.owl-nav-inner:hover .owl-prev,
.owl-nav-inner:hover .owl-next {
    opacity: 1;
    visibility: visible;
}

.owl-nav-inner:hover .owl-prev {
    left: 2rem;
}

.owl-nav-inner:hover .owl-next {
    right: 2rem;
}

.owl-nav-top .owl-nav {
    position: absolute;
    display: flex;
    top: -5.3rem;
    right: -0.4rem;
    color: #ccc;
}

.owl-nav-top .owl-nav .owl-next:not(.disabled) .owl-prev:not(.disabled) {
    color: #777;
}

.owl-nav-top .owl-nav .owl-prev,
.owl-nav-top .owl-nav .owl-next {
    position: static;
    transform: translateY(-11%);
}

.owl-nav-top .owl-nav .owl-next {
    margin-right: 5px;
}

.owl-nav-top .owl-nav i {
    padding: 0.1rem 0.5rem;
    font-size: 1.8rem;
    font-weight: 700;
}

.owl-nav-top .owl-nav i::before {
    margin: 0;
}

.owl-nav-top.owl-nav-arrow .owl-nav .owl-prev,
.owl-nav-top.owl-nav-arrow .owl-nav .owl-next {
    font-size: 3.4rem;
}

.owl-nav-bottom .owl-nav {
    position: absolute;
    bottom: 1.7rem;
    right: -0.4rem;
    color: #999;
}

.owl-nav-bottom .owl-nav i {
    font-size: 2rem;
    font-weight: 700;
}

.owl-nav-bottom .owl-nav i::before {
    margin: 0;
}

.owl-nav-bottom .owl-prev {
    margin-right: 0.8rem;
}

.owl-dot-white .owl-dots .owl-dot span {
    background-color: #fff;
    border-color: #fff;
    opacity: 0.8;
}

.owl-dot-white .owl-dots .owl-dot.active span {
    background-color: #fff;
    border-color: #fff;
    opacity: 1;
}

.owl-dot-grey .owl-dots .owl-dot span {
    background-color: #efefef;
    border-color: #efefef;
}

.owl-dot-grey .owl-dots .owl-dot.active span {
    background-color: #999;
    border-color: #999;
}

.owl-dot-dark .owl-dots .owl-dot span {
    background-color: #a1a1a1;
    border-color: #a1a1a1;
}

.owl-dot-dark .owl-dots .owl-dot.active span {
    background-color: #333;
    border-color: #333;
}

.owl-dot-inner .owl-dots {
    position: absolute;
    bottom: 4.5rem;
    left: 50%;
    transform: translateX(-50%);
}

.brand-carousel .owl-stage {
    display: flex;
    align-items: center;
}

.brand-carousel .owl-item img {
    width: auto;
    margin: auto;
}

.brand-carousel:not(.loaded) {
    text-align: center;
    align-items: center;
}

.owl-carousel .slide-animate {
    transform: translate3d(0, 0, 0) scale(1);
    will-change: filter, transform, opacity;
    visibility: hidden;
    opacity: 0;
}

.owl-carousel .slide-animate.show-content {
    visibility: visible;
    opacity: 1;
}

.owl-full-height,
.owl-full-height .owl-stage-outer,
.owl-full-height .owl-stage,
.owl-full-height .owl-item,
.owl-same-height .owl-item>* {
    height: 100%;
}

.owl-same-height .owl-stage {
    display: flex;
}

.owl-full-height .owl-item img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.owl-shadow-carousel .owl-stage-outer {
    margin: -30px;
    padding: 30px;
}

.owl-nav-bg .owl-nav .owl-prev {
    left: 15.4%;
}

.owl-nav-bg .owl-nav .owl-next {
    right: 15.4%;
}

.owl-nav-bg .owl-nav [class*="owl-"] {
    width: 4.8rem;
    height: 4.8rem;
    border-width: 2px;
    font-size: 3.2rem;
    color: #666;
    border-color: #666;
}

.owl-nav-bg .owl-nav [class*="owl-"].disabled {
    border-color: #acabab;
}

.owl-nav-bg .owl-nav i {
    line-height: 1;
    margin-bottom: 2px;
}

.owl-nav-fade .owl-nav .owl-prev {
    opacity: 0;
    margin-left: 4rem;
}

.owl-nav-fade .owl-nav .owl-next {
    opacity: 0;
    margin-right: 4rem;
}

.owl-nav-fade:hover .owl-nav .owl-prev {
    opacity: 1;
    margin-left: 0;
}

.owl-nav-fade:hover .owl-nav .owl-next {
    opacity: 1;
    margin-right: 0;
}

.owl-split .owl-item:not(:last-child) {
    position: relative;
}

.owl-split .owl-item:not(:last-child)::after {
    content: "";
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #ebebeb;
}

.owl-middle .owl-stage {
    display: flex;
    align-items: center;
}

.rotate-slider {
    padding-left: 17.9%;
}

.rotate-slider .owl-stage-outer {
    margin: -20px;
    padding: 20px;
    padding-left: 23%;
    margin-left: -23%;
}

.rotate-slider .owl-item {
    transition: 1s;
    box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.1);
}

.rotate-slider .owl-item:not(.active) {
    transform: scale(0.8) translateX(100%);
    transform-origin: left;
}

.rotate-slider .owl-nav .owl-next {
    right: auto;
    left: 40px;
    font-size: 36px;
}

.rotate-slider .owl-nav .owl-next::before {
    content: "";
}

.rotate-slider .owl-nav .owl-prev {
    display: none;
}

@media (max-width: 479px) {
    .rotate-slider .owl-nav .owl-next {
        right: auto;
        left: 15px;
        font-size: 24px;
    }
}

/* -------------------------------------------
    No UiSlider
---------------------------------------------- */
.noUi-target {
    background: #eee;
    margin: 4px 16px 3rem 9px;
}

.noUi-connects {
    padding: 11.5px 0;
    top: -10px;
}

.noUi-connect {
    background: #999;
    margin-top: 11px;
    height: 3px;
}

.noUi-horizontal .noUi-handle {
    top: -0.6rem;
    width: 1.2rem;
    height: 1.7rem;
    background: #444;
    border-radius: 3px;
}

/* 4. base */
/* -------------------------------------------
    Base
---------------------------------------------- */
*,
::after,
::before {
    box-sizing: inherit;
}

html {
    font-size: 62.5%;
    font-size-adjust: 100%;
    font-weight: 400;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html.dark-theme {
    background-color: #151515;
}

.riode-rounded-skin {
    margin: 0;
    overflow-x: hidden;
    font-family: var(--font-family-poppins);
    font-size: 16px;
    line-height: 1.6;
    color: #666;
}

.description-title {
    font-size: 28px;
    line-height: 28px;
    color: var(--color-text);
    font-weight: 700 !important;
    font-family: var(--font-family-poppins);
    margin-bottom: 40px !important;
}

main {
    display: block;
    position: relative;
    background-color: #fff;
}

.riode-rounded-skin:not(.loaded) * {
    transition: all 0s !important;
}

.page-wrapper {
    position: relative;
    transition: margin 0.4s;
}

table {
    width: 100%;
    border-collapse: collapse;
}

table td,
table th {
    padding: 0;
}

.section {
    padding: 7rem 0;
}

.grey-section {
    background: #f6f7f9;
}

.background-section {
    background-repeat: no-repeat;
}

.parallax {
    background-color: #3c3f41;
}

ul {
    padding-left: 1.5em;
}

.menu,
.menu ul,
.mobile-menu,
.mobile-menu ul,
.nav,
.nav ul,
.widget-body,
.widget-body ul,
.list,
.breadcrumb,
.filter-items,
.select-menu>ul,
.dropdown-box,
.pagination,
.nav-filters,
.category ul,
.comments ul,
.product-nav,
.product-tabs>div ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: inherit;
    transition: color 0.3s;
}

a:hover {
    color: #4dae67;
}

:focus {
    outline: 0;
}

figure {
    margin: 0;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

hr {
    margin-bottom: 2rem;
    border: 0;
    border-top: 1px solid #eee;
}

input {
    /* -webkit-appearance: none; */
    border: 0;
    padding: 0;
    font-family: var(--font-family-poppins);
}

input:focus {
    outline: 0;
}

.input-wrapper>input,
.input-wrapper>button,
.input-group>input,
.input-group>button {
    margin: 0px;
}

i {
    font-style: normal;
}

button:focus {
    outline: none;
}

.scrollable,
.sidebar-content {
    -webkit-overflow-scrolling: touch;
}

.scrollable::-webkit-scrollbar,
.sidebar-content::-webkit-scrollbar {
    height: 7px;
    width: 4px;
}

.scrollable::-webkit-scrollbar-thumb,
.sidebar-content::-webkit-scrollbar-thumb {
    margin-right: 2px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
}

.scrollable-light::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
}

@keyframes bouncedelay {

    0%,
    80%,
    100% {
        transform: scale(1);
    }

    40% {
        transform: scale(1.2);
    }
}

@keyframes rotatedelay {
    0% {
        transform: rotateZ(0);
    }

    100% {
        transform: rotateZ(360deg);
    }
}

@keyframes reveal-1 {
    0% {
        transform: rotate3d(0, 0, 1, 135deg) translate3d(0, 0, 0);
        border-radius: 50%;
    }

    20% {
        transform: rotate3d(0, 0, 1, 135deg) translate3d(55%, 55%, 0);
        border-radius: 0;
    }

    80% {
        transform: rotate3d(0, 0, 1, 495deg) translate3d(55%, 55%, 0);
        border-radius: 0;
    }

    100% {
        transform: rotate3d(0, 0, 1, 495deg) translate3d(0, 0, 0);
        border-radius: 50%;
    }
}

@keyframes reveal-2 {
    0% {
        transform: rotate3d(0, 0, 1, 135deg) translate3d(0, 0, 0);
        border-radius: 50%;
    }

    20% {
        transform: rotate3d(0, 0, 1, 135deg) translate3d(55%, -55%, 0);
        border-radius: 0;
    }

    80% {
        transform: rotate3d(0, 0, 1, 495deg) translate3d(55%, -55%, 0);
        border-radius: 0;
    }

    100% {
        transform: rotate3d(0, 0, 1, 495deg) translate3d(0, 0, 0);
        border-radius: 50%;
    }
}

@keyframes reveal-3 {
    0% {
        transform: rotate3d(0, 0, 1, 135deg) translate3d(0, 0, 0);
        border-radius: 50%;
    }

    20% {
        transform: rotate3d(0, 0, 1, 135deg) translate3d(-55%, -55%, 0);
        border-radius: 0;
    }

    80% {
        transform: rotate3d(0, 0, 1, 495deg) translate3d(-55%, -55%, 0);
        border-radius: 0;
    }

    100% {
        transform: rotate3d(0, 0, 1, 495deg) translate3d(0, 0, 0);
        border-radius: 50%;
    }
}

@keyframes reveal-4 {
    0% {
        transform: rotate3d(0, 0, 1, 135deg) translate3d(0, 0, 0);
        border-radius: 50%;
    }

    20% {
        transform: rotate3d(0, 0, 1, 135deg) translate3d(-55%, 55%, 0);
        border-radius: 0;
    }

    80% {
        transform: rotate3d(0, 0, 1, 495deg) translate3d(-55%, 55%, 0);
        border-radius: 0;
    }

    100% {
        transform: rotate3d(0, 0, 1, 495deg) translate3d(0, 0, 0);
        border-radius: 50%;
    }
}

.bounce-loader {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    transition: all 0.2s;
    text-align: center;
    z-index: 10000;
}

.bounce-loader .bounce1,
.bounce-loader .bounce2,
.bounce-loader .bounce3,
.bounce-loader .bounce4 {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    margin-bottom: 5px;
    background-color: #4dae67;
}

.bounce-loader .bounce1 {
    animation: 2s infinite reveal-1;
}

.bounce-loader .bounce2 {
    animation: 2s infinite reveal-2;
}

.bounce-loader .bounce3 {
    animation: 2s infinite reveal-3;
}

.bounce-loader .bounce4 {
    animation: 2s infinite reveal-4;
}

.bounce-loader .bounce3 {
    border: 3px solid #4dae67;
    background-color: transparent;
}

.appear-animate {
    transform: translate3d(0, 0, 0) scale(1);
    will-change: transform, filter, opacity;
}

.fade {
    opacity: 0;
    transition: opacity 0.5s;
}

.fade.in {
    opacity: 1;
}

.d-icon-arrow-up:before {
    content: "";
    font-size: 20px;
    top: -10px;
    bottom: 0;
    right: 0px;
    left: 0px;
    position: absolute;
}

.scroll-top {
    position: fixed;
    text-align: center;
    bottom: 15px;
    left: auto;
    right: 30px;
    width: 40px;
    height: 40px;
    font-size: 27px;
    opacity: 0;
    visibility: hidden;
    transition: transform 0.3s, visibility 0.3s, opacity 0.3s;
    color: var(--white);
    transform: translateY(40px);
    border-radius: 3px;
    z-index: 999;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    line-height: 60px;
    background: var(--base-color);
}

.scroll-top:hover {
    color: #222;
}

.scroll-top i {
    font-weight: 900;
    line-height: inherit;
}

@media (min-width: 768px) {
    .scroll-top.show {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fixedTop {
    from {
        transform: translateY(-100%);
        transform-origin: center top 0px;
    }

    to {
        transform: translateY(0);
    }
}

@keyframes fixedBottom {
    from {
        transform: translateY(100%);
        transform-origin: center top 0px;
    }

    to {
        transform: translateY(0);
    }
}

.sticky-content.fix-top {
    top: 0;
}

.sticky-content.fix-bottom {
    bottom: 0;
}

.sticky-content.fixed {
    position: fixed;
    opacity: 1;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1051;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
}

.sticky-content.fixed.fix-top {
    animation: fixedTop 0.4s;
}

.sticky-content.fixed.fix-bottom {
    animation: fixedBottom 0.4s;
}

.loading:not(.load-more-overlay),
.load-more-overlay.loading::after {
    animation: spin 650ms infinite linear;
    border: 2px solid transparent;
    border-radius: 32px;
    border-top: 2px solid rgba(0, 0, 0, 0.4) !important;
    border-right: 2px solid rgba(0, 0, 0, 0.4) !important;
    border-bottom: 2px solid rgba(0, 0, 0, 0.4) !important;
    content: "";
    display: block;
    height: 21px;
    top: 50%;
    margin-top: -11px;
    left: 50%;
    margin-left: -10px;
    right: auto;
    position: absolute;
    width: 21px;
}

.dark-theme .loading:not(.load-more-overlay),
.dark-theme .load-more-overlay.loading::after {
    border-radius: 32px;
    border-top: 2px solid rgba(255, 255, 255, 0.6) !important;
    border-right: 2px solid rgba(255, 255, 255, 0.6) !important;
    border-bottom: 2px solid rgba(255, 255, 255, 0.6) !important;
}

.load-more-overlay {
    position: relative;
}

.load-more-overlay.loading::after {
    content: "";
}

.load-more-overlay::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    opacity: 0.8;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}





.riode-rounded-skin .btn,
.riode-rounded-skin .post-calendar,
.riode-rounded-skin .product-hide-details .btn-product,
.riode-rounded-skin .product-hide-details .btn-product-icon,
.riode-rounded-skin .post-single>.post-wrap img,
.riode-rounded-skin .post-single .post-author-detail,
.riode-rounded-skin .post-media,
.riode-rounded-skin .post-framed,
.riode-rounded-skin .minipopup-box,
.riode-rounded-skin .product-category,
.riode-rounded-skin .product-category .category-content,
.riode-rounded-skin .product-with-qty .quantity button,
.riode-rounded-skin .product-wrapper .banner,
.riode-rounded-skin .vendor-widget .vendor-logo,
.riode-rounded-skin .vendor-widget .vendor-product>figure,
.riode-rounded-skin .vendor-widget .vendor-banner {
    border-radius: 3px;
    overflow: hidden;
}




.riode-rounded-skin .login-popup {
    border-radius: 3px;
}

.riode-rounded-skin .form-row [type="text"],
.riode-rounded-skin .form-row [type="tel"],
.riode-rounded-skin .form-row [type="password"],
.riode-rounded-skin .form-row [type="email"],
.riode-rounded-skin .form-row textarea {
    border-radius: 3px;
}

.riode-rounded-skin .btn-link,
.riode-rounded-skin .form-coupon .input-text {
    border-radius: 0;
    overflow: visible;
}

.riode-rounded-skin .post.post-mask.gradient::before {
    border-radius: 0 0 3px 3px;
    overflow: hidden;
}

.riode-rounded-skin .mfp-product .product,
.riode-rounded-skin .product-category.cat-type-default {
    border-radius: 10px;
}

.sticky-icon-links li:nth-child(4) a {
    background: #4dae67;
}

.d-loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.6);
    z-index: 10;
}

.d-loading.small i {
    position: absolute;
    left: calc(50% - 10px);
    top: calc(50% - 10px);
    width: 20px;
    height: 20px;
}

.d-loading i {
    position: absolute;
    left: calc(50% - 17px);
    top: calc(50% - 17px);
    width: 34px;
    height: 34px;
    border: 2px solid transparent;
    border-top-color: #4dae67;
    border-radius: 50%;
    animation: spin 0.75s infinite linear;
}

.d-loading i:before {
    content: "";
    top: -2px;
    left: -2px;
    position: absolute;
    width: inherit;
    height: inherit;
    border: inherit;
    border-radius: inherit;
    animation: spin 1.5s infinite ease;
}

/* -------------------------------------------
    Helper
---------------------------------------------- */
.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.p-sticky {
    position: sticky !important;
}

.p-relative {
    position: relative !important;
}

.p-absolute {
    position: absolute !important;
}

.x-50 {
    left: 50% !important;
    transform: translateX(-50%) !important;
}

.x-50.y-50 {
    transform: translate(-50%, -50%) !important;
}

.y-50 {
    top: 50% !important;
    transform: translateY(-50%) !important;
}

.d-none {
    display: none !important;
}

.d-block {
    display: block !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-flex {
    display: flex !important;
}

.d-inline-flex {
    display: inline-flex !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-1 {
    flex: 1 !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

@media (min-width: 1200px) {
    .d-xl-none {
        display: none !important;
    }

    .d-xl-block {
        display: block !important;
    }

    .d-xl-flex {
        display: flex !important;
    }

    .d-xl-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 992px) {
    .d-lg-none {
        display: none !important;
    }

    .d-lg-block {
        display: block !important;
    }

    .d-lg-flex {
        display: flex !important;
    }

    .d-lg-inline-flex {
        display: inline-flex !important;
    }
}

@media (max-width: 1199px) {
    .d-xl-show {
        display: none !important;
    }
}

@media (max-width: 991px) {
    .d-lg-show {
        display: none !important;
    }
}

@media (max-width: 767px) {
    .d-md-show {
        display: none !important;
    }
}

@media (max-width: 479px) {
    .d-xs-show {
        display: none !important;
    }
}

.font-primary {
    font-family: var(--font-family-poppins) !important;
}

.font-secondary {
    font-family: Kalam, sans-serif !important;
}

.font-tertiary {
    font-family: "Open Sans", sans-serif !important;
}

.font-weight-bolder {
    font-weight: 800 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-weight-semi-bold {
    font-weight: 600 !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-light {
    font-weight: 300 !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.text-normal {
    text-transform: none !important;
}

.font-italic {
    font-style: italic !important;
}

.font-normal {
    font-stretch: normal !important;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.text-white {
    color: #fff !important;
}

.text-light {
    color: #ccc !important;
}

.text-grey {
    color: #999 !important;
}

.text-body {
    color: #666 !important;
}

.text-dark {
    color: #222 !important;
}

.text-black {
    color: #000 !important;
}

.text-primary {
    color: #4dae67 !important;
}

.text-secondary {
    color: #fd990b !important;
}

.ls-md {
    letter-spacing: -0.03em !important;
}

.ls-s {
    letter-spacing: -0.01em !important;
}

.ls-m {
    letter-spacing: -0.025em !important;
}

.ls-l {
    letter-spacing: -0.05em !important;
}

.ls-normal {
    letter-spacing: 0 !important;
}

.lh-2 {
    line-height: 2 !important;
}

.lh-1 {
    line-height: 1 !important;
}

.lh-0 {
    line-height: 0 !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-dark {
    background: var(--base-color) !important;
}

.bg-grey {
    background-color: #999 !important;
}

.bg-light {
    background-color: #ccc !important;
}

.bg-black {
    background-color: #000 !important;
}

.bg-primary {
    background-color: #4dae67 !important;
}

.bg-secondary {
    background-color: #fd990b !important;
}

.border-no {
    border: none !important;
}

/* -------------------------------------------
    Typography
---------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-weight: 400;
    margin: 0 0 2rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-family-poppins);
    font-weight: 600;
    line-height: 1.4;
    color: #333;
}

h1 {
    font-size: 4rem;
}

h2 {
    font-size: 3.4rem;
}

h3 {
    font-size: 3rem;
}

h4 {
    font-size: 2.4rem;
}

h5 {
    font-size: 1.8rem;
}

h6 {
    font-size: 1.5rem;
}

p {
    font-family: var(--font-family-poppins);
    font-size: 1.4rem;
    line-height: 1.86;
}

blockquote {
    margin: 0;
}

.list {
    font-size: 1.4rem;
    line-height: 1.86;
    color: #666;
}

.list-style-none {
    list-style: none;
}

.list-type-number {
    list-style-type: decimal;
}

.list-type-check li {
    position: relative;
    padding-left: 2.4rem;
}

.list-type-check li::before {
    display: block;
    position: absolute;
    left: 3px;
    top: 2px;
    font-family: "riode";
    content: "";
    font-weight: 700;
    font-size: 1.2rem;
    color: #222;
}

.list-circle li {
    padding-left: 1rem;
    margin-bottom: 1rem;
}

.list-circle i {
    margin-right: 1.2rem;
    width: 4rem;
    height: 4rem;
    border: 1px solid #eee;
    border-radius: 50%;
    text-align: center;
    line-height: 3.8rem;
    font-size: 1.1rem;
}

.list-arrow li {
    position: relative;
    transition: color 0.3s, padding 0.3s;
}

.list-arrow li::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    font-family: "riode";
    font-size: 1.4rem;
    transition: opacity 0.3s, visibility 0.3s;
}

.list-arrow li:hover,
.list-arrow li.show {
    padding-left: 2.2rem;
    color: #4dae67;
}

.list-arrow li:hover::before,
.list-arrow li.show::before {
    visibility: visible;
    opacity: 1;
}

.text-bd-left {
    position: relative;
    padding-left: 2rem;
}

.text-bd-left::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 2px;
    top: 5px;
    bottom: 5px;
    background-color: #dae1e5;
}

@media (max-width: 767px) {
    .list-type-number {
        margin-left: 2.3rem;
    }
}

/* -------------------------------------------
    Layout
---------------------------------------------- */
.container,
.container-fluid {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 479px) {

    .container,
    .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.container-fluid {
    max-width: 1820px;
}

.container {
    max-width: 1220px;
}

.divider {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1.5rem;
    height: 2.5rem;
    width: 1px;
    background-color: #eee;
}

.dark-theme .divider {
    background-color: #fff3;
}

/* -------------------------------------------
    Grid
---------------------------------------------- */
.row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
}

.row>* {
    position: relative;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
}

.gutter-lg {
    margin-left: -15px;
    margin-right: -15px;
}

.gutter-lg>* {
    padding-right: 15px;
    padding-left: 15px;
}

.gutter-sm {
    margin-left: -5px;
    margin-right: -5px;
}

.gutter-sm>* {
    padding-right: 5px;
    padding-left: 5px;
}

.gutter-xs {
    margin-left: -1px;
    margin-right: -1px;
}

.gutter-xs>* {
    padding-right: 1px;
    padding-left: 1px;
}

.gutter-no {
    margin-left: 0;
    margin-right: 0;
}

.gutter-no>* {
    padding-right: 0;
    padding-left: 0;
}

.cols-1>* {
    max-width: 100%;
    flex: 0 0 100%;
}

.cols-2>* {
    max-width: 50%;
    flex: 0 0 50%;
}

.cols-3>* {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
}

.cols-4>* {
    max-width: 25%;
    flex: 0 0 25%;
}

.cols-5>* {
    max-width: 20%;
    flex: 0 0 20%;
}

.cols-6>* {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
}

.cols-7>* {
    max-width: 14.2857%;
    flex: 0 0 14.2857%;
}

.cols-8>* {
    max-width: 12.5%;
    flex: 0 0 12.5%;
}

@media (min-width: 480px) {
    .cols-xs-1>* {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .cols-xs-2>* {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .cols-xs-3>* {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }

    .cols-xs-4>* {
        max-width: 25%;
        flex: 0 0 25%;
    }

    .cols-xs-5>* {
        max-width: 20%;
        flex: 0 0 20%;
    }

    .cols-xs-6>* {
        max-width: 16.6667%;
        flex: 0 0 16.6667%;
    }

    .cols-xs-7>* {
        max-width: 14.2857%;
        flex: 0 0 14.2857%;
    }

    .cols-xs-8>* {
        max-width: 12.5%;
        flex: 0 0 12.5%;
    }
}

@media (min-width: 576px) {
    .cols-sm-1>* {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .cols-sm-2>* {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .cols-sm-3>* {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }

    .cols-sm-4>* {
        max-width: 25%;
        flex: 0 0 25%;
    }

    .cols-sm-5>* {
        max-width: 20%;
        flex: 0 0 20%;
    }

    .cols-sm-6>* {
        max-width: 16.6667%;
        flex: 0 0 16.6667%;
    }

    .cols-sm-7>* {
        max-width: 14.2857%;
        flex: 0 0 14.2857%;
    }

    .cols-sm-8>* {
        max-width: 12.5%;
        flex: 0 0 12.5%;
    }
}

@media (min-width: 768px) {
    .cols-md-1>* {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .cols-md-2>* {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .cols-md-3>* {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }

    .cols-md-4>* {
        max-width: 25%;
        flex: 0 0 25%;
    }

    .cols-md-5>* {
        max-width: 20%;
        flex: 0 0 20%;
    }

    .cols-md-6>* {
        max-width: 16.6667%;
        flex: 0 0 16.6667%;
    }

    .cols-md-7>* {
        max-width: 14.2857%;
        flex: 0 0 14.2857%;
    }

    .cols-md-8>* {
        max-width: 12.5%;
        flex: 0 0 12.5%;
    }
}

@media (min-width: 992px) {
    .cols-lg-1>* {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .cols-lg-2>* {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .cols-lg-3>* {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }

    .cols-lg-4>* {
        max-width: 25%;
        flex: 0 0 25%;
    }

    .cols-lg-5>* {
        max-width: 20%;
        flex: 0 0 20%;
    }

    .cols-lg-6>* {
        max-width: 16.6667%;
        flex: 0 0 16.6667%;
    }

    .cols-lg-7>* {
        max-width: 14.2857%;
        flex: 0 0 14.2857%;
    }

    .cols-lg-8>* {
        max-width: 12.5%;
        flex: 0 0 12.5%;
    }
}

@media (min-width: 1200px) {
    .cols-xl-1>* {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .cols-xl-2>* {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .cols-xl-3>* {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }

    .cols-xl-4>* {
        max-width: 25%;
        flex: 0 0 25%;
    }

    .cols-xl-5>* {
        max-width: 20%;
        flex: 0 0 20%;
    }

    .cols-xl-6>* {
        max-width: 16.6667%;
        flex: 0 0 16.6667%;
    }

    .cols-xl-7>* {
        max-width: 14.2857%;
        flex: 0 0 14.2857%;
    }

    .cols-xl-8>* {
        max-width: 12.5%;
        flex: 0 0 12.5%;
    }
}

@media (min-width: 1600px) {
    .cols-xxl-1>* {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .cols-xxl-2>* {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .cols-xxl-3>* {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }

    .cols-xxl-4>* {
        max-width: 25%;
        flex: 0 0 25%;
    }

    .cols-xxl-5>* {
        max-width: 20%;
        flex: 0 0 20%;
    }

    .cols-xxl-6>* {
        max-width: 16.6667%;
        flex: 0 0 16.6667%;
    }

    .cols-xxl-7>* {
        max-width: 14.2857%;
        flex: 0 0 14.2857%;
    }

    .cols-xxl-8>* {
        max-width: 12.5%;
        flex: 0 0 12.5%;
    }
}

.col-1 {
    max-width: 8.3333%;
    flex: 0 0 8.3333%;
}

.col-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
}

.col-3 {
    max-width: 25%;
    flex: 0 0 25%;
}

.col-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
}

.col-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
}

.col-6 {
    max-width: 50%;
    flex: 0 0 50%;
}

.col-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
}

.col-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
}

.col-9 {
    max-width: 75%;
    flex: 0 0 75%;
}

.col-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
}

.col-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
}

.col-12 {
    max-width: 100%;
    flex: 0 0 100%;
}

@media (min-width: 480px) {
    .col-xs-1 {
        max-width: 8.3333%;
        flex: 0 0 8.3333%;
    }

    .col-xs-2 {
        max-width: 16.6667%;
        flex: 0 0 16.6667%;
    }

    .col-xs-3 {
        max-width: 25%;
        flex: 0 0 25%;
    }

    .col-xs-4 {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }

    .col-xs-5 {
        max-width: 41.6667%;
        flex: 0 0 41.6667%;
    }

    .col-xs-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .col-xs-7 {
        max-width: 58.3333%;
        flex: 0 0 58.3333%;
    }

    .col-xs-8 {
        max-width: 66.6667%;
        flex: 0 0 66.6667%;
    }

    .col-xs-9 {
        max-width: 75%;
        flex: 0 0 75%;
    }

    .col-xs-10 {
        max-width: 83.3333%;
        flex: 0 0 83.3333%;
    }

    .col-xs-11 {
        max-width: 91.6667%;
        flex: 0 0 91.6667%;
    }

    .col-xs-12 {
        max-width: 100%;
        flex: 0 0 100%;
    }
}

@media (min-width: 576px) {
    .col-sm-1 {
        max-width: 8.3333%;
        flex: 0 0 8.3333%;
    }

    .col-sm-2 {
        max-width: 16.6667%;
        flex: 0 0 16.6667%;
    }

    .col-sm-3 {
        max-width: 25%;
        flex: 0 0 25%;
    }

    .col-sm-4 {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }

    .col-sm-5 {
        max-width: 41.6667%;
        flex: 0 0 41.6667%;
    }

    .col-sm-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .col-sm-7 {
        max-width: 58.3333%;
        flex: 0 0 58.3333%;
    }

    .col-sm-8 {
        max-width: 66.6667%;
        flex: 0 0 66.6667%;
    }

    .col-sm-9 {
        max-width: 75%;
        flex: 0 0 75%;
    }

    .col-sm-10 {
        max-width: 83.3333%;
        flex: 0 0 83.3333%;
    }

    .col-sm-11 {
        max-width: 91.6667%;
        flex: 0 0 91.6667%;
    }

    .col-sm-12 {
        max-width: 100%;
        flex: 0 0 100%;
    }
}

@media (min-width: 768px) {
    .col-md-1 {
        max-width: 8.3333%;
        flex: 0 0 8.3333%;
    }

    .col-md-2 {
        max-width: 16.6667%;
        flex: 0 0 16.6667%;
    }

    .col-md-3 {
        max-width: 25%;
        flex: 0 0 25%;
    }

    .col-md-4 {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }

    .col-md-5 {
        max-width: 41.6667%;
        flex: 0 0 41.6667%;
    }

    .col-md-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .col-md-7 {
        max-width: 58.3333%;
        flex: 0 0 58.3333%;
    }

    .col-md-8 {
        max-width: 66.6667%;
        flex: 0 0 66.6667%;
    }

    .col-md-9 {
        max-width: 75%;
        flex: 0 0 75%;
    }

    .col-md-10 {
        max-width: 83.3333%;
        flex: 0 0 83.3333%;
    }

    .col-md-11 {
        max-width: 91.6667%;
        flex: 0 0 91.6667%;
    }

    .col-md-12 {
        max-width: 100%;
        flex: 0 0 100%;
    }
}

@media (min-width: 992px) {
    .col-lg-1 {
        max-width: 8.3333%;
        flex: 0 0 8.3333%;
    }

    .col-lg-2 {
        max-width: 16.6667%;
        flex: 0 0 16.6667%;
    }

    .col-lg-3 {
        max-width: 25%;
        flex: 0 0 25%;
    }

    .col-lg-4 {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }

    .col-lg-5 {
        max-width: 41.6667%;
        flex: 0 0 41.6667%;
    }

    .col-lg-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .col-lg-7 {
        max-width: 58.3333%;
        flex: 0 0 58.3333%;
    }

    .col-lg-8 {
        max-width: 66.6667%;
        flex: 0 0 66.6667%;
    }

    .col-lg-9 {
        max-width: 75%;
        flex: 0 0 75%;
    }

    .col-lg-10 {
        max-width: 83.3333%;
        flex: 0 0 83.3333%;
    }

    .col-lg-11 {
        max-width: 91.6667%;
        flex: 0 0 91.6667%;
    }

    .col-lg-12 {
        max-width: 100%;
        flex: 0 0 100%;
    }
}

@media (min-width: 1200px) {
    .col-xl-1 {
        max-width: 8.3333%;
        flex: 0 0 8.3333%;
    }

    .col-xl-2 {
        max-width: 16.6667%;
        flex: 0 0 16.6667%;
    }

    .col-xl-3 {
        max-width: 25%;
        flex: 0 0 25%;
    }

    .col-xl-4 {
        max-width: 33.3333%;
        flex: 0 0 33.3333%;
    }

    .col-xl-5 {
        max-width: 41.6667%;
        flex: 0 0 41.6667%;
    }

    .col-xl-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .col-xl-7 {
        max-width: 58.3333%;
        flex: 0 0 58.3333%;
    }

    .col-xl-8 {
        max-width: 66.6667%;
        flex: 0 0 66.6667%;
    }

    .col-xl-9 {
        max-width: 75%;
        flex: 0 0 75%;
    }

    .col-xl-10 {
        max-width: 83.3333%;
        flex: 0 0 83.3333%;
    }

    .col-xl-11 {
        max-width: 91.6667%;
        flex: 0 0 91.6667%;
    }

    .col-xl-12 {
        max-width: 100%;
        flex: 0 0 100%;
    }
}

@media (min-width: 1600px) {
    .col-xxl-10 {
        flex: 0 0 83.3333%;
        max-width: 83.3333%;
    }

    .col-xxl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xxl-2 {
        flex: 0 0 16.6666%;
        max-width: 16.6666%;
    }
}

@media (min-width: 1200px) {
    .col-xl-5col {
        position: relative;
        flex: 0 0 20%;
        max-width: 20%;
        padding-right: 10px;
        padding-left: 10px;
    }

    .col-xl-5col2 {
        position: relative;
        flex: 0 0 40%;
        max-width: 40%;
        padding-right: 10px;
        padding-left: 10px;
    }

    .col-xl-5col4 {
        position: relative;
        flex: 0 0 80%;
        max-width: 80%;
        padding-right: 10px;
        padding-left: 10px;
    }
}

.order-first {
    order: -1;
}

.order-last {
    order: 9;
}

@media (min-width: 576px) {
    .order-sm-auto {
        order: 0;
    }

    .order-sm-first {
        order: -1;
    }

    .order-sm-last {
        order: 9;
    }
}

@media (min-width: 768px) {
    .order-md-auto {
        order: 0;
    }

    .order-md-first {
        order: -1;
    }

    .order-md-last {
        order: 9;
    }
}

@media (min-width: 992px) {
    .order-lg-auto {
        order: 0;
    }

    .order-lg-first {
        order: -1;
    }

    .order-lg-last {
        order: 9;
    }
}

@media (min-width: 1200px) {
    .order-xl-auto {
        order: 0;
    }

    .order-xl-first {
        order: -1;
    }

    .order-xl-last {
        order: 9;
    }
}

.grid {
    margin: -1rem;
}

.grid .banner,
.grid .category,
.grid .category>a,
.grid figure,
.grid .banner img,
.grid .category img {
    height: 100%;
}

.grid img {
    object-fit: cover;
}

.grid .grid-item {
    padding: 1rem;
    transform: translate3d(0, 0, 0);
}

.grid.gutter-sm {
    margin: -0.5rem;
}

.grid.gutter-sm .grid-item {
    padding: 0.5rem;
}

.grid.gutter-no {
    margin: 0;
}

.grid.gutter-no .grid-item {
    padding: 0;
}

.grid::after {
    content: "";
    display: block;
    clear: both;
}

.grid.grid-float {
    display: block !important;
}

.grid.grid-float .grid-item {
    float: left;
}

.split-line>* {
    margin-bottom: 3rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid #ebebeb;
}

.split-line>*::after {
    content: "";
    display: block;
    width: 1px;
    height: calc(100% - 3rem);
    position: absolute;
    right: 0;
    top: 0;
    background-color: #ebebeb;
}

.split-line>.col-1:nth-child(12n)::after,
.split-line>.col-2:nth-child(6n)::after,
.split-line>.col-3:nth-child(4n)::after,
.split-line>.col-4:nth-child(3n)::after,
.split-line>.col-5:nth-child(2n)::after,
.split-line>.col-6:nth-child(2n)::after,
.split-line>.col-7:nth-child(1n)::after,
.split-line>.col-8:nth-child(1n)::after,
.split-line>.col-9:nth-child(1n)::after,
.split-line>.col-10:nth-child(1n)::after,
.split-line>.col-11:nth-child(1n)::after,
.split-line.cols-1>*:nth-child(1n)::after,
.split-line.cols-2>*:nth-child(2n)::after,
.split-line.cols-3>*:nth-child(3n)::after,
.split-line.cols-4>*:nth-child(4n)::after,
.split-line.cols-5>*:nth-child(5n)::after,
.split-line.cols-6>*:nth-child(6n)::after,
.split-line.cols-7>*:nth-child(7n)::after,
.split-line.cols-8>*:nth-child(8n)::after {
    content: none;
}

@media (min-width: 576px) {

    .split-line>.col-sm-1:nth-child(n)::after,
    .split-line>.col-sm-2:nth-child(n)::after,
    .split-line>.col-sm-3:nth-child(n)::after,
    .split-line>.col-sm-4:nth-child(n)::after,
    .split-line>.col-sm-5:nth-child(n)::after,
    .split-line>.col-sm-6:nth-child(n)::after,
    .split-line>.col-sm-7:nth-child(n)::after,
    .split-line>.col-sm-8:nth-child(n)::after,
    .split-line>.col-sm-9:nth-child(n)::after,
    .split-line>.col-sm-10:nth-child(n)::after,
    .split-line>.col-sm-11:nth-child(n)::after,
    .split-line.cols-sm-1>*:nth-child(n)::after,
    .split-line.cols-sm-2>*:nth-child(n)::after,
    .split-line.cols-sm-3>*:nth-child(n)::after,
    .split-line.cols-sm-4>*:nth-child(n)::after,
    .split-line.cols-sm-5>*:nth-child(n)::after,
    .split-line.cols-sm-6>*:nth-child(n)::after,
    .split-line.cols-sm-7>*:nth-child(n)::after,
    .split-line.cols-sm-8>*:nth-child(n)::after {
        content: "";
    }

    .split-line>.col-sm-1:nth-child(12n)::after,
    .split-line>.col-sm-2:nth-child(6n)::after,
    .split-line>.col-sm-3:nth-child(4n)::after,
    .split-line>.col-sm-4:nth-child(3n)::after,
    .split-line>.col-sm-5:nth-child(2n)::after,
    .split-line>.col-sm-6:nth-child(2n)::after,
    .split-line>.col-sm-7:nth-child(1n)::after,
    .split-line>.col-sm-8:nth-child(1n)::after,
    .split-line>.col-sm-9:nth-child(1n)::after,
    .split-line>.col-sm-10:nth-child(1n)::after,
    .split-line>.col-sm-11:nth-child(1n)::after,
    .split-line.cols-sm-1>*:nth-child(1n)::after,
    .split-line.cols-sm-2>*:nth-child(2n)::after,
    .split-line.cols-sm-3>*:nth-child(3n)::after,
    .split-line.cols-sm-4>*:nth-child(4n)::after,
    .split-line.cols-sm-5>*:nth-child(5n)::after,
    .split-line.cols-sm-6>*:nth-child(6n)::after,
    .split-line.cols-sm-7>*:nth-child(7n)::after,
    .split-line.cols-sm-8>*:nth-child(8n)::after {
        content: none;
    }
}

@media (min-width: 768px) {

    .split-line>.col-md-1:nth-child(n)::after,
    .split-line>.col-md-2:nth-child(n)::after,
    .split-line>.col-md-3:nth-child(n)::after,
    .split-line>.col-md-4:nth-child(n)::after,
    .split-line>.col-md-5:nth-child(n)::after,
    .split-line>.col-md-6:nth-child(n)::after,
    .split-line>.col-md-7:nth-child(n)::after,
    .split-line>.col-md-8:nth-child(n)::after,
    .split-line>.col-md-9:nth-child(n)::after,
    .split-line>.col-md-10:nth-child(n)::after,
    .split-line>.col-md-11:nth-child(n)::after,
    .split-line.cols-md-1>*:nth-child(n)::after,
    .split-line.cols-md-2>*:nth-child(n)::after,
    .split-line.cols-md-3>*:nth-child(n)::after,
    .split-line.cols-md-4>*:nth-child(n)::after,
    .split-line.cols-md-5>*:nth-child(n)::after,
    .split-line.cols-md-6>*:nth-child(n)::after,
    .split-line.cols-md-7>*:nth-child(n)::after,
    .split-line.cols-md-8>*:nth-child(n)::after {
        content: "";
    }

    .split-line>.col-md-1:nth-child(12n)::after,
    .split-line>.col-md-2:nth-child(6n)::after,
    .split-line>.col-md-3:nth-child(4n)::after,
    .split-line>.col-md-4:nth-child(3n)::after,
    .split-line>.col-md-5:nth-child(2n)::after,
    .split-line>.col-md-6:nth-child(2n)::after,
    .split-line>.col-md-7:nth-child(1n)::after,
    .split-line>.col-md-8:nth-child(1n)::after,
    .split-line>.col-md-9:nth-child(1n)::after,
    .split-line>.col-md-10:nth-child(1n)::after,
    .split-line>.col-md-11:nth-child(1n)::after,
    .split-line.cols-md-1>*:nth-child(1n)::after,
    .split-line.cols-md-2>*:nth-child(2n)::after,
    .split-line.cols-md-3>*:nth-child(3n)::after,
    .split-line.cols-md-4>*:nth-child(4n)::after,
    .split-line.cols-md-5>*:nth-child(5n)::after,
    .split-line.cols-md-6>*:nth-child(6n)::after,
    .split-line.cols-md-7>*:nth-child(7n)::after,
    .split-line.cols-md-8>*:nth-child(8n)::after {
        content: none;
    }
}

@media (min-width: 992px) {

    .split-line>.col-lg-1:nth-child(n)::after,
    .split-line>.col-lg-2:nth-child(n)::after,
    .split-line>.col-lg-3:nth-child(n)::after,
    .split-line>.col-lg-4:nth-child(n)::after,
    .split-line>.col-lg-5:nth-child(n)::after,
    .split-line>.col-lg-6:nth-child(n)::after,
    .split-line>.col-lg-7:nth-child(n)::after,
    .split-line>.col-lg-8:nth-child(n)::after,
    .split-line>.col-lg-9:nth-child(n)::after,
    .split-line>.col-lg-10:nth-child(n)::after,
    .split-line>.col-lg-11:nth-child(n)::after,
    .split-line.cols-lg-1>*:nth-child(n)::after,
    .split-line.cols-lg-2>*:nth-child(n)::after,
    .split-line.cols-lg-3>*:nth-child(n)::after,
    .split-line.cols-lg-4>*:nth-child(n)::after,
    .split-line.cols-lg-5>*:nth-child(n)::after,
    .split-line.cols-lg-6>*:nth-child(n)::after,
    .split-line.cols-lg-7>*:nth-child(n)::after,
    .split-line.cols-lg-8>*:nth-child(n)::after {
        content: "";
    }

    .split-line>.col-lg-1:nth-child(12n)::after,
    .split-line>.col-lg-2:nth-child(6n)::after,
    .split-line>.col-lg-3:nth-child(4n)::after,
    .split-line>.col-lg-4:nth-child(3n)::after,
    .split-line>.col-lg-5:nth-child(2n)::after,
    .split-line>.col-lg-6:nth-child(2n)::after,
    .split-line>.col-lg-7:nth-child(1n)::after,
    .split-line>.col-lg-8:nth-child(1n)::after,
    .split-line>.col-lg-9:nth-child(1n)::after,
    .split-line>.col-lg-10:nth-child(1n)::after,
    .split-line>.col-lg-11:nth-child(1n)::after,
    .split-line.cols-lg-1>*:nth-child(1n)::after,
    .split-line.cols-lg-2>*:nth-child(2n)::after,
    .split-line.cols-lg-3>*:nth-child(3n)::after,
    .split-line.cols-lg-4>*:nth-child(4n)::after,
    .split-line.cols-lg-5>*:nth-child(5n)::after,
    .split-line.cols-lg-6>*:nth-child(6n)::after,
    .split-line.cols-lg-7>*:nth-child(7n)::after,
    .split-line.cols-lg-8>*:nth-child(8n)::after {
        content: none;
    }
}

@media (min-width: 1200px) {

    .split-line>.col-xl-1:nth-child(n)::after,
    .split-line>.col-xl-2:nth-child(n)::after,
    .split-line>.col-xl-3:nth-child(n)::after,
    .split-line>.col-xl-4:nth-child(n)::after,
    .split-line>.col-xl-5:nth-child(n)::after,
    .split-line>.col-xl-6:nth-child(n)::after,
    .split-line>.col-xl-7:nth-child(n)::after,
    .split-line>.col-xl-8:nth-child(n)::after,
    .split-line>.col-xl-9:nth-child(n)::after,
    .split-line>.col-xl-10:nth-child(n)::after,
    .split-line>.col-xl-11:nth-child(n)::after,
    .split-line.cols-xl-1>*:nth-child(n)::after,
    .split-line.cols-xl-2>*:nth-child(n)::after,
    .split-line.cols-xl-3>*:nth-child(n)::after,
    .split-line.cols-xl-4>*:nth-child(n)::after,
    .split-line.cols-xl-5>*:nth-child(n)::after,
    .split-line.cols-xl-6>*:nth-child(n)::after,
    .split-line.cols-xl-7>*:nth-child(n)::after,
    .split-line.cols-xl-8>*:nth-child(n)::after,
    .split-line>.col-xl-5col:nth-child(n)::after,
    .split-line>.col-xl-7col:nth-child(n)::after,
    .split-line>.col-xl-8col:nth-child(n)::after {
        content: "";
    }

    .split-line>.col-xl-1:nth-child(12n)::after,
    .split-line>.col-xl-2:nth-child(6n)::after,
    .split-line>.col-xl-3:nth-child(4n)::after,
    .split-line>.col-xl-4:nth-child(3n)::after,
    .split-line>.col-xl-5:nth-child(2n)::after,
    .split-line>.col-xl-6:nth-child(2n)::after,
    .split-line>.col-xl-7:nth-child(1n)::after,
    .split-line>.col-xl-8:nth-child(1n)::after,
    .split-line>.col-xl-9:nth-child(1n)::after,
    .split-line>.col-xl-10:nth-child(1n)::after,
    .split-line>.col-xl-11:nth-child(1n)::after,
    .split-line.cols-xl-1>*:nth-child(1n)::after,
    .split-line.cols-xl-2>*:nth-child(2n)::after,
    .split-line.cols-xl-3>*:nth-child(3n)::after,
    .split-line.cols-xl-4>*:nth-child(4n)::after,
    .split-line.cols-xl-5>*:nth-child(5n)::after,
    .split-line.cols-xl-6>*:nth-child(6n)::after,
    .split-line.cols-xl-7>*:nth-child(7n)::after,
    .split-line.cols-xl-8>*:nth-child(8n)::after,
    .split-line>.col-xl-5col:nth-child(5n)::after,
    .split-line>.col-xl-7col:nth-child(7n)::after,
    .split-line>.col-xl-8col:nth-child(8n)::after {
        content: none;
    }
}

/* -------------------------------------------
    Spacing
---------------------------------------------- */
.mt-0 {
    margin-top: 0rem !important;
}

.mb-0 {
    margin-bottom: 0rem !important;
}

.pt-0 {
    padding-top: 0rem !important;
}

.pb-0 {
    padding-bottom: 0rem !important;
}

.mt-1 {
    margin-top: 0.5rem !important;
}

.mb-1 {
    margin-bottom: 0.5rem !important;
}

.pt-1 {
    padding-top: 0.5rem !important;
}

.pb-1 {
    padding-bottom: 0.5rem !important;
}

.mt-2 {
    margin-top: 1rem !important;
}

.mb-2 {
    margin-bottom: 1rem !important;
}

.pt-2 {
    padding-top: 1rem !important;
}

.pb-2 {
    padding-bottom: 1rem !important;
}

.mt-3 {
    margin-top: 1.5rem !important;
}

.mb-3 {
    margin-bottom: 1.5rem !important;
}

.pt-3 {
    padding-top: 1.5rem !important;
}

.pb-3 {
    padding-bottom: 1.5rem !important;
}

.mt-4 {
    margin-top: 2rem !important;
}

.mb-4 {
    margin-bottom: 2rem !important;
}

.pt-4 {
    padding-top: 2rem !important;
}

.pb-4 {
    padding-bottom: 2rem !important;
}

.mt-5 {
    margin-top: 2.5rem !important;
}

.mb-5 {
    margin-bottom: 2.5rem !important;
}

.pt-5 {
    padding-top: 2.5rem !important;
}

.pb-5 {
    padding-bottom: 2.5rem !important;
}

.mt-6 {
    margin-top: 3rem !important;
}

.mb-6 {
    margin-bottom: 3rem !important;
}

.pt-6 {
    padding-top: 3rem !important;
}

.pb-6 {
    padding-bottom: 3rem !important;
}

.mt-7 {
    margin-top: 3.5rem !important;
}

.mb-7 {
    margin-bottom: 3.5rem !important;
}

.pt-7 {
    padding-top: 3.5rem !important;
}

.pb-7 {
    padding-bottom: 3.5rem !important;
}

.mt-8 {
    margin-top: 4rem !important;
}

.mb-8 {
    margin-bottom: 4rem !important;
}

.pt-8 {
    padding-top: 4rem !important;
}

.pb-8 {
    padding-bottom: 4rem !important;
}

.mt-9 {
    margin-top: 4.5rem !important;
}

.mb-9 {
    margin-bottom: 4.5rem !important;
}

.pt-9 {
    padding-top: 4.5rem !important;
}

.pb-9 {
    padding-bottom: 4.5rem !important;
}

.mt-10 {
    margin-top: 5rem !important;
}

.mb-10 {
    margin-bottom: 5rem !important;
}

.pt-10 {
    padding-top: 5rem !important;
}

.pt-20 {
    padding-top: 10rem !important;
}

.pb-10 {
    padding-bottom: 5rem !important;
}

.ml-0 {
    margin-left: 0rem !important;
}

.mr-0 {
    margin-right: 0rem !important;
}

.pl-0 {
    padding-left: 0rem !important;
}

.pr-0 {
    padding-right: 0rem !important;
}

.ml-1 {
    margin-left: 0.5rem !important;
}

.mr-1 {
    margin-right: 0.5rem !important;
}

.pl-1 {
    padding-left: 0.5rem !important;
}

.pr-1 {
    padding-right: 0.5rem !important;
}

.ml-2 {
    margin-left: 1rem !important;
}

.mr-2 {
    margin-right: 1rem !important;
}

.pl-2 {
    padding-left: 1rem !important;
}

.pr-2 {
    padding-right: 1rem !important;
}

.ml-3 {
    margin-left: 1.5rem !important;
}

.mr-3 {
    margin-right: 1.5rem !important;
}

.pl-3 {
    padding-left: 1.5rem !important;
}

.pr-3 {
    padding-right: 1.5rem !important;
}

.ml-4 {
    margin-left: 2rem !important;
}

.mr-4 {
    margin-right: 2rem !important;
}

.pl-4 {
    padding-left: 2rem !important;
}

.pr-4 {
    padding-right: 2rem !important;
}

.ml-5 {
    margin-left: 2.5rem !important;
}

.mr-5 {
    margin-right: 2.5rem !important;
}

.pl-5 {
    padding-left: 2.5rem !important;
}

.pr-5 {
    padding-right: 2.5rem !important;
}

.ml-6 {
    margin-left: 3rem !important;
}

.mr-6 {
    margin-right: 3rem !important;
}

.pl-6 {
    padding-left: 3rem !important;
}

.pr-6 {
    padding-right: 3rem !important;
}

.ml-7 {
    margin-left: 3.5rem !important;
}

.mr-7 {
    margin-right: 3.5rem !important;
}

.pl-7 {
    padding-left: 3.5rem !important;
}

.pr-7 {
    padding-right: 3.5rem !important;
}

.ml-8 {
    margin-left: 4rem !important;
}

.mr-8 {
    margin-right: 4rem !important;
}

.pl-8 {
    padding-left: 4rem !important;
}

.pr-8 {
    padding-right: 4rem !important;
}

.ml-9 {
    margin-left: 4.5rem !important;
}

.mr-9 {
    margin-right: 4.5rem !important;
}

.pl-9 {
    padding-left: 4.5rem !important;
}

.pr-9 {
    padding-right: 4.5rem !important;
}

.ml-10 {
    margin-left: 5rem !important;
}

.mr-10 {
    margin-right: 5rem !important;
}

.pl-10 {
    padding-left: 5rem !important;
}

.pr-10 {
    padding-right: 5rem !important;
}

.p-0 {
    padding: 0 !important;
}

.p-10 {
    padding: 10px !important;
}

.p-20 {
    padding: 20px !important;
}

.m-0 {
    margin: 0 !important;
}

.ml-auto {
    margin-left: auto !important;
}

.mr-auto {
    margin-right: auto !important;
}

.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

@media (min-width: 576px) {
    .mt-sm-0 {
        margin-top: 0rem !important;
    }

    .mb-sm-0 {
        margin-bottom: 0rem !important;
    }

    .mr-sm-0 {
        margin-right: 0rem !important;
    }

    .ml-sm-0 {
        margin-left: 0rem !important;
    }

    .pt-sm-0 {
        padding-top: 0rem !important;
    }

    .pr-sm-0 {
        padding-right: 0rem !important;
    }

    .pl-sm-0 {
        padding-left: 0rem !important;
    }

    .pb-sm-0 {
        padding-bottom: 0rem !important;
    }

    .mt-sm-1 {
        margin-top: 0.5rem !important;
    }

    .mb-sm-1 {
        margin-bottom: 0.5rem !important;
    }

    .mr-sm-1 {
        margin-right: 0.5rem !important;
    }

    .ml-sm-1 {
        margin-left: 0.5rem !important;
    }

    .pt-sm-1 {
        padding-top: 0.5rem !important;
    }

    .pr-sm-1 {
        padding-right: 0.5rem !important;
    }

    .pl-sm-1 {
        padding-left: 0.5rem !important;
    }

    .pb-sm-1 {
        padding-bottom: 0.5rem !important;
    }

    .mt-sm-2 {
        margin-top: 1rem !important;
    }

    .mb-sm-2 {
        margin-bottom: 1rem !important;
    }

    .mr-sm-2 {
        margin-right: 1rem !important;
    }

    .ml-sm-2 {
        margin-left: 1rem !important;
    }

    .pt-sm-2 {
        padding-top: 1rem !important;
    }

    .pr-sm-2 {
        padding-right: 1rem !important;
    }

    .pl-sm-2 {
        padding-left: 1rem !important;
    }

    .pb-sm-2 {
        padding-bottom: 1rem !important;
    }

    .mt-sm-3 {
        margin-top: 1.5rem !important;
    }

    .mb-sm-3 {
        margin-bottom: 1.5rem !important;
    }

    .mr-sm-3 {
        margin-right: 1.5rem !important;
    }

    .ml-sm-3 {
        margin-left: 1.5rem !important;
    }

    .pt-sm-3 {
        padding-top: 1.5rem !important;
    }

    .pr-sm-3 {
        padding-right: 1.5rem !important;
    }

    .pl-sm-3 {
        padding-left: 1.5rem !important;
    }

    .pb-sm-3 {
        padding-bottom: 1.5rem !important;
    }

    .mt-sm-4 {
        margin-top: 2rem !important;
    }

    .mb-sm-4 {
        margin-bottom: 2rem !important;
    }

    .mr-sm-4 {
        margin-right: 2rem !important;
    }

    .ml-sm-4 {
        margin-left: 2rem !important;
    }

    .pt-sm-4 {
        padding-top: 2rem !important;
    }

    .pr-sm-4 {
        padding-right: 2rem !important;
    }

    .pl-sm-4 {
        padding-left: 2rem !important;
    }

    .pb-sm-4 {
        padding-bottom: 2rem !important;
    }

    .mt-sm-5 {
        margin-top: 2.5rem !important;
    }

    .mb-sm-5 {
        margin-bottom: 2.5rem !important;
    }

    .mr-sm-5 {
        margin-right: 2.5rem !important;
    }

    .ml-sm-5 {
        margin-left: 2.5rem !important;
    }

    .pt-sm-5 {
        padding-top: 2.5rem !important;
    }

    .pr-sm-5 {
        padding-right: 2.5rem !important;
    }

    .pl-sm-5 {
        padding-left: 2.5rem !important;
    }

    .pb-sm-5 {
        padding-bottom: 2.5rem !important;
    }

    .mt-sm-6 {
        margin-top: 3rem !important;
    }

    .mb-sm-6 {
        margin-bottom: 3rem !important;
    }

    .mr-sm-6 {
        margin-right: 3rem !important;
    }

    .ml-sm-6 {
        margin-left: 3rem !important;
    }

    .pt-sm-6 {
        padding-top: 3rem !important;
    }

    .pr-sm-6 {
        padding-right: 3rem !important;
    }

    .pl-sm-6 {
        padding-left: 3rem !important;
    }

    .pb-sm-6 {
        padding-bottom: 3rem !important;
    }

    .mt-sm-7 {
        margin-top: 3.5rem !important;
    }

    .mb-sm-7 {
        margin-bottom: 3.5rem !important;
    }

    .mr-sm-7 {
        margin-right: 3.5rem !important;
    }

    .ml-sm-7 {
        margin-left: 3.5rem !important;
    }

    .pt-sm-7 {
        padding-top: 3.5rem !important;
    }

    .pr-sm-7 {
        padding-right: 3.5rem !important;
    }

    .pl-sm-7 {
        padding-left: 3.5rem !important;
    }

    .pb-sm-7 {
        padding-bottom: 3.5rem !important;
    }

    .mt-sm-8 {
        margin-top: 4rem !important;
    }

    .mb-sm-8 {
        margin-bottom: 4rem !important;
    }

    .mr-sm-8 {
        margin-right: 4rem !important;
    }

    .ml-sm-8 {
        margin-left: 4rem !important;
    }

    .pt-sm-8 {
        padding-top: 4rem !important;
    }

    .pr-sm-8 {
        padding-right: 4rem !important;
    }

    .pl-sm-8 {
        padding-left: 4rem !important;
    }

    .pb-sm-8 {
        padding-bottom: 4rem !important;
    }

    .mt-sm-9 {
        margin-top: 4.5rem !important;
    }

    .mb-sm-9 {
        margin-bottom: 4.5rem !important;
    }

    .mr-sm-9 {
        margin-right: 4.5rem !important;
    }

    .ml-sm-9 {
        margin-left: 4.5rem !important;
    }

    .pt-sm-9 {
        padding-top: 4.5rem !important;
    }

    .pr-sm-9 {
        padding-right: 4.5rem !important;
    }

    .pl-sm-9 {
        padding-left: 4.5rem !important;
    }

    .pb-sm-9 {
        padding-bottom: 4.5rem !important;
    }

    .mt-sm-10 {
        margin-top: 5rem !important;
    }

    .mb-sm-10 {
        margin-bottom: 5rem !important;
    }

    .mr-sm-10 {
        margin-right: 5rem !important;
    }

    .ml-sm-10 {
        margin-left: 5rem !important;
    }

    .pt-sm-10 {
        padding-top: 5rem !important;
    }

    .pr-sm-10 {
        padding-right: 5rem !important;
    }

    .pl-sm-10 {
        padding-left: 5rem !important;
    }

    .pb-sm-10 {
        padding-bottom: 5rem !important;
    }
}

@media (min-width: 768px) {
    .mt-md-0 {
        margin-top: 0rem !important;
    }

    .mb-md-0 {
        margin-bottom: 0rem !important;
    }

    .mr-md-0 {
        margin-right: 0rem !important;
    }

    .ml-md-0 {
        margin-left: 0rem !important;
    }

    .pt-md-0 {
        padding-top: 0rem !important;
    }

    .pb-md-0 {
        padding-bottom: 0rem !important;
    }

    .pr-md-0 {
        padding-right: 0rem !important;
    }

    .pl-md-0 {
        padding-left: 0rem !important;
    }

    .mt-md-1 {
        margin-top: 0.5rem !important;
    }

    .mb-md-1 {
        margin-bottom: 0.5rem !important;
    }

    .mr-md-1 {
        margin-right: 0.5rem !important;
    }

    .ml-md-1 {
        margin-left: 0.5rem !important;
    }

    .pt-md-1 {
        padding-top: 0.5rem !important;
    }

    .pb-md-1 {
        padding-bottom: 0.5rem !important;
    }

    .pr-md-1 {
        padding-right: 0.5rem !important;
    }

    .pl-md-1 {
        padding-left: 0.5rem !important;
    }

    .mt-md-2 {
        margin-top: 1rem !important;
    }

    .mb-md-2 {
        margin-bottom: 1rem !important;
    }

    .mr-md-2 {
        margin-right: 1rem !important;
    }

    .ml-md-2 {
        margin-left: 1rem !important;
    }

    .pt-md-2 {
        padding-top: 1rem !important;
    }

    .pb-md-2 {
        padding-bottom: 1rem !important;
    }

    .pr-md-2 {
        padding-right: 1rem !important;
    }

    .pl-md-2 {
        padding-left: 1rem !important;
    }

    .mt-md-3 {
        margin-top: 1.5rem !important;
    }

    .mb-md-3 {
        margin-bottom: 1.5rem !important;
    }

    .mr-md-3 {
        margin-right: 1.5rem !important;
    }

    .ml-md-3 {
        margin-left: 1.5rem !important;
    }

    .pt-md-3 {
        padding-top: 1.5rem !important;
    }

    .pb-md-3 {
        padding-bottom: 1.5rem !important;
    }

    .pr-md-3 {
        padding-right: 1.5rem !important;
    }

    .pl-md-3 {
        padding-left: 1.5rem !important;
    }

    .mt-md-4 {
        margin-top: 2rem !important;
    }

    .mb-md-4 {
        margin-bottom: 2rem !important;
    }

    .mr-md-4 {
        margin-right: 2rem !important;
    }

    .ml-md-4 {
        margin-left: 2rem !important;
    }

    .pt-md-4 {
        padding-top: 2rem !important;
    }

    .pb-md-4 {
        padding-bottom: 2rem !important;
    }

    .pr-md-4 {
        padding-right: 2rem !important;
    }

    .pl-md-4 {
        padding-left: 2rem !important;
    }

    .mt-md-5 {
        margin-top: 2.5rem !important;
    }

    .mb-md-5 {
        margin-bottom: 2.5rem !important;
    }

    .mr-md-5 {
        margin-right: 2.5rem !important;
    }

    .ml-md-5 {
        margin-left: 2.5rem !important;
    }

    .pt-md-5 {
        padding-top: 2.5rem !important;
    }

    .pb-md-5 {
        padding-bottom: 2.5rem !important;
    }

    .pr-md-5 {
        padding-right: 2.5rem !important;
    }

    .pl-md-5 {
        padding-left: 2.5rem !important;
    }

    .mt-md-6 {
        margin-top: 3rem !important;
    }

    .mb-md-6 {
        margin-bottom: 3rem !important;
    }

    .mr-md-6 {
        margin-right: 3rem !important;
    }

    .ml-md-6 {
        margin-left: 3rem !important;
    }

    .pt-md-6 {
        padding-top: 3rem !important;
    }

    .pb-md-6 {
        padding-bottom: 3rem !important;
    }

    .pr-md-6 {
        padding-right: 3rem !important;
    }

    .pl-md-6 {
        padding-left: 3rem !important;
    }

    .mt-md-7 {
        margin-top: 3.5rem !important;
    }

    .mb-md-7 {
        margin-bottom: 3.5rem !important;
    }

    .mr-md-7 {
        margin-right: 3.5rem !important;
    }

    .ml-md-7 {
        margin-left: 3.5rem !important;
    }

    .pt-md-7 {
        padding-top: 3.5rem !important;
    }

    .pb-md-7 {
        padding-bottom: 3.5rem !important;
    }

    .pr-md-7 {
        padding-right: 3.5rem !important;
    }

    .pl-md-7 {
        padding-left: 3.5rem !important;
    }

    .mt-md-8 {
        margin-top: 4rem !important;
    }

    .mb-md-8 {
        margin-bottom: 4rem !important;
    }

    .mr-md-8 {
        margin-right: 4rem !important;
    }

    .ml-md-8 {
        margin-left: 4rem !important;
    }

    .pt-md-8 {
        padding-top: 4rem !important;
    }

    .pb-md-8 {
        padding-bottom: 4rem !important;
    }

    .pr-md-8 {
        padding-right: 4rem !important;
    }

    .pl-md-8 {
        padding-left: 4rem !important;
    }

    .mt-md-9 {
        margin-top: 4.5rem !important;
    }

    .mb-md-9 {
        margin-bottom: 4.5rem !important;
    }

    .mr-md-9 {
        margin-right: 4.5rem !important;
    }

    .ml-md-9 {
        margin-left: 4.5rem !important;
    }

    .pt-md-9 {
        padding-top: 4.5rem !important;
    }

    .pb-md-9 {
        padding-bottom: 4.5rem !important;
    }

    .pr-md-9 {
        padding-right: 4.5rem !important;
    }

    .pl-md-9 {
        padding-left: 4.5rem !important;
    }

    .mt-md-10 {
        margin-top: 5rem !important;
    }

    .mb-md-10 {
        margin-bottom: 5rem !important;
    }

    .mr-md-10 {
        margin-right: 5rem !important;
    }

    .ml-md-10 {
        margin-left: 5rem !important;
    }

    .pt-md-10 {
        padding-top: 5rem !important;
    }

    .pb-md-10 {
        padding-bottom: 5rem !important;
    }

    .pr-md-10 {
        padding-right: 5rem !important;
    }

    .pl-md-10 {
        padding-left: 5rem !important;
    }
}

@media (min-width: 992px) {
    .mt-lg-0 {
        margin-top: 0rem !important;
    }

    .mb-lg-0 {
        margin-bottom: 0rem !important;
    }

    .pt-lg-0 {
        padding-top: 0rem !important;
    }

    .pb-lg-0 {
        padding-bottom: 0rem !important;
    }

    .ml-lg-0 {
        margin-left: 0rem !important;
    }

    .mr-lg-0 {
        margin-right: 0rem !important;
    }

    .pl-lg-0 {
        padding-left: 0rem !important;
    }

    .pr-lg-0 {
        padding-right: 0rem !important;
    }

    .mt-lg-1 {
        margin-top: 0.5rem !important;
    }

    .mb-lg-1 {
        margin-bottom: 0.5rem !important;
    }

    .pt-lg-1 {
        padding-top: 0.5rem !important;
    }

    .pb-lg-1 {
        padding-bottom: 0.5rem !important;
    }

    .ml-lg-1 {
        margin-left: 0.5rem !important;
    }

    .mr-lg-1 {
        margin-right: 0.5rem !important;
    }

    .pl-lg-1 {
        padding-left: 0.5rem !important;
    }

    .pr-lg-1 {
        padding-right: 0.5rem !important;
    }

    .mt-lg-2 {
        margin-top: 1rem !important;
    }

    .mb-lg-2 {
        margin-bottom: 1rem !important;
    }

    .pt-lg-2 {
        padding-top: 1rem !important;
    }

    .pb-lg-2 {
        padding-bottom: 1rem !important;
    }

    .ml-lg-2 {
        margin-left: 1rem !important;
    }

    .mr-lg-2 {
        margin-right: 1rem !important;
    }

    .pl-lg-2 {
        padding-left: 1rem !important;
    }

    .pr-lg-2 {
        padding-right: 1rem !important;
    }

    .mt-lg-3 {
        margin-top: 1.5rem !important;
    }

    .mb-lg-3 {
        margin-bottom: 1.5rem !important;
    }

    .pt-lg-3 {
        padding-top: 1.5rem !important;
    }

    .pb-lg-3 {
        padding-bottom: 1.5rem !important;
    }

    .ml-lg-3 {
        margin-left: 1.5rem !important;
    }

    .mr-lg-3 {
        margin-right: 1.5rem !important;
    }

    .pl-lg-3 {
        padding-left: 1.5rem !important;
    }

    .pr-lg-3 {
        padding-right: 1.5rem !important;
    }

    .mt-lg-4 {
        margin-top: 2rem !important;
    }

    .mb-lg-4 {
        margin-bottom: 2rem !important;
    }

    .pt-lg-4 {
        padding-top: 2rem !important;
    }

    .pb-lg-4 {
        padding-bottom: 2rem !important;
    }

    .ml-lg-4 {
        margin-left: 2rem !important;
    }

    .mr-lg-4 {
        margin-right: 2rem !important;
    }

    .pl-lg-4 {
        padding-left: 2rem !important;
    }

    .pr-lg-4 {
        padding-right: 2rem !important;
    }

    .mt-lg-5 {
        margin-top: 2.5rem !important;
    }

    .mb-lg-5 {
        margin-bottom: 2.5rem !important;
    }

    .pt-lg-5 {
        padding-top: 2.5rem !important;
    }

    .pb-lg-5 {
        padding-bottom: 2.5rem !important;
    }

    .ml-lg-5 {
        margin-left: 2.5rem !important;
    }

    .mr-lg-5 {
        margin-right: 2.5rem !important;
    }

    .pl-lg-5 {
        padding-left: 2.5rem !important;
    }

    .pr-lg-5 {
        padding-right: 2.5rem !important;
    }

    .mt-lg-6 {
        margin-top: 3rem !important;
    }

    .mb-lg-6 {
        margin-bottom: 3rem !important;
    }

    .pt-lg-6 {
        padding-top: 3rem !important;
    }

    .pb-lg-6 {
        padding-bottom: 3rem !important;
    }

    .ml-lg-6 {
        margin-left: 3rem !important;
    }

    .mr-lg-6 {
        margin-right: 3rem !important;
    }

    .pl-lg-6 {
        padding-left: 3rem !important;
    }

    .pr-lg-6 {
        padding-right: 3rem !important;
    }

    .mt-lg-7 {
        margin-top: 3.5rem !important;
    }

    .mb-lg-7 {
        margin-bottom: 3.5rem !important;
    }

    .pt-lg-7 {
        padding-top: 3.5rem !important;
    }

    .pb-lg-7 {
        padding-bottom: 3.5rem !important;
    }

    .ml-lg-7 {
        margin-left: 3.5rem !important;
    }

    .mr-lg-7 {
        margin-right: 3.5rem !important;
    }

    .pl-lg-7 {
        padding-left: 3.5rem !important;
    }

    .pr-lg-7 {
        padding-right: 3.5rem !important;
    }

    .mt-lg-8 {
        margin-top: 4rem !important;
    }

    .mb-lg-8 {
        margin-bottom: 4rem !important;
    }

    .pt-lg-8 {
        padding-top: 4rem !important;
    }

    .pb-lg-8 {
        padding-bottom: 4rem !important;
    }

    .ml-lg-8 {
        margin-left: 4rem !important;
    }

    .mr-lg-8 {
        margin-right: 4rem !important;
    }

    .pl-lg-8 {
        padding-left: 4rem !important;
    }

    .pr-lg-8 {
        padding-right: 4rem !important;
    }

    .mt-lg-9 {
        margin-top: 4.5rem !important;
    }

    .mb-lg-9 {
        margin-bottom: 4.5rem !important;
    }

    .pt-lg-9 {
        padding-top: 4.5rem !important;
    }

    .pb-lg-9 {
        padding-bottom: 4.5rem !important;
    }

    .ml-lg-9 {
        margin-left: 4.5rem !important;
    }

    .mr-lg-9 {
        margin-right: 4.5rem !important;
    }

    .pl-lg-9 {
        padding-left: 4.5rem !important;
    }

    .pr-lg-9 {
        padding-right: 4.5rem !important;
    }

    .mt-lg-10 {
        margin-top: 5rem !important;
    }

    .mb-lg-10 {
        margin-bottom: 5rem !important;
    }

    .pt-lg-10 {
        padding-top: 5rem !important;
    }

    .pb-lg-10 {
        padding-bottom: 5rem !important;
    }

    .ml-lg-10 {
        margin-left: 5rem !important;
    }

    .mr-lg-10 {
        margin-right: 5rem !important;
    }

    .pl-lg-10 {
        padding-left: 5rem !important;
    }

    .pr-lg-10 {
        padding-right: 5rem !important;
    }
}

@media (min-width: 1200px) {
    .mt-xl-0 {
        margin-top: 0rem !important;
    }

    .mb-xl-0 {
        margin-bottom: 0rem !important;
    }

    .ml-xl-0 {
        margin-left: 0rem !important;
    }

    .mr-xl-0 {
        margin-right: 0rem !important;
    }

    .pl-xl-0 {
        padding-left: 0rem !important;
    }

    .pr-xl-0 {
        padding-right: 0rem !important;
    }

    .mt-xl-1 {
        margin-top: 0.5rem !important;
    }

    .mb-xl-1 {
        margin-bottom: 0.5rem !important;
    }

    .ml-xl-1 {
        margin-left: 0.5rem !important;
    }

    .mr-xl-1 {
        margin-right: 0.5rem !important;
    }

    .pl-xl-1 {
        padding-left: 0.5rem !important;
    }

    .pr-xl-1 {
        padding-right: 0.5rem !important;
    }

    .mt-xl-2 {
        margin-top: 1rem !important;
    }

    .mb-xl-2 {
        margin-bottom: 1rem !important;
    }

    .ml-xl-2 {
        margin-left: 1rem !important;
    }

    .mr-xl-2 {
        margin-right: 1rem !important;
    }

    .pl-xl-2 {
        padding-left: 1rem !important;
    }

    .pr-xl-2 {
        padding-right: 1rem !important;
    }

    .mt-xl-3 {
        margin-top: 1.5rem !important;
    }

    .mb-xl-3 {
        margin-bottom: 1.5rem !important;
    }

    .ml-xl-3 {
        margin-left: 1.5rem !important;
    }

    .mr-xl-3 {
        margin-right: 1.5rem !important;
    }

    .pl-xl-3 {
        padding-left: 1.5rem !important;
    }

    .pr-xl-3 {
        padding-right: 1.5rem !important;
    }

    .mt-xl-4 {
        margin-top: 2rem !important;
    }

    .mb-xl-4 {
        margin-bottom: 2rem !important;
    }

    .ml-xl-4 {
        margin-left: 2rem !important;
    }

    .mr-xl-4 {
        margin-right: 2rem !important;
    }

    .pl-xl-4 {
        padding-left: 2rem !important;
    }

    .pr-xl-4 {
        padding-right: 2rem !important;
    }

    .mt-xl-5 {
        margin-top: 2.5rem !important;
    }

    .mb-xl-5 {
        margin-bottom: 2.5rem !important;
    }

    .ml-xl-5 {
        margin-left: 2.5rem !important;
    }

    .mr-xl-5 {
        margin-right: 2.5rem !important;
    }

    .pl-xl-5 {
        padding-left: 2.5rem !important;
    }

    .pr-xl-5 {
        padding-right: 2.5rem !important;
    }

    .mt-xl-6 {
        margin-top: 3rem !important;
    }

    .mb-xl-6 {
        margin-bottom: 3rem !important;
    }

    .ml-xl-6 {
        margin-left: 3rem !important;
    }

    .mr-xl-6 {
        margin-right: 3rem !important;
    }

    .pl-xl-6 {
        padding-left: 3rem !important;
    }

    .pr-xl-6 {
        padding-right: 3rem !important;
    }

    .mt-xl-7 {
        margin-top: 3.5rem !important;
    }

    .mb-xl-7 {
        margin-bottom: 3.5rem !important;
    }

    .ml-xl-7 {
        margin-left: 3.5rem !important;
    }

    .mr-xl-7 {
        margin-right: 3.5rem !important;
    }

    .pl-xl-7 {
        padding-left: 3.5rem !important;
    }

    .pr-xl-7 {
        padding-right: 3.5rem !important;
    }

    .mt-xl-8 {
        margin-top: 4rem !important;
    }

    .mb-xl-8 {
        margin-bottom: 4rem !important;
    }

    .ml-xl-8 {
        margin-left: 4rem !important;
    }

    .mr-xl-8 {
        margin-right: 4rem !important;
    }

    .pl-xl-8 {
        padding-left: 4rem !important;
    }

    .pr-xl-8 {
        padding-right: 4rem !important;
    }

    .mt-xl-9 {
        margin-top: 4.5rem !important;
    }

    .mb-xl-9 {
        margin-bottom: 4.5rem !important;
    }

    .ml-xl-9 {
        margin-left: 4.5rem !important;
    }

    .mr-xl-9 {
        margin-right: 4.5rem !important;
    }

    .pl-xl-9 {
        padding-left: 4.5rem !important;
    }

    .pr-xl-9 {
        padding-right: 4.5rem !important;
    }

    .mt-xl-10 {
        margin-top: 5rem !important;
    }

    .mb-xl-10 {
        margin-bottom: 5rem !important;
    }

    .ml-xl-10 {
        margin-left: 5rem !important;
    }

    .mr-xl-10 {
        margin-right: 5rem !important;
    }

    .pl-xl-10 {
        padding-left: 5rem !important;
    }

    .pr-xl-10 {
        padding-right: 5rem !important;
    }
}

@media (min-width: 992px) {
    .ml-lg-auto {
        margin-left: auto !important;
    }

    .mr-lg-auto {
        margin-right: auto !important;
    }

    .mx-lg-auto {
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

/* 5, components */
/* -------------------------------------------
    Accordion
---------------------------------------------- */
.accordion {
    overflow: hidden;
}

.accordion .collapsed,
.accordion .expanding {
    display: none;
}

.card-header {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    color: #222;
}

.card-header a {
    display: flex;
    align-items: center;
    position: relative;
    padding: 1.8rem 0.5rem;
}

.card-header a:hover {
    color: #4dae67;
}

.card-header a:hover span {
    color: #4dae67;
}

.card-header a::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.5rem;
    font-family: "riode";
    font-size: 1.8rem;
    font-weight: 400;
    letter-spacing: -0.01em;
    color: #222;
}

.card-header .collapse {
    color: #4dae67;
}

.expand::after {
    content: "";
}

.collapse::after {
    content: "";
}

.card-body {
    padding: 1.3rem 0.5rem;
}

.card {
    background-color: transparent;
}

.accordion-simple .card {
    border-top: 1px solid #eee;
}

.accordion-gutter-md .card:not(:last-child) {
    margin-bottom: 10px;
}

.accordion-gutter-sm .card:not(:last-child) {
    margin-bottom: 2px;
}

.accordion-plus .expand::after {
    content: "";
}

.accordion-plus .collapse::after {
    content: "";
}

.accordion-boxed .card-header a,
.accordion-boxed .card-body {
    padding-left: 2rem;
    padding-right: 2rem;
}

.accordion-boxed .card-header a::after {
    right: 2.3rem;
}

.accordion-border .card {
    border-width: 1px 1px 0;
    border-style: solid;
    border-color: #eee;
}

.accordion-border .card:last-child {
    border-bottom: 1px solid #eee;
}

.accordion-background .card {
    background-color: #fff;
    border-color: #fff;
}

.accordion-dropshadow {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.12);
}

.accordion-dropshadow .card {
    background-color: #fff;
    border-color: #fff;
}

.accordion-icon .card-header i {
    margin-right: 1.5rem;
    font-size: 1.8rem;
    font-weight: 400;
    letter-spacing: -0.01em;
    line-height: 0;
}

.accordion-card-bg .card {
    border: 1px solid #f2f3f5;
}

.accordion-card-bg .card-header a {
    padding-top: 1.7rem;
    padding-bottom: 1.7rem;
    background-color: #f2f3f5;
}

.accordion-card-bg.accordion-primary .card {
    border: 0;
    background-color: #f2f3f5;
}

.accordion-card-bg.accordion-primary .card-header a {
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
    background-color: #37c;
    color: #fff;
}

.accordion-card-bg.accordion-primary .card-header a::after {
    color: #fff;
}

.accordion-color .collapse {
    color: #4dae67;
}

.accordion-card-border .card {
    border: 1px solid #dae1e5;
}

/* -------------------------------------------
    Alert
        - Default
        - Simple Alert
        - Dark Alert
        - Icon Alert
        - Light Alert
        - Message Alert
        - Button Alert
        - Summary Alert
---------------------------------------------- */
.alert-round {
    border-radius: 3px;
}

.alert {
    position: relative;
    padding: 1.6rem 3.8rem 1.6rem 1.8rem;
    border: 1px solid #222;
    font-size: 1.4rem;
    line-height: 1.75;
}

.alert .btn-close {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 2.1rem;
    transform: translateY(-50%);
    margin-top: -0.1rem;
    font-size: 1.5rem;
    line-height: 0;
}

.alert-title {
    text-transform: uppercase;
    font-size: 1.4rem;
    line-height: inherit;
    color: inherit;
}

.btn-close {
    transition: color 0.3s;
}

.btn-close:hover {
    color: #000;
}

.alert-primary {
    background-color: #4dae67;
    border-color: #4dae67;
    color: #4dae67;
}

.alert-success {
    background-color: #a8c26e;
    border-color: #a8c26e;
    color: #a8c26e;
}

.alert-warning {
    background-color: #fd990b;
    border-color: #fd990b;
    color: #fd990b;
}

.alert-danger {
    background-color: #b10001;
    border-color: #b10001;
    color: #b10001;
}

.alert-black {
    background-color: #222;
    border-color: #222;
}

.alert-black .btn-close:hover {
    color: #666;
}

.alert-simple {
    background-color: transparent;
    border-style: dashed;
    border-color: #cacbcc;
}

.alert-dark {
    color: #fff;
}

.alert-dark .btn-close {
    color: #fff;
}

.alert-icon {
    padding: 1.5rem 3.9rem 1.5rem 1.9rem;
}

.alert-icon>i {
    vertical-align: middle;
    margin-right: 1.2rem;
    line-height: 1;
    font-size: 2.4rem;
    font-weight: 600;
}

.alert-light.alert-primary {
    background-color: #edf4f9;
    border-color: #edf4f9;
}

.alert-light.alert-danger {
    background-color: #f6d6d5;
    border-color: #f6d6d5;
    color: #6d1a17;
}

.alert-light.alert-danger .btn-close {
    color: #6d1a17;
}

.alert-light.alert-warning {
    background-color: #fff5eb;
    border-color: #fff5eb;
}

.alert-message {
    display: block;
    padding: 1.3rem 3.9rem 1.9rem 1.9rem;
}

.alert-message .btn-close {
    position: absolute;
    transform: translateY(0);
    top: 2.7rem;
    right: 2.1rem;
    font-size: 1.4rem;
}

.alert-message .alert-title {
    margin-bottom: 0.5rem;
    font-weight: 700;
    line-height: 1.7;
}

.alert-message p {
    margin: 0;
    padding-right: 2rem;
    line-height: 1.7;
}

.alert-message.alert-link p {
    color: #666;
}

.alert-message.alert-link a {
    color: #4dae67;
}

.alert-message.alert-link a:hover {
    text-decoration: underline;
}

.alert.alert-inline {
    padding-right: 4rem;
}

.alert.alert-inline .alert-title {
    display: inline-block;
    margin: 0 0.8rem 0 0;
}

.alert.alert-inline p {
    display: inline;
}

.alert-btn:not(.alert-message) {
    padding: 0.9rem 3.9rem 0.9rem 0.9rem;
}

.alert-btn .btn:not(.btn-close) {
    margin-right: 1rem;
    padding: 0.73em 1.61em;
}

.alert-btn.alert-message p {
    margin-bottom: 1rem;
}

.alert-btn.alert-dark.alert-warning .btn:not(.btn-close) {
    background-color: transparent;
    padding: 0.7em 1.43em;
    border-width: 3px;
    color: #fff;
}

.alert-btn.alert-dark.alert-warning .btn:not(.btn-close):hover {
    border-color: #df7f50;
    background: #df7f50;
}

.alert-btn.alert-light.alert-warning .btn:not(.btn-close) {
    color: #fd990b;
    background-color: #fff;
    border-color: #fff;
}

.alert-btn.alert-light.alert-warning .btn:not(.btn-close):hover {
    color: #fff;
    background-color: #fd990b;
    border-color: #fd990b;
}

.alert-summary>i {
    margin-right: 0.9rem;
    font-size: 1.6rem;
    line-height: 1.25;
}

.alert-summary ul {
    margin: 1.7rem 0 0 2.7rem;
    list-style-type: disc;
}

/* -------------------------------------------
    Animation
---------------------------------------------- */
@keyframes maskUp {
    from {
        transform: translate(0, 100%);
    }

    to {
        transform: translate(0, 0);
    }
}

@keyframes maskRight {
    from {
        transform: translate(-100%, 0);
    }

    to {
        transform: translate(0, 0);
    }
}

@keyframes maskDown {
    from {
        transform: translate(0, -100%);
    }

    to {
        transform: translate(0, 0);
    }
}

@keyframes maskLeft {
    from {
        transform: translate(100%, 0);
    }

    to {
        transform: translate(0, 0);
    }
}

.maskUp {
    animation-name: maskUp;
}

.maskRight {
    animation-name: maskRight;
}

.maskDown {
    animation-name: maskDown;
}

.maskLeft {
    animation-name: maskLeft;
}

@keyframes fadeInUpShorter {
    from {
        opacity: 0;
        transform: translate(0, 50px);
        transform-origin: 0 0;
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInUpShorter {
    animation-name: fadeInUpShorter;
}

@keyframes fadeInLeftShorter {
    from {
        opacity: 0;
        transform: translate(50px, 0);
        transform-origin: 0 0;
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInLeftShorter {
    animation-name: fadeInLeftShorter;
}

@keyframes fadeInRightShorter {
    from {
        opacity: 0;
        transform: translate(-50px, 0);
        transform-origin: 0 0;
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInRightShorter {
    animation-name: fadeInRightShorter;
}

@keyframes fadeInDownShorter {
    from {
        opacity: 0;
        transform: translate(0, -50px);
        transform-origin: 0 0;
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInDownShorter {
    animation-name: fadeInDownShorter;
}

@keyframes blurIn {
    from {
        opacity: 0;
        filter: blur(20px);
        transform: scale(1.2);
    }

    to {
        opacity: 1;
        filter: blur(0);
        transform: none;
    }
}

.blurIn {
    animation-name: blurIn;
}

@keyframes grayOut {
    from {
        opacity: 1;
        filter: grayscale(0);
    }

    15% {
        filter: grayscale(100%);
    }

    to {
        opacity: 0;
        filter: grayscale(100%);
    }
}

.grayOut {
    animation-name: grayOut;
}

@keyframes dotPulse {
    from {
        opacity: 1;
        transform: scale(0.2);
    }

    to {
        opacity: 0;
        transform: scale(1);
    }
}

.dotPulse {
    animation-name: dotPulse;
    animation-iteration-count: infinite;
    animation-duration: 4s;
}

@keyframes slideInUp {
    0% {
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        transform: translateZ(0);
    }
}

@keyframes slideInDown {
    0% {
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to {
        transform: translateZ(0);
    }
}

@keyframes slideInLeft {
    0% {
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    to {
        transform: translateZ(0);
    }
}

@keyframes slideInRight {
    0% {
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }

    to {
        transform: translateZ(0);
    }
}

@keyframes flipInX {
    0% {
        animation-timing-function: ease-in;
        opacity: 0;
        transform: perspective(400px) rotateX(90deg);
    }

    to {
        transform: perspective(400px);
    }
}

@keyframes flipInY {
    0% {
        animation-timing-function: ease-in;
        opacity: 0;
        transform: perspective(400px) rotateY(90deg);
    }

    to {
        transform: perspective(400px);
    }
}

@keyframes flipOutY {
    0% {
        animation-timing-function: ease-out;
        transform: perspective(400px);
    }

    to {
        opacity: 0;
        transform: perspective(400px) rotateY(90deg);
    }
}

@keyframes brightIn {
    0% {
        animation-timing-function: ease-in;
        filter: brightness(0%);
    }

    to {
        filter: brightness(100%);
    }
}

.brightIn {
    animation-name: brightIn;
}

.appear-animate {
    visibility: hidden;
    opacity: 0;
}

.appear-animation-visible {
    visibility: visible;
    opacity: 1;
}

@keyframes zoomInShorter {
    0% {
        -webkit-transform: scale3d(0.8, 0.8, 0.8);
        opacity: 0;
        transform: scale3d(0.8, 0.8, 0.8);
    }

    50% {
        opacity: 1;
    }
}

.zoomInShorter {
    animation-name: zoomInShorter;
}

@keyframes bounceInUp {

    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
        opacity: 0;
        transform: translate3d(0, 3000px, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0);
    }

    75% {
        transform: translate3d(0, 10px, 0);
    }

    90% {
        transform: translate3d(0, -5px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideZoomIn {
    0% {
        transform: scale3d(1, 1, 1);
        opacity: 1;
    }

    100% {
        transform: scale3d(1.1, 1.1, 1);
        opacity: 1;
    }
}

.slideZoomIn {
    animation-name: slideZoomIn;
}

@keyframes rotateStar {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.label-star {
    align-items: center;
    padding: 9px 7px 5px;
    border-radius: 0.3rem;
    line-height: 1;
}

.label-star::before,
.label-star::after {
    animation: 4s linear 0s infinite rotateStar;
    content: "";
    display: inline-block;
    margin: 0.5rem 0.5rem 0.5rem 1.5rem;
    vertical-align: middle;
    font-family: "Font Awesome 5 Free";
    font-size: 0.467em;
    font-weight: 400;
}

.label-star::before {
    margin-left: 0.5rem;
    animation-direction: reverse;
}

/* -------------------------------------------
    comments
---------------------------------------------- */
.comments {
    padding: 0rem 0;
    border-top: 1px solid #ebebeb;
}

.comments ul ul {
    margin-left: 6rem;
    padding-top: 4.5rem;
}

.comments li {
    margin-bottom: 4.5rem;
}

.comments li:last-child {
    border-bottom-width: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}

@media (max-width: 575px) {
    .comments ul ul {
        margin-left: 3rem;
    }
}

.comment {
    position: relative;
    display: flex;
    align-items: flex-start;
}

.comment p:last-child {
    margin-bottom: 0;
}

.comment-body {
    position: relative;
    flex: 1 1 auto;
    padding: 0.8rem 0 0 2.5rem;
}

.comment-reply {
    color: #777;
    position: absolute;
    right: 0;
    top: 1.7rem;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1;
    letter-spacing: -0.01em;
    padding-bottom: 0.5rem;
    transition: color 0.3s, box-shadow 0.3s;
}

.comment-reply:hover,
.comment-reply:focus {
    color: #4dae67;
    box-shadow: 0 2px 0 #4dae67;
}

.comment-media {
    max-width: 6rem;
    flex: 0 0 6rem;
    height: 6rem;
    overflow: hidden;
}

.comment-media img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.comment-user {
    margin-bottom: 1rem;
}

.comment-user h4 {
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 1;
    letter-spacing: 0;
}

.comment-user h4 a:hover,
.comment-user h4 a:focus {
    color: #4dae67;
}

.comment-user h4 span {
    display: block;
}

.comment-date {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1.3rem;
    color: #999;
    line-height: 1;
}

.reply .form-control {
    max-width: 100%;
    color: #999;
    border-color: #eee;
    background-color: #fff;
    border-radius: 0.3rem;
}

.reply p {
    font-size: 1.3rem;
}

/* @font-face {
    font-family: "riode";
    src: url("../fonts/newfonts/riode.eot_5gap68");
    src: url("../fonts/newfonts/riode.eot?5gap68") format("embedded-opentype"), url("../fonts/newfonts/riode.ttf?5gap68") format("truetype"), url("../fonts/newfonts/riode.woff?5gap68") format("woff"),
        url("https://d-themes.com/html/riode/fonts/riode.svg?5gap68") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
} */
[class^="d-icon-"],
[class*=" d-icon-"] {
    display: inline-block;
    line-height: 1;
}

[class^="d-icon-"]::before,
[class*=" d-icon-"]::before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "riode" !important;
    /* speak: never; */
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.d-icon-truck:before {
    content: "";
}

.d-icon-service:before {
    content: "";
}

.d-icon-secure:before {
    content: "";
}

.d-icon-lock:before {
    content: "";
}

.d-icon-percent:before {
    content: "";
}

.d-icon-layer:before {
    content: "";
}

.d-icon-alert:before {
    content: "";
}

.d-icon-database:before {
    content: "";
}

.d-icon-money:before {
    content: "";
}

.d-icon-shoppingbag:before {
    content: "";
}

.d-icon-t-shirt1:before {
    content: "";
}

.d-icon-t-shirt2:before {
    content: "";
}

.d-icon-hat:before {
    content: "";
}

.d-icon-officebag:before {
    content: "";
}

.d-icon-handbag:before {
    content: "";
}

.d-icon-backpack:before {
    content: "";
}

.d-icon-shoes:before {
    content: "";
}

.d-icon-pillow:before {
    content: "";
}

.d-icon-dress:before {
    content: "";
}

.d-icon-loungewear:before {
    content: "";
}

.d-icon-desktop:before {
    content: "";
}

.d-icon-laptop:before {
    content: "";
}

.d-icon-gamepad1:before {
    content: "";
}

.d-icon-wireless:before {
    content: "";
}

.d-icon-projector:before {
    content: "";
}

.d-icon-drone1:before {
    content: "";
}

.d-icon-drone2:before {
    content: "";
}

.d-icon-radio:before {
    content: "";
}

.d-icon-battery:before {
    content: "";
}

.d-icon-plugin:before {
    content: "";
}

.d-icon-memory:before {
    content: "";
}

.d-icon-ball:before {
    content: "";
}

.d-icon-basketball1:before {
    content: "";
}

.d-icon-babycare:before {
    content: "";
}

.d-icon-card:before {
    content: "";
}

.d-icon-gamepad2:before {
    content: "";
}

.d-icon-camera1:before {
    content: "";
}

.d-icon-camera2:before {
    content: "";
}

.d-icon-babywear:before {
    content: "";
}

.d-icon-abacus:before {
    content: "";
}

.d-icon-pot:before {
    content: "";
}

.d-icon-freezer:before {
    content: "";
}

.d-icon-cook:before {
    content: "";
}

.d-icon-student:before {
    content: "";
}

.d-icon-category:before {
    content: "";
}

.d-icon-basketball2:before {
    content: "";
}

.d-icon-watch:before {
    content: "";
}

.d-icon-tcard:before {
    content: "";
}

.d-icon-heartbeat:before {
    content: "";
}

.d-icon-watch-round:before {
    content: "";
}

.d-icon-washbowl:before {
    content: "";
}

.d-icon-bridge-lamp:before {
    content: "";
}

.d-icon-lamp:before {
    content: "";
}

.d-icon-sofa:before {
    content: "";
}

.d-icon-sofa2:before {
    content: "";
}

.d-icon-bed:before {
    content: "";
}

.d-icon-table:before {
    content: "";
}

.d-icon-table-lamp:before {
    content: "";
}

.d-icon-table-tv:before {
    content: "";
}

.d-icon-mirror:before {
    content: "";
}

.d-icon-volume:before {
    content: "";
}

.d-icon-bars2:before {
    content: "";
}

.d-icon-bars:before {
    content: "";
}

.d-icon-phone:before {
    content: "";
}

.d-icon-user:before {
    content: "";
}

.d-icon-search:before {
    content: "";
}

.d-icon-search2:before {
    content: "";
}

.d-icon-bag:before {
    content: "";
}

i.fa.fa-inr::before {
    content: "\f156";
}

.d-icon-map:before {
    content: "";
}

.d-icon-info:before {
    content: "";
}

.d-icon-refresh:before {
    content: "";
}

.d-icon-left-arrow:before {
    content: "";
}

.d-icon-right-arrow:before {
    content: "";
}

.d-icon-down-arrow:before {
    content: "";
}

.d-icon-up-arrow:before {
    content: "";
}

.d-icon-ruler:before {
    content: "";
}

.d-icon-zoom:before {
    content: "";
}

.d-icon-right-circle:before {
    content: "";
}

.d-icon-left-circle:before {
    content: "";
}

.d-icon-up-circle:before {
    content: "";
}

.d-icon-down-circle:before {
    content: "";
}

.d-icon-angle-right:before {
    content: "";
}

.d-icon-angle-left:before {
    content: "";
}

.d-icon-angle-up:before {
    content: "";
}

.d-icon-angle-down:before {
    content: "";
}

.d-icon-star:before {
    content: "";
}

.d-icon-star-full:before {
    content: "";
}

.d-icon-heart:before {
    content: "";
}

.d-icon-heart-full:before {
    content: "";
}

.d-icon-close:before {
    content: "";
}

.d-icon-play-circle:before {
    content: "";
}

.d-icon-home:before {
    content: "";
}

.d-icon-filter-2:before {
    content: "";
}

.d-icon-switch:before {
    content: "";
}

.d-icon-switch-left-on:before {
    content: "";
}

.d-icon-switch-right-on:before {
    content: "";
}

.d-icon-filter-3:before {
    content: "";
}

.d-icon-check:before {
    content: "";
}

.d-icon-alert-solid:before {
    content: "";
}

.d-icon-headphone:before {
    content: "";
}

.d-icon-mode-grid:before {
    content: "";
}

.d-icon-mode-list:before {
    content: "";
}

.d-icon-cancel:before {
    content: "";
}

.d-icon-instagram:before {
    content: "";
}

.d-icon-solid-check:before {
    content: "";
}

.d-icon-play-solid:before {
    content: "";
}

.d-icon-mobile:before {
    content: "";
}

.d-icon-minus:before {
    content: "";
}

.d-icon-plus:before {
    content: "";
}

.d-icon-wifi:before {
    content: "";
}

.d-icon-times:before {
    content: "";
}

.d-icon-times-circle:before {
    content: "";
}

.d-icon-random:before {
    content: "";
}

.d-icon-th-list:before {
    content: "";
}

.d-icon-rocket:before {
    content: "";
}

.d-icon-map-marker:before {
    content: "";
}

.d-icon-birthday-cake:before {
    content: "";
}

.d-icon-gift:before {
    content: "";
}

.d-icon-female:before {
    content: "";
}

.d-icon-clock:before {
    content: "";
}

.d-icon-comments:before {
    content: "";
}

.d-icon-rotate-left:before {
    content: "";
}

.d-icon-rotate-right:before {
    content: "";
}

.d-icon-reading:before {
    content: "";
}

.d-icon-feeder:before {
    content: "";
}

.d-icon-birthday-cake2:before {
    content: "";
}

.d-icon-skirt:before {
    content: "";
}

.d-icon-toy:before {
    content: "";
}

.d-icon-butterfly:before {
    content: "";
}

.d-icon-babycare2:before {
    content: "";
}

.d-icon-butterfly2:before {
    content: "";
}

.d-icon-dinner-set:before {
    content: "";
}

.d-icon-ball2:before {
    content: "";
}

.d-icon-apple:before {
    content: "";
}

.d-icon-icecream:before {
    content: "";
}

.d-icon-cake:before {
    content: "";
}

.d-icon-meat:before {
    content: "";
}

.d-icon-bread:before {
    content: "";
}

.d-icon-cocktail:before {
    content: "";
}

.d-icon-food:before {
    content: "";
}

.d-icon-birthday-cake3:before {
    content: "";
}

.d-icon-cash:before {
    content: "";
}

.d-icon-service2:before {
    content: "";
}

.d-icon-car:before {
    content: "";
}

.d-icon-ball3:before {
    content: "";
}

.d-icon-compare:before {
    content: "";
}

.d-icon-jar:before {
    content: "";
}

.d-icon-radish:before {
    content: "";
}

.d-icon-arrow-down:before {
    content: "";
}

.d-icon-arrow-left:before {
    content: "";
}

.d-icon-arrow-right:before {
    content: "";
}

.d-icon-arrow-up:before {
    content: "";
}

.d-icon-earth:before {
    content: "";
}

.d-icon-long-arrow-left:before {
    content: "";
}

.d-icon-long-arrow-right:before {
    content: "";
}

.d-icon-long-arrow-down:before {
    content: "";
}

.d-icon-long-arrow-up:before {
    content: "";
}

.d-icon-pill:before {
    content: "";
}

.d-icon-cylinder:before {
    content: "";
}

.d-icon-medical-bag:before {
    content: "";
}

.d-icon-graph:before {
    content: "";
}

/* -------------------------------------------
    Social Links
        - Default Style
        - Other Styles
            - No Border
            - Inline Style
---------------------------------------------- */
.social-links {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
}

.social-links.square-link .social-link {
    border-radius: 0;
}

.social-links.square-link,
.social-links.rounded-link {
    display: flex;
    justify-content: center;
}

.social-links.square-link .social-link,
.social-links.rounded-link .social-link {
    margin: 5px 15px;
    border: none;
    font-size: 24px;
    width: 45px;
    height: 45px;
    line-height: 45px;
}

.social-links.square-link .social-link:not(:hover),
.social-links.rounded-link .social-link:not(:hover) {
    color: #fff;
    background-color: #eee;
}

.social-links.rounded-link .social-link {
    border-radius: 0.3rem;
}

.social-link-active {
    display: flex;
    justify-content: center;
}

.social-link-active .social-link {
    border: none;
    margin: 1rem;
    font-size: 26px;
    line-height: 60px;
    width: 60px;
    height: 60px;
    color: #fff;
}

.social-link-active .social-facebook {
    background: #3b5998;
    border-color: #3b5998;
}

.social-link-active .social-twitter {
    background: #1da1f2;
    border-color: #1da1f2;
}

.social-link-active .social-linkedin {
    background: #0073b2;
    border-color: #0073b2;
}

.social-link-active .social-email {
    background: #dd4b39;
    border-color: #dd4b39;
}

.social-link-active .social-google {
    background: #dd4b39;
    border-color: #dd4b39;
}

.social-link-active .social-pinterest {
    background: #bd081c;
    border-color: #bd081c;
}

.social-link-active .social-reddit {
    background: #ff4107;
    border-color: #ff4107;
}

.social-link-active .social-tumblr {
    background: #304e6c;
    border-color: #304e6c;
}

.social-link-active .social-vk {
    background: #6383a8;
    border-color: #6383a8;
}

.social-link-active .social-youtube {
    background: #4dae67;
    border-color: #4dae67;
}

.social-link-active .social-whatsapp {
    background: #3c8a38;
    border-color: #3c8a38;
}

.social-link-active .social-xing {
    background: #1a7576;
    border-color: #1a7576;
}

.social-link-active .social-instagram {
    background: #7c4a3a;
    border-color: #7c4a3a;
}

.social-link {
    margin: 2px 8px 2px 0;
    width: 30px;
    height: 30px;
    line-height: 28px;
    font-size: 1.5rem;
    border-radius: 50%;
    border: 2px solid #ccc;
    color: inherit;
    transition: color 0.4s, border 0.4s, background 0.4s;
    text-align: center;
}

.social-link:last-child {
    margin-right: 0;
}

.social-link i {
    letter-spacing: -0.001em;
    line-height: 1;
}

.social-link:hover {
    background: #4dae67;
    border-color: #4dae67;
    color: #fff;
}

.social-link.social-facebook:hover {
    background: #3b5998;
    border-color: #3b5998;
}

.social-link.social-twitter:hover {
    background: #1da1f2;
    border-color: #1da1f2;
}

.social-link.social-linkedin:hover {
    background: #0073b2;
    border-color: #0073b2;
}

.social-link.social-email:hover {
    background: #dd4b39;
    border-color: #dd4b39;
}

.social-link.social-google:hover {
    background: #dd4b39;
    border-color: #dd4b39;
}

.social-link.social-pinterest:hover {
    background: #bd081c;
    border-color: #bd081c;
}

.social-link.social-reddit:hover {
    background: #ff4107;
    border-color: #ff4107;
}

.social-link.social-tumblr:hover {
    background: #304e6c;
    border-color: #304e6c;
}

.social-link.social-vk:hover {
    background: #6383a8;
    border-color: #6383a8;
}

.social-link.social-youtube:hover {
    background: #4dae67;
    border-color: #4dae67;
}

.social-link.social-whatsapp:hover {
    background: #3c8a38;
    border-color: #3c8a38;
}

.social-link.social-xing:hover {
    background: #1a7576;
    border-color: #1a7576;
}

.social-link.social-instagram:hover {
    background: #7c4a3a;
    border-color: #7c4a3a;
}

/*
Other Styles
    - No Border
    - Inline Style
*/
.no-border .social-link {
    border: 0;
    line-height: 30px;
}

.no-border .social-link:not(:hover) {
    background: transparent;
}

.no-backgrond .social-link {
    background-color: none;
    line-height: 30px;
}

.no-backgrond .social-link:not(:hover) {
    background: transparent;
}

.inline-links .social-link {
    display: inline-block;
    margin-right: 2rem;
    width: auto;
    height: auto;
    border: 0;
    color: inherit;
}

.inline-links .social-link:not(:last-child) {
    margin-right: 2rem;
}

.inline-links .social-link:hover {
    background: transparent;
}

.inline-links .social-link.social-facebook:hover {
    color: #3b5998;
}

.inline-links .social-link.social-twitter:hover {
    color: #1da1f2;
}

.inline-links .social-link.social-linkedin:hover {
    color: #0073b2;
}

.inline-links .social-link.social-email:hover {
    color: #dd4b39;
}

.inline-links .social-link.social-google:hover {
    color: #dd4b39;
}

.inline-links .social-link.social-pinterest:hover {
    color: #bd081c;
}

.inline-links .social-link.social-reddit:hover {
    color: #ff4107;
}

.inline-links .social-link.social-tumblr:hover {
    color: #304e6c;
}

.inline-links .social-link.social-vk:hover {
    color: #6383a8;
}

.inline-links .social-link.social-whatsapp:hover {
    color: #3c8a38;
}

.inline-links .social-link.social-xing:hover {
    color: #1a7576;
}

.inline-links .social-link.social-instagram:hover {
    color: #7c4a3a;
}

.social-link-template .inline-links .social-link {
    font-size: 24px;
    margin: 3px 19px;
}

.social-link-template .inline-links .social-link:not(:hover) {
    color: #999;
}

.social-default .social-link {
    width: 45px;
    height: 45px;
    margin: 3px 7.5px;
    line-height: 45px;
    font-size: 24px;
}

.social-default .social-link:not(:hover) {
    color: #999;
}

/* -------------------------------------------
  Widget 
------------------------------------------- */
.widget-title {
    margin-bottom: 2rem;
    padding: 1.1rem 0;
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.2;
    border-bottom: 1px solid #eee;
    letter-spacing: -0.025em;
}

.widget-products .widget-title {
    padding: 2.8rem 0.3rem 1.6rem;
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: -0.03em;
}

/* -------------------------------------------
    Form
---------------------------------------------- */
.form .form-control {
    margin-bottom: 2rem;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.85rem 2rem;
    border: 1px solid #e3e3e3;
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: 400;
    color: #666;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    outline: 0;
}

.form-control::placeholder {
    color: inherit;
    transition: color 0.3s;
}

.form-control:focus::placeholder {
    color: transparent;
}

.form-control:focus::-webkit-input-placeholder {
    color: transparent;
}

.form-control:focus::-moz-placeholder {
    color: transparent;
}

.form-control.form-solid {
    background-color: #fff;
    border: 0;
    color: #8d8d8d;
}

textarea {
    font-family: inherit;
    min-width: 100%;
    max-width: 100%;
}

.custom-checkbox {
    position: absolute;
    opacity: 0;
}

.custom-checkbox+label {
    display: block;
    position: relative;
    padding-left: 2.4rem;
    cursor: pointer;
}

.custom-checkbox+label::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 18px;
    height: 18px;
    line-height: 17px;
    border: 1px solid #ccc;
    border-radius: 2px;
}

@media (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .custom-checkbox+label::before {
        line-height: 14px;
    }
}

.custom-checkbox:checked+label::before {
    content: "";
    border-color: #222;
    background: #222;
    color: #fff;
    font-size: 9px;
    font-weight: 600;
    font-family: "Font Awesome 5 Free";
    text-align: center;
}

.select-box,
.select-menu {
    position: relative;
}

.select-box select,
.select-menu select {
    position: relative;
    max-width: 14rem;
    min-height: auto;
    width: auto;
    height: 100%;
    padding-left: 9px;
    padding-right: 25px;
    border: 1px solid #d7d7d7;
    border-radius: 2px;
    color: inherit;
    background-color: transparent;
    font-size: 1.2rem;
    font-family: inherit;
    letter-spacing: inherit;
    z-index: 1;
    /* -moz-appearance: none;
    -webkit-appearance: none; */
}

.select-box select:focus,
.select-menu select:focus {
    outline: none;
}

.select-box::before,
.select-menu::before {
    content: "";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    font-weight: 900;
    font-size: 9px;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
}

.select-box option {
    font-size: 1.2rem;
}

@media (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    select::-ms-expand {
        display: none;
    }

    select:focus::-ms-value {
        background: transparent;
        color: currentColor;
    }
}

/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* -webkit-appearance: none; 
} */

.quantity {
    /* -moz-appearance: textfield; */
    max-width: 48px;
}

.input-group {
    display: inline-flex;
    width: 11rem;
    height: 4.5rem;
}

.input-group .form-control {
    flex: 1;
    padding: 0;
    height: inherit;
    font-size: 1.4rem;
    border: solid #ebebeb;
    border-width: 1px 0;
    color: #222;
    text-align: center;
}

.input-group button {
    padding: 0;
    width: 3.1rem;
    border: 1px solid #ebebeb;
    font-size: 1.2rem;
    color: #222;
    background-color: transparent;
    cursor: pointer;
    /* -webkit-appearance: none; */
}

.custom-radio {
    position: relative;
}

.custom-radio input {
    display: none;
}

input[type="radio"]~label {
    padding-left: 2.5rem;
}

input[type="radio"]~label::before,
input[type="radio"]~label::after {
    position: absolute;
    content: "";
    display: inline-block;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
}

input[type="radio"]~label::before {
    left: 0;
    width: 1.8rem;
    height: 1.8rem;
    background-color: #fff;
    border: 1px solid #cdcdcd;
}

input[type="radio"]~label::after {
    width: 0.8rem;
    height: 0.8rem;
    left: 0.5rem;
    background: #666;
    opacity: 0;
}

input[type="radio"]:checked~label::after {
    opacity: 1;
}

.input-wrapper input.form-control {
    flex: 1;
    min-width: 40px;
    font-size: 1.2rem;
}

.input-wrapper-inline {
    display: flex;
    position: relative;
    max-width: 61.3rem;
    width: 100%;
    height: 45px;
}

.input-wrapper-inline .btn {
    line-height: 1;
}

/* .input-wrapper-round {
   Issue 
} */
.input-wrapper-round .form-control {
    position: relative;
    border-radius: 2.4rem;
}

.input-wrapper-round .btn {
    border-radius: 2.4rem;
    line-height: 1;
}

.input-wrapper-round.input-wrapper-inline .form-control {
    border-radius: 2.4rem 0 0 2.4rem;
}

.input-wrapper-round.input-wrapper-inline .btn {
    border-radius: 0 2.4rem 2.4rem 0;
}

.form-wrapper.form-wrapper-inline {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-wrapper.form-wrapper-inline form {
    flex: 1 1 0;
}

@media (max-width: 991px) {
    .form-wrapper.form-wrapper-inline {
        display: block;
        text-align: center;
    }

    .form-wrapper.form-wrapper-inline form {
        margin-left: auto;
        margin-right: auto;
    }
}

.btn-absolute input.form-control {
    width: 100%;
    padding: 1rem 4rem 1rem 1.5rem;
    color: #666;
    font-size: 1.3rem;
}

.btn-absolute .btn {
    position: absolute;
    width: auto;
    height: 100%;
    min-width: 4.3rem;
    right: 0;
    font-size: 1.7rem;
}

.btn-absolute .btn:hover {
    color: #4dae67;
}

.btn-absolute .btn i {
    margin-left: 0;
}

.dark-theme .form-control:focus option {
    background-color: #fff;
}

/* -------------------------------------------
    Tab
---------------------------------------------- */
.tab-content {
    background-color: transparent;
}

.nav-tabs {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #ebebeb;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-item .nav-link.active,
.nav-tabs .nav-item:hover .nav-link {
    color: #222;
    /* background: var(--base-color); */
}

.nav-tabs .nav-item .nav-link.active h3 {
    color: var(--black);
}

.nav-link {
    display: block;
    margin-bottom: -0.1rem;
    padding: 1.6rem 0.2rem;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0;
    text-align: center;
    border-radius: 0;
    border: 0;
    transition: border 0.35s, color 0.35s, background-color 0.35s;
}

.nav-link-with-img {
    /*position: relative;
  margin-bottom: -4px;
  height: 100%;
  padding: 1.5rem 0 2rem;
  border: 1px solid #22222236;
  border-radius: 100px;
  background: #e1e1e1;*/

    position: relative;
    margin-bottom: 0px;
    height: 100%;
    padding: 10px;
    border: 1px solid #22222236;
    border-radius: 100px;
    background: #e1e1e1;
    margin-right: 10px;
}

.nav-link-with-img figure {
    height: 7.4rem;
    width: 12.1rem;
}

.nav-link-with-img .img-cat-title {
    /* position: absolute; */
    bottom: 1.5rem;
    font-size: 16px;
    font-weight: 400;
    min-width: 12.1rem;
    font-family: var(--font-family-poppins);
}

/* .nav-link-with-img::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom: 4px solid #4dae67;
  transform: scaleX(0) translateX(-50%);
  transform-origin: left;
  transition: transform 0.3s;
} */
.nav-link-with-img.active::after,
.nav-link-with-img:hover::after {
    transform: scaleX(1) translateX(-50%);
}

.nav-item:not(:last-child) {
    margin-right: 2.5rem;
}

.nav-fill .nav-item {
    flex: 1;
}

.tab-content {
    position: relative;
}

.tab-content>.tab-pane {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 0 !important;
    opacity: 0;
    z-index: -1;
    transition: visibility 0.3s, opacity 0.3s;
}

.tab-content>.tab-pane:not(.active) {
    overflow: hidden;
    visibility: hidden;
}

.tab-content>.active {
    position: relative;
    height: auto !important;
    opacity: 1;
    z-index: auto;
}

.tab-pane {
    padding: 2.3rem 0;
    line-height: 1.72;
    color: #666;
}

.tab-pane p {
    line-height: 1.72;
}

.tab-nav-simple .nav-link {
    border-bottom: 2px solid transparent;
    background-color: transparent;
    color: #666;
}

.tab-nav-simple .nav-item.show .nav-link,
.tab-nav-simple .nav-item .nav-link.active,
.tab-nav-simple .nav-item:hover .nav-link {
    border-bottom-color: #4dae67;
}

.tab-nav-center .nav {
    justify-content: center;
}

.tab-nav-right .nav {
    justify-content: flex-end;
}

.tab-nav-boxed .nav-item {
    margin-right: 0;
}

.tab-nav-boxed .nav-link {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
}

.tab-nav-round .nav-item:not(:last-child) {
    margin-right: 2px;
}

.tab-nav-round .nav-link {
    margin-bottom: 0;
    border-radius: 2.3rem;
    background-color: #f2f3f5;
}

.tab-nav-round .nav-tabs {
    border-bottom: 0;
}

.tab-boxed .tab-pane {
    padding-left: 2rem;
    padding-right: 2rem;
}

.tab-outline .tab-content {
    border: 1px solid #ebebeb;
}

.tab-outline .nav-tabs {
    border-bottom: 0;
}

.tab-outline .nav-link {
    padding-top: 1.4rem;
    padding-bottom: 1.5rem;
    border: 1px solid transparent;
    border-top-width: 2px;
}

.tab-outline .nav-item.show .nav-link,
.tab-outline .nav-item .nav-link.active,
.tab-outline .nav-item:hover .nav-link {
    border-color: #ebebeb;
    border-top-color: #4dae67;
}

.tab-outline2 .nav {
    position: relative;
    z-index: 1;
}

.tab-outline2 .tab-content {
    border: 1px solid #ebebeb;
}

.tab-outline2 .nav-tabs {
    border-bottom: 0;
}

.tab-outline2 .nav-link {
    padding: 1.5rem 2.4rem;
    border: 1px solid transparent;
}

.tab-outline2 .nav-item.show .nav-link,
.tab-outline2 .nav-item .nav-link.active,
.tab-outline2 .nav-item:hover .nav-link {
    border-color: #ebebeb;
    border-bottom-color: #fff;
}

.tab-vertical {
    display: flex;
}

.tab-vertical .nav-tabs {
    flex-flow: column nowrap;
    width: 27.8%;
    border: 0;
    border-right: 2px solid #ebebeb;
}

.tab-vertical .tab-content {
    flex: 1;
}

.tab-vertical .nav-item {
    margin-right: 0;
}

.tab-vertical .nav-link {
    position: relative;
    margin-right: -2px;
    padding-left: 2rem;
    width: calc(100% + 2px);
    border: 0;
    text-align: left;
}

.tab-vertical .nav-link::after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    width: 2px;
    height: calc(100% - 6px);
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    z-index: 1;
}

.tab-vertical .tab-pane {
    padding: 1.7rem 2rem;
}

.tab-vertical.tab-simple .nav-link {
    margin-right: -2px;
}

.tab-vertical.tab-simple .nav-link::after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    width: 2px;
    height: calc(100% - 6px);
    top: 50%;
    transform: translateY(-50%) scale(0);
    background-color: transparent;
    transition: transform 0.3s;
    z-index: 1;
}

.tab-vertical.tab-simple .nav-item.show .nav-link,
.tab-vertical.tab-simple .nav-item .nav-link.active,
.tab-vertical.tab-simple .nav-item:hover .nav-link {
    color: #4dae67;
}

.tab-vertical.tab-simple .nav-item.show .nav-link::after,
.tab-vertical.tab-simple .nav-item .nav-link.active::after,
.tab-vertical.tab-simple .nav-item:hover .nav-link::after {
    background-color: #4dae67;
    transform: translateY(-50%) scale(1);
}

@media (max-width: 767px) {
    .tab-vertical {
        flex-flow: column nowrap;
    }

    .tab-vertical .tab-content,
    .tab-vertical .nav-tabs {
        width: 100%;
    }

    .tab-vertical .nav-tabs {
        border: 0;
    }

    .tab-vertical .tab-content {
        padding-left: 0;
    }

    .tab-vertical.tab-simple .nav-link::after {
        height: 2px;
        width: 100%;
        background-color: transparent;
        top: calc(100% - 2px);
        transform: scale(0);
    }

    .tab-vertical.tab-simple .nav-item.show .nav-link::after,
    .tab-vertical.tab-simple .nav-item .nav-link.active::after,
    .tab-vertical.tab-simple .nav-item:hover .nav-link::after {
        transform: scale(1);
    }
}

.tab-inverse .tab-content {
    border-color: #fff;
}

.tab-inverse .nav-tabs {
    border: 0;
}

.tab-inverse .nav-link {
    margin: 0;
    width: 100%;
}

.tab-inverse .nav-item.show .nav-link,
.tab-inverse .nav-item .nav-link.active,
.tab-inverse .nav-item:hover .nav-link {
    background-color: #fff;
    border-color: #fff;
}

.tab-inverse.tab-simple .nav-link {
    padding-top: 1.4rem;
    border-top: 2px solid transparent;
}

.tab-inverse.tab-simple .nav-item.show .nav-link,
.tab-inverse.tab-simple .nav-item .nav-link.active,
.tab-inverse.tab-simple .nav-item:hover .nav-link {
    border-top-color: #4dae67;
}

.tab-nav-solid .nav-link {
    padding-bottom: 1.6rem;
    border: 0;
}

.tab-nav-solid .nav-item.show .nav-link,
.tab-nav-solid .nav-item .nav-link.active,
.tab-nav-solid .nav-item:hover .nav-link {
    color: #fff;
    background-color: #4dae67;
    border-color: #4dae67;
}

@media (max-width: 767px) {
    .nav-link {
        font-size: 1.4rem;
    }

    .tab-nav-boxed .nav-link {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@media (max-width: 479px) {
    .tab-nav-round .nav-link {
        margin-bottom: 0.5rem;
    }
}

.nav-filters {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: #666;
}

.nav-filters li:not(:last-child) {
    margin-right: 1.8rem;
}

.nav-filter {
    display: inline-block;
    position: relative;
    padding: 0.5rem 0;
    font-size: 1.4rem;
    letter-spacing: -0.01em;
    line-height: 1;
    transition: color 0.3s;
}

.nav-filter.active {
    color: #4dae67;
}

.filter-underline .nav-filter {
    border-bottom: 1px solid transparent;
    transition: color 0.3s, border-color 0.3s;
}

.filter-underline .nav-filter.active {
    border-color: #4dae67;
}

/* -------------------------------------------
    Popup
        - Popup
        - Popup Close
        - Popup Arrow
        - Popup Content
        - Popup - Login
        - Popup - Newsletter
        - Popup - Product
        - Popup - Image PhotoSwipe
        - Popup Zoom, Flip effect
        - Responsive
---------------------------------------------- */
.mfp-wrap {
    z-index: 3000;
}

.mfp-bg {
    z-index: 3000;
    background: #000;
    opacity: 0;
    transition: opacity 0.3s ease-out;
}

.mfp-bg.mfp-ready {
    opacity: 0.7;
}

.mfp-bg.mfp-removing {
    opacity: 0;
}

.mfp-container {
    padding: 4rem 2rem;
}

.mfp-container video {
    width: 100%;
    height: 100%;
}

.mfp-close {
    transform: rotateZ(45deg);
    transition: transform 0.3s;
    width: 18px;
    height: 18px;
    opacity: 1;
}

.mfp-wrap .mfp-close {
    top: 20px;
    right: 20px;
}

.mfp-content .mfp-close {
    top: -25px;
    right: 0;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
    top: 15px;
    right: 0;
}

.mfp-close span {
    display: none;
}

.mfp-close::before,
.mfp-close::after {
    content: "";
    display: block;
    position: absolute;
    background-color: #fff;
    width: 2px;
    height: 100%;
    top: 0;
    left: calc(50% - 1px);
}

.mfp-close::before {
    width: 100%;
    height: 2px;
    top: calc(50% - 1px);
    left: 0;
}

.mfp-close:hover,
.mfp-close:focus {
    transform: rotateZ(135deg) scale(1.5);
}

.pswp__button--arrow--left,
.pswp__button--arrow--right,
.mfp-arrow {
    width: 4.8rem;
    height: 4.8rem;
    color: #fff;
    border-radius: 50%;
    font-size: 2.4rem;
    font-weight: 900;
    font-family: "riode";
    text-align: center;
    opacity: 0.5;
    right: 10%;
    transition: opacity 0.3s;
    border: 2px solid;
}

.pswp__ui>button::before {
    background: transparent;
    position: static;
    line-height: 44px;
}

.pswp__ui>button.pswp__button--arrow--left::before {
    margin-right: 3px;
}

.pswp__ui>button.pswp__button--arrow--right::before {
    margin-left: 3px;
}

.pswp__ui>button:hover {
    opacity: 1;
}

.pswp__button--arrow--right::before {
    content: "";
    font-family: "riode";
}

.mfp-arrow::before,
.mfp-arrow::after {
    content: "";
    position: static;
    display: inline;
    margin: 0;
    border: 0;
}

.mfp-arrow::after {
    content: none;
}

button.mfp-arrow {
    border: 2px solid;
}

.pswp__button--arrow--left,
.mfp-arrow-left {
    left: 10%;
}

.pswp__button--arrow--left::before,
.mfp-arrow-left::before {
    content: "";
    font-family: "riode";
}

.mfp-content>* {
    position: relative;
    margin: auto;
    background-color: #fff;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
    opacity: 0;
}

.mfp-ready .mfp-content>* {
    opacity: 1;
}

.mfp-removing .mfp-content>* {
    opacity: 0;
}

.login-popup {
    max-width: 490px;
    padding: 4rem;
    border-radius: 0.3rem;
}

.login-popup .tab {
    font-size: 1.8rem;
    color: #ccc;
}

.login-popup .tab .nav-item {
    flex: none;
}

.login-popup .nav-item .nav-link {
    padding: 0 0 1px;
    color: #999;
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: -0.025em;
    line-height: 2.43;
    transition: font-size 0.3s, color 0.3s;
}

.login-popup .nav-item .nav-link.active {
    font-size: 3rem;
}

.login-popup .delimiter {
    margin: 0 0.8rem;
}

.login-popup .tab-pane {
    padding: 0;
}

.login-popup form {
    margin-bottom: 2.2rem;
}

.login-popup .form-group {
    margin-bottom: 1.8rem;
}

.login-popup .form-group label {
    display: inline-block;
    margin-bottom: 8px;
    font-size: 1.3rem;
    line-height: 1;
}

.login-popup .form-group .form-control {
    padding: 1.2rem 1.5rem 1.1rem;
    height: 3.7rem;
    font-size: 1.3rem;
    color: #999;
    border-radius: 0.3rem;
    border-color: #eee;
}

.login-popup .form-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 2.3rem;
    font-size: 1.2rem;
}

.login-popup .custom-checkbox+label {
    padding-left: 2.6rem;
}

.login-popup .btn {
    height: 4.7rem;
    padding: 0;
    font-size: 16px;
}

.login-popup .lost-link {
    text-align: right;
    color: #999;
    font-size: 1.2rem;
}

.login-popup .lost-link:hover {
    color: #4dae67;
}

.login-popup .form-choice label {
    display: flex;
    align-items: center;
    margin-bottom: 1.3rem;
    font-size: 1.2rem;
    color: #666;
}

.login-popup .form-choice label::after,
.login-popup .form-choice label::before {
    content: "";
    height: 1px;
    flex: 1;
    background: #eee;
}

.login-popup .form-choice label::after {
    margin-left: 3rem;
}

.login-popup .form-choice label::before {
    margin-right: 3rem;
}

.login-popup .social-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.6rem;
    height: 3.6rem;
    font-size: 1.4rem;
    border-radius: 0.3rem;
    color: #fff;
}

.login-popup .social-link.social-google {
    background-color: #db402c;
}

.login-popup .social-link.social-facebook {
    background-color: #3b5998;
}

.login-popup .social-link.social-twitter {
    background-color: #1ab2e8;
}

.container>.login-popup {
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.newsletter-popup {
    text-align: center;
    background-size: cover;
    border-radius: 1rem;
}

.newsletter-popup .btn {
    min-width: auto;
    padding: 0.5em 1.5em;
    font-size: 1.3rem;
}

.newsletter-popup p {
    max-width: 100%;
    margin: 0 0.2rem 2.5rem;
    font-size: 1.4rem;
    line-height: 1.7;
}

.newsletter-popup label {
    display: inline;
    font-size: 1.2rem;
}

.newsletter-popup label::before {
    border-color: #666;
}

.newsletter-popup .email {
    border: 0;
    color: #999;
    background: #f4f4f4;
}

.newsletter-popup .form-control {
    border: 1px solid #222;
    background-color: #fff;
    border-right: none;
}

.newsletter-popup .input-wrapper-inline {
    margin-bottom: 2.6rem;
    height: 4.6rem;
}

.newsletter-popup .input-wrapper-inline .form-control {
    margin: 0;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    border-radius: 0.3rem 0 0 0.3rem;
}

.newsletter-popup .input-wrapper-inline .btn {
    margin: 0;
    border-radius: 0 0.3rem 0.3rem 0;
}

.newsletter-popup .custom-checkbox+label {
    padding-left: 2.6rem;
}

.newsletter-pop1 {
    display: flex;
    max-width: 72rem;
    background-position: 60% center;
}

.newsletter-pop1 h4 {
    margin-bottom: 0.6rem;
    font-size: 2rem;
    font-weight: 800;
    line-height: 1.2;
}

.newsletter-pop1 h2 {
    margin-bottom: 0.7rem;
    font-size: 3.2rem;
    line-height: 1.2;
    letter-spacing: -0.04375em;
}

.newsletter-pop1 h2 span {
    font-weight: 800;
}

.newsletter-pop1 .newsletter-content {
    margin-left: auto;
    max-width: 41.4rem;
    padding: 7.1rem 4.2rem 5rem;
}

@media (max-width: 767px) {
    .newsletter-pop1 .newsletter-content {
        max-width: 36rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .newsletter-pop1 .login-popup {
        max-width: 500px;
        padding: 3.5rem 2rem;
    }
}

@media (max-width: 575px) {
    .newsletter-pop1 {
        background-image: none !important;
    }

    .newsletter-pop1 .newsletter-content {
        margin-left: auto;
        margin-right: auto;
    }
}

.newsletter-pop2 {
    max-width: 50rem;
}

.newsletter-pop2 img {
    border-radius: 1rem 1rem 0 0;
    min-height: 200px;
    object-fit: cover;
}

.newsletter-pop2 h2 {
    margin: 2.5rem 0 0.5rem;
    font-size: 24px;
    line-height: 1.25;
}

.newsletter-pop2 p {
    font-size: 16px;
    line-height: 1.7;
}

.newsletter-pop2 .input-wrapper-inline {
    max-width: 38rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 17px;
}

.newsletter-pop2 .social-links {
    margin: 1.7rem 0 3rem;
}

.newsletter-pop2 .social-link:not(:hover),
.newsletter-pop2 .social-link:not(:hover):before {
    color: #999;
}

.newsletter-pop2 .newsletter-content {
    padding: 0 2rem;
}

.newsletter-pop3 {
    border-radius: 1rem;
    max-width: 54rem;
    padding: 5.4rem 4rem 2.8rem;
}

.newsletter-pop3 h2 {
    font-size: 24px;
    line-height: 1.25;
    margin-bottom: 0;
}

.newsletter-pop3 p {
    margin: 8px 21px 24px;
    font-size: 16px;
    line-height: 1.7;
}

.newsletter-pop3 .input-wrapper-inline {
    flex-direction: column;
    align-items: center;
    height: unset;
    margin-bottom: 4.8rem;
}

.newsletter-pop3 .input-wrapper-inline .btn {
    margin-top: 2rem;
    padding: 1.4rem 4.4rem;
    border-radius: 0.3rem;
}

.newsletter-pop3 .form-control {
    border: 1px solid #eee;
    max-width: 46rem;
}

.newsletter-pop3 .form-control::placeholder {
    text-align: center;
}

.newsletter-pop3 .form-check {
    justify-content: space-between;
    align-items: center;
}

.newsletter-pop3 .form-privacy {
    text-decoration: underline;
}

@media (max-width: 575px) {
    .newsletter-pop3 {
        padding: 5.4rem 2rem 2.8rem;
    }

    .newsletter-pop3 p {
        margin: 8px 5px 24px;
    }

    .newsletter-pop3 .form-check {
        flex-direction: column;
    }
}

.newsletter-pop4 {
    position: relative;
    border-radius: 100%;
    background: transparent;
    max-width: 580px;
    height: 580px;
}

.newsletter-pop4::before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 100%;
    background-color: #191919;
    opacity: 0.6;
}

.newsletter-pop4 h4 {
    font-size: 26px;
    line-height: 1.15;
    margin-bottom: 0;
}

.newsletter-pop4 h2 {
    font-size: 36px;
    line-height: 1.5;
}

.newsletter-pop4 p {
    font-size: 16px;
    line-height: 1.7;
}

.newsletter-pop4 .input-wrapper-inline {
    max-width: 40.6rem;
    margin-left: auto;
    margin-right: auto;
}

.newsletter-pop4 .input-wrapper-inline .form-control {
    border-color: #fff;
    border-radius: 23px 0 0 23px;
}

.newsletter-pop4 .input-wrapper-inline .btn {
    border-radius: 0 23px 23px 0;
}

@media (max-width: 490px) {
    .newsletter-pop4 {
        padding: 0 2rem;
    }

    .newsletter-pop4::before {
        border-radius: 100px;
    }

    .newsletter-pop4 h4 {
        margin-bottom: 2.5rem;
    }
}

.newsletter-pop5 {
    position: relative;
    max-width: 50rem;
}

.newsletter-pop5>figure img {
    min-height: 57rem;
    object-fit: cover;
}

.newsletter-pop5 h2 {
    font-weight: 32px;
    line-height: 1;
}

.newsletter-pop5 p {
    font-size: 16px;
    line-height: 1.7;
    margin-bottom: 23px;
}

.newsletter-pop5 .newsletter-content {
    top: 51%;
    left: 50%;
    max-width: 100%;
    transform: translateX(-50%);
}

.newsletter-pop5 .form-control {
    border-color: #eee;
}

.newsletter-pop5 .input-wrapper-inline {
    width: 38rem;
    max-width: 100%;
    margin-bottom: 18px;
}

.newsletter-pop5 .form-checkbox {
    margin-bottom: 26px;
}

.newsletter-pop5 .social-links {
    position: relative;
}

.newsletter-pop5 .social-links::before,
.newsletter-pop5 .social-links::after {
    position: absolute;
    content: "";
    top: 50%;
    border-top: 1px solid #eee;
    width: 10.6rem;
}

.newsletter-pop5 .social-links:before {
    left: -100%;
}

.newsletter-pop5 .social-links::after {
    right: -100%;
}

.newsletter-pop5 .social-links .social-link {
    width: 36px;
    height: 36px;
    line-height: 36px;
    border: none;
    border-radius: 3px;
    color: #fff;
}

.newsletter-pop5 .social-links .fa-google {
    background-color: #db402c;
}

.newsletter-pop5 .social-links .social-link.social-linkedin:hover {
    background: #db552c;
    border-color: #db552c;
}

.newsletter-pop5 .social-links .fa-facebook-f {
    background-color: #3b5998;
}

.newsletter-pop5 .social-links .fa-twitter {
    background-color: #1ab2e8;
}

@media (max-width: 575px) {

    .newsletter-pop5 .social-links::after,
    .newsletter-pop5 .social-links:before {
        display: none;
    }

    .newsletter-pop5 h2 {
        line-height: 1.1;
    }

    .newsletter-pop5 .newsletter-content {
        top: 45%;
        padding: 0 2rem;
    }
}

.mfp-product .mfp-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem 2rem 4rem;
}

.mfp-product .mfp-content {
    max-width: 98.8rem;
    margin: auto;
}

.mfp-product .product {
    background-color: #fff;
    margin-bottom: 2rem;
    padding: 3rem 1.5rem;
    border-radius: 1rem;
}

.mfp-product .product-single .product-details {
    padding: 0;
}

.mfp-product .product-details {
    position: absolute;
    overflow-y: auto;
    height: 100%;
    top: 0;
}

.mfp-product .mfp-close {
    position: absolute;
    top: -2.7rem;
    right: 0;
}

.mfp-product .owl-nav .owl-next,
.mfp-product .owl-nav .owl-prev {
    width: 3.6rem;
    height: 3.6rem;
    font-size: 3rem;
}

.mfp-product .owl-nav .owl-next {
    right: 1rem;
}

.mfp-product .owl-nav .owl-prev {
    left: 1rem;
}

.dark-theme .mfp-content .product-divider {
    border-color: #eee;
}

.dark-theme .mfp-content .product {
    background-color: #222;
}

.dark-theme .newsletter-popup label,
.dark-theme .newsletter-popup .form-privacy {
    color: #999;
}

.pswp__bg {
    background-color: rgba(0, 0, 0, 0.7);
}

.pswp__img--placeholder--blank {
    background-color: #f2f3f5;
}

.pswp__ui--fit .pswp__caption,
.pswp__ui--fit .pswp__top-bar {
    background-color: transparent;
}

.pswp__caption__center {
    text-align: center;
}

.mfp-ready.mfp-zoom-popup .mfp-content {
    transform: scale(1);
}

.mfp-zoom-popup .mfp-content,
.mfp-removing.mfp-zoom-popup .mfp-content {
    transition: 0.2s ease-in-out;
    transform: scale(0.8);
}

.mfp-ready.mfp-flip-popup .mfp-content {
    transform: translateY(0) perspective(600px) rotateX(0);
}

.mfp-flip-popup .mfp-content,
.mfp-removing.mfp-flip-popup .mfp-content {
    transition: 0.3s ease-out;
    transform: translateY(-20px) perspective(600px) rotateX(20deg);
}

.mfp-content video {
    width: 100%;
    height: 100%;
}

.mfp-video-popup .mfp-content {
    max-width: 1000px;
}

.mfp-video-popup {
    z-index: 10000;
}

.mfp-img-popup .mfp-content {
    width: unset;
}

@media (max-width: 767px) {
    .mfp-product .product {
        padding: 2rem 1rem;
    }

    .mfp-arrow {
        color: #444;
    }

    .mfp-product .product-details {
        position: relative;
    }
}

@media (min-width: 768px) {
    .mfp-product .row {
        margin: 0 -1.5rem;
    }

    .mfp-product .row>.col-md-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .mfp-product .product-gallery {
        margin-bottom: 0;
    }

    .mfp-product .pr-md-3 {
        padding-right: 1.5rem !important;
    }
}

/* -------------------------------------------
   Titles
        - Default
        - Simple
        - Line
        - Underline
        - Icon
        - Link
---------------------------------------------- */
.title {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-items: center;
    text-align: center;
    line-height: 1.1;
    margin-bottom: 2.6rem;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1;
    letter-spacing: -0.0125em;
    color: #222;
}

.title.title-center {
    justify-content: center;
}

.title.title-descri {
    font-size: 3rem;
}

.title-line::after {
    flex: 1;
    margin-left: 3rem;
    content: "";
    height: 2px;
    background-color: #eee;
}

.title-line.title-underline::after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.title-right-line::before {
    flex: 1;
    margin-right: 3rem;
    content: "";
    height: 2px;
    background-color: #eee;
}

@media (max-width: 479px) {
    .title-line::before {
        margin-right: 1.5rem;
    }

    .title-line::after {
        margin-left: 1.5rem;
    }
}

.title-underline {
    display: block;
    text-align: left;
}

.title-underline:after {
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.title-underline span {
    display: inline-block;
    position: relative;
    z-index: 2;
    line-height: 2.28;
}

.title-underline span::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: 0;
    background-color: #4dae67;
}

.title-wrapper .title {
    margin-bottom: 0.5rem;
}

.title-wrapper p {
    margin-bottom: 2.3rem;
    font-size: 1.4rem;
    line-height: 1.71;
    color: #999;
}

.title-simple {
    display: block;
}

.title-simple::before,
.title-simple::after {
    content: none;
}

.title-sm {
    font-size: 2rem;
}

.title-icon {
    flex-direction: column;
}

.title-icon>i {
    margin-top: 1rem;
    font-size: 1.8rem;
    color: #4dae67;
}

.title-icon::before,
.title-icon::after {
    content: none;
}

.title-white {
    color: #fff;
}

.title-white::before,
.title-white::after {
    background-color: #fff;
    opacity: 0.1;
}

.title-white>.title {
    color: #fff;
}

.title-white>.title::before,
.title-white>.title::after {
    background-color: #fff;
    opacity: 0.1;
}

.title-white>p {
    color: #fff;
    opacity: 0.5;
}

.title-link {
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: 1px solid #eee;
}

.title-link::before,
.title-link::after {
    content: none;
}

.title-link a {
    color: #444;
    font-size: 1.2rem;
}

.title-link a:hover {
    color: #4dae67;
}

.title-link i {
    font-size: 1rem;
}

.with-link {
    display: flex;
    justify-content: space-between;
    line-height: 1;
}

.with-link::after {
    background-color: #eee;
}

.with-link a {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
    font-weight: 700;
    font-size: 13px;
    line-height: 2.05em;
    text-transform: uppercase;
    letter-spacing: -0.325px;
    color: #222;
    transition: color 0.3s;
}

.with-link a:hover {
    color: #4dae67;
}

.with-link i {
    margin-left: 0.9rem;
    font-size: 1.9rem;
    line-height: 0;
}

.title-echo {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 7px;
    color: #f3f3f3;
    /* font-size: 8.89rem; */
    padding-bottom: 50px;
}

.title-echo2 {
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #f3f3f3;
    margin: 0px;
    padding: 20px 0px;
}

.title-echo2 span {
    transform: translate(-50%, -50%);
    color: var(--color-text);
    font-size: 20px;
    font-weight: 100;
    font-family: var(--font-family-poppins);
}

.need_sec p {
    font-family: var(--font-family-poppins);
    font-size: 16px;
    line-height: 30px;
    color: var(--color-text);
    text-align: center;
}

.team_sec p {
    font-family: var(--font-family-poppins);
    font-size: 16px;
    line-height: 1.86;
    color: var(--color-text);
    text-align: center;
}

.post-details p {
    font-family: var(--font-family-poppins);
    font-size: 16px;
    line-height: 25px;
    color: #000;
    text-align: left;
    padding: 2px 0px;
    margin: 0px;
}

.post-title a {
    color: #000;
}

.need_sec ul.nav.nav-tabs {
    padding: 25px 0px;
    /* flex-wrap: initial  !important; */
}

.title-echo span {
    /* position: absolute;
    left: 50%;
    top: 50%;
    font-size: 3.74rem;
    transform: translate(-50%, -50%);
    color: var(--color-text);
    font-family: var(--font-family-poppins);
    font-weight: 100; */
    color: var(--color-text);
    font-weight: 600;
    font-family: var(--font-family-poppins);
    font-size: 20px;
    margin-bottom: 1rem;
}

@media (max-width: 767px) {
    .title-echo {
        font-size: 3.2em;
    }
}

/* -------------------------------------------
    Custom Icons
---------------------------------------------- */
.menu-icon {
    display: inline-block;
    color: #fff;
    width: 1.7rem;
}

.menu-icon::before,
.menu-icon::after {
    display: block;
    content: "";
    width: 100%;
}

.menu-icon::before {
    height: 6px;
    border-top: 2px solid;
    border-bottom: 2px solid;
}

.menu-icon::after {
    height: 4px;
    border-bottom: 2px solid;
}

.minicart-icon {
    display: inline-block;
    position: relative;
    /* width: 30px; */
    width: 2.5em;
    /* height: 30px; */
    height: 2.5em;
    margin-top: 1px;
    border: solid 2px;
    border-radius: 2px;
    text-align: center;
    transition: background 0.4s, border-color 0.4s;
}

.minicart-icon::before {
    content: "";
    position: absolute;
    /* top: -9px; */
    top: -0.75em;
    /* left: 7px; */
    left: 0.585em;
    /* width: 12px; */
    width: 1em;
    /* height: 6px; */
    height: 0.5em;
    border-radius: 10px 10px 0 0;
    border: inherit;
    border-bottom: none;
    transition: transform 0.4s;
}

/* -------------------------------------------
  Tooltips 
------------------------------------------- */
.tooltiptext {
    font-family: var(--font-family-poppins);
    position: absolute;
    padding: 1rem 0.7rem;
    bottom: 150%;
    left: 48%;
    background-color: #333;
    color: #fff;
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: 0.01em;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transform: translateX(-50%);
    z-index: 1;
    transition: opacity 0.3s;
}

.tooltip-top::after,
.tooltip-bottom::after {
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -6px;
    border-width: 6px;
    border-style: solid;
}

.tooltip-top::after {
    top: 96%;
    border-color: #333 transparent transparent transparent;
}

.tooltip-bottom::after {
    top: -37%;
    border-color: transparent transparent #333 transparent;
}

/* -------------------------------------------
    Buttons
        - Different Backgrounds
        - Outline Buttons
        - Gradient Buttons
        - Simple Link Buttons
        - Button Sizes
        - Shapes & Shadow
        - With Icon & Animation
        - On Background
---------------------------------------------- */
.btn {
    display: inline-block;
    border: 2px solid #e4eaec;
    background-color: var(--base-color);
    color: #000;
    outline: 0;
    padding: 1.22em 2.78em;
    font-weight: 100;
    font-size: 1.4rem;
    font-family: var(--font-family-poppins);
    letter-spacing: 0;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    transition: color 0.3s, border-color 0.3s, background-color 0.3s, box-shadow 0.3s;
    white-space: nowrap;
    cursor: pointer;
}

.btn:hover,
.btn:active,
.btn:focus {
    color: #222;
    border-color: #cfdadd;
    background-color: #cfdadd;
}

.btn i {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.4rem;
    line-height: 0;
    font-size: 1.9rem;
}

.btn i::before {
    margin: 0;
}

.btn-solid {
    background-color: #fff;
    border-color: #fff;
    color: #222;
}

.btn-solid:hover,
.btn-solid:active,
.btn-solid:focus {
    border-color: #222;
    background-color: #222;
    color: #fff;
}

.btn-outline {
    border: 2px solid #e4eaec;
    color: #666;
    background-color: transparent;
}

.btn-outline:hover,
.btn-outline:active,
.btn-outline:focus {
    border-color: #666;
    background-color: #666;
    color: #fff;
}

.btn-outline.btn-outline-light {
    padding: 1.07em 2.21em;
    border-width: 1px;
}

.btn-outline.btn-bg {
    color: #fff;
}

.btn-gradient {
    position: relative;
    color: #fff;
    border: none;
    overflow: hidden;
    transition: background 0.25s, color 0.25s, border 0.25s;
}

.btn-gradient::after {
    content: "";
    position: absolute;
    top: -50%;
    left: 0;
    right: 0;
    bottom: -50%;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 80%);
    transform: skewX(-45deg) translateX(-100%);
    transition: transform 0.8s;
}

.btn-gradient:hover::after {
    transform: skewX(-45deg) translateX(100%);
}

.btn-gradient.btn-primary {
    background: linear-gradient(135deg, #3b8df1, #5449ff);
}

.btn-gradient.btn-secondary {
    background: linear-gradient(135deg, #ffa35f, #ef5454);
}

.btn-gradient.btn-alert {
    background: linear-gradient(135deg, #f85283, #b33c9e);
}

.btn-gradient.btn-success {
    background: linear-gradient(135deg, #a0ec69, #87cf47);
}

.btn-gradient.btn-dark {
    background: linear-gradient(135deg, #666, #222);
}

.btn-icon-left i,
.btn-icon-right i,
.btn-reveal-left i,
.btn-reveal-right i {
    line-height: 0;
}

.btn-icon-right {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.btn-icon-right i {
    margin-left: 0.7rem;
}

.btn-icon-left {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.btn-icon-left i {
    margin-right: 0.7rem;
    margin-left: 0;
}

.btn-reveal-left i {
    opacity: 0;
    margin-left: -1em;
    transform: translateX(0.5em);
    margin-right: 0;
    transition: transform 0.3s, opacity 0.3s, margin 0.3s;
}

.btn-reveal-left:hover i,
.btn-reveal-left:active i,
.btn-reveal-left:focus i {
    opacity: 1;
    margin-left: 0;
    transform: translateX(0);
    margin-right: 0.7rem;
}

.btn-reveal-right i {
    opacity: 0;
    margin-right: -1em;
    transform: translateX(-0.5em);
    margin-left: 0;
    transition: transform 0.3s, opacity 0.3s, margin 0.3s;
}

.btn-reveal-right:hover i,
.btn-reveal-right:active i,
.btn-reveal-right:focus i {
    opacity: 1;
    margin-left: 0.7rem;
    transform: translateX(0);
    margin-right: 0;
}

.btn-slide-left:hover i,
.btn-slide-left:active i,
.btn-slide-left:focus i {
    animation: 0.5s ease slideLeft;
}

.btn-slide-right:hover i,
.btn-slide-right:active i,
.btn-slide-right:focus i {
    animation: 0.5s ease slideRight;
}

.btn-slide-up:hover i,
.btn-slide-up:active i,
.btn-slide-up:focus i {
    animation: 0.5s ease slideUp;
}

.btn-slide-down:hover i,
.btn-slide-down:active i,
.btn-slide-down:focus i {
    animation: 0.5s ease slideDown;
}

.btn-infinite:hover i {
    animation-iteration-count: infinite;
}

@keyframes slideLeft {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(-0.5em);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideRight {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(0.5em);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-0.5em);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes slideDown {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(0.5em);
    }

    100% {
        transform: translateY(0);
    }
}

.btn-shadow-lg {
    box-shadow: 0 13px 20px -10px rgba(0, 0, 0, 0.15);
}

.btn-shadow-lg:hover,
.btn-shadow-lg:active,
.btn-shadow-lg:focus {
    box-shadow: 0 13px 20px -10px rgba(0, 0, 0, 0.2);
}

.btn-shadow {
    box-shadow: 0 15px 20px -15px rgba(0, 0, 0, 0.15);
}

.btn-shadow:hover,
.btn-shadow:active,
.btn-shadow:focus {
    box-shadow: 0 15px 20px -15px rgba(0, 0, 0, 0.2);
}

.btn-shadow-sm {
    box-shadow: 0 17px 20px -20px rgba(0, 0, 0, 0.15);
}

.btn-shadow-sm:hover,
.btn-shadow-sm:active,
.btn-shadow-sm:focus {
    box-shadow: 0 17px 20px -20px rgba(0, 0, 0, 0.2);
}

.btn-underline:hover::after,
.btn-underline:active::after,
.btn-underline:focus::after {
    transform: scaleX(1);
}

.btn-underline::after {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1px;
    width: 100%;
    border-bottom: 2px solid;
    transform: scaleX(0);
    transition: transform 0.3s;
    content: "";
}

.btn-underline i {
    margin-bottom: 2px;
}

.btn-underline.btn-underline-visible::after {
    transform: scaleX(1);
}

.btn-underline.btn-underline-width-sm::after {
    height: 1px;
    border-bottom: 0;
}

.btn-underline.sm::after {
    width: 46%;
}

.btn-underline.lg::after {
    margin-left: -16.5%;
    margin-right: -16.5%;
    width: 133%;
}

.btn-link {
    padding: 0;
    color: #222;
    border: 0;
    background-color: transparent;
}

.btn-link:hover,
.btn-link:active,
.btn-link:focus {
    background-color: transparent;
}

.btn-disabled {
    cursor: not-allowed;
    background-color: #e4eaec !important;
    color: #999 !important;
    border-color: #e4eaec !important;
    box-shadow: none !important;
}

.btn-disabled::before,
.btn-disabled::after {
    content: none !important;
}

.btn.btn-rounded {
    border-radius: 3px;
}

.btn.btn-rounded::before,
.btn.btn-rounded::after {
    border-radius: 3px;
}

.btn.btn-block {
    display: block;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
}

.btn.btn-ellipse {
    border-radius: 3rem;
}

.btn.btn-lg {
    padding: 1.5em 3.42em;
}

.btn.btn-md {
    padding: 1.07em 2.15em;
    font-size: 1.3rem;
}

.btn.btn-sm {
    padding: 0.92em 1.61em;
    font-size: 1.3rem;
}

.btn.btn-link {
    padding: 0;
}

@media (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .btn.btn-link {
        overflow: visible;
    }
}

.btn-primary {
    color: var(--black);
    border-color: var(--base-color);
    background-color: var(--base-color);
    font-weight: 100;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
    color: #fff;
    border-color: #64ba7b;
    background-color: #64ba7b;
}

.btn-primary.btn-solid {
    color: #000;
    border-color: #000;
    background-color: transparent;
}

.btn-primary.btn-solid:hover,
.btn-primary.btn-solid:active,
.btn-primary.btn-solid:focus {
    border-color: #4dae67;
    background-color: #4dae67;
    color: #fff;
}

.btn-primary.btn-outline {
    color: #4dae67;
    border-color: #4dae67;
    background-color: transparent;
}

.btn-primary.btn-outline:hover,
.btn-primary.btn-outline:active,
.btn-primary.btn-outline:focus {
    background-color: #4dae67;
    color: #fff;
}

.btn-primary.btn-link {
    background-color: transparent;
    color: var(--base-color);
    /*color: var(--white);*/
    font-family: var(--font-family-poppins) !important;
    text-transform: capitalize;
    font-weight: 100 !important;
    font-size: 16px !important;
}

.btn-primary.btn-link:hover,
.btn-primary.btn-link:active,
.btn-primary.btn-link:focus {
    color: #222;
}

.btn-primary.btn-underline:hover,
.btn-primary.btn-underline:active,
.btn-primary.btn-underline:focus {
    color: #4dae67;
}

.btn-secondary {
    color: #fff;
    border-color: #fd990b;
    background-color: #fd990b;
}

.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus {
    color: #fff;
    border-color: #fda82e;
    background-color: #fda82e;
}

.btn-secondary.btn-solid {
    color: #fd990b;
    border-color: #fff;
    background-color: #fff;
}

.btn-secondary.btn-solid:hover,
.btn-secondary.btn-solid:active,
.btn-secondary.btn-solid:focus {
    border-color: #fd990b;
    background-color: #fd990b;
    color: #fff;
}

.btn-secondary.btn-outline {
    color: #fd990b;
    border-color: #fd990b;
    background-color: transparent;
}

.btn-secondary.btn-outline:hover,
.btn-secondary.btn-outline:active,
.btn-secondary.btn-outline:focus {
    background-color: #fd990b;
    color: #fff;
}

.btn-secondary.btn-link {
    background-color: transparent;
    color: #fd990b;
}

.btn-secondary.btn-link:hover,
.btn-secondary.btn-link:active,
.btn-secondary.btn-link:focus {
    color: #222;
}

.btn-secondary.btn-underline:hover,
.btn-secondary.btn-underline:active,
.btn-secondary.btn-underline:focus {
    color: #fd990b;
}

.btn-alert {
    color: #fff;
    border-color: #b10001;
    background-color: #b10001;
}

.btn-alert:hover,
.btn-alert:active,
.btn-alert:focus {
    color: #fff;
    border-color: #d50001;
    background-color: #d50001;
}

.btn-alert.btn-solid {
    color: #b10001;
    border-color: #fff;
    background-color: #fff;
}

.btn-alert.btn-solid:hover,
.btn-alert.btn-solid:active,
.btn-alert.btn-solid:focus {
    border-color: #b10001;
    background-color: #b10001;
    color: #fff;
}

.btn-alert.btn-outline {
    color: #b10001;
    border-color: #b10001;
    background-color: transparent;
}

.btn-alert.btn-outline:hover,
.btn-alert.btn-outline:active,
.btn-alert.btn-outline:focus {
    background-color: #b10001;
    color: #fff;
}

.btn-alert.btn-link {
    background-color: transparent;
    color: #b10001;
}

.btn-alert.btn-link:hover,
.btn-alert.btn-link:active,
.btn-alert.btn-link:focus {
    color: #222;
}

.btn-alert.btn-underline:hover,
.btn-alert.btn-underline:active,
.btn-alert.btn-underline:focus {
    color: #b10001;
}

.btn-success {
    color: #fff;
    border-color: #a8c26e;
    background-color: #a8c26e;
}

.btn-success:hover,
.btn-success:active,
.btn-success:focus {
    color: #fff;
    border-color: #b7cd87;
    background-color: #b7cd87;
}

.btn-success.btn-solid {
    color: #a8c26e;
    border-color: #fff;
    background-color: #fff;
}

.btn-success.btn-solid:hover,
.btn-success.btn-solid:active,
.btn-success.btn-solid:focus {
    border-color: #a8c26e;
    background-color: #a8c26e;
    color: #fff;
}

.btn-success.btn-outline {
    color: #a8c26e;
    border-color: #a8c26e;
    background-color: transparent;
}

.btn-success.btn-outline:hover,
.btn-success.btn-outline:active,
.btn-success.btn-outline:focus {
    background-color: #a8c26e;
    color: #fff;
}

.btn-success.btn-link {
    background-color: transparent;
    color: #a8c26e;
}

.btn-success.btn-link:hover,
.btn-success.btn-link:active,
.btn-success.btn-link:focus {
    color: #222;
}

.btn-success.btn-underline:hover,
.btn-success.btn-underline:active,
.btn-success.btn-underline:focus {
    color: #a8c26e;
}

.btn-dark {
    color: #fff;
    border-color: #222;
    background-color: #222;
}

.btn-dark:hover,
.btn-dark:active,
.btn-dark:focus {
    color: #fff;
    border-color: #343434;
    background-color: #343434;
}

.btn-dark.btn-solid {
    color: #222;
    border-color: #fff;
    background-color: #fff;
}

.btn-dark.btn-solid:hover,
.btn-dark.btn-solid:active,
.btn-dark.btn-solid:focus {
    border-color: #222;
    background-color: #222;
    color: #fff;
}

.btn-dark.btn-outline {
    color: #222;
    border-color: #222;
    background-color: transparent;
}

.btn-dark.btn-outline:hover,
.btn-dark.btn-outline:active,
.btn-dark.btn-outline:focus {
    background-color: #222;
    color: #fff;
}

.btn-dark.btn-link {
    background-color: transparent;
    color: #222;
}

.btn-dark.btn-link:hover,
.btn-dark.btn-link:active,
.btn-dark.btn-link:focus {
    color: #222;
}

.btn-dark.btn-underline:hover,
.btn-dark.btn-underline:active,
.btn-dark.btn-underline:focus {
    color: #222;
}

.btn-white {
    color: #222;
    background-color: #fff;
    border-color: #fff;
}

.btn-white:hover,
.btn-white:active,
.btn-white:focus {
    color: #fff;
    border-color: #4dae67;
    background-color: #4dae67;
}

.btn-white.btn-outline {
    color: #fff;
    background-color: transparent;
    border-color: #fff;
}

.btn-white.btn-outline:hover,
.btn-white.btn-outline:active,
.btn-white.btn-outline:focus {
    color: #4dae67;
    background-color: #fff;
}

.btn-white.btn-link {
    color: #fff;
    background-color: transparent;
}

.btn-white.btn-link:hover,
.btn-white.btn-link:active,
.btn-white.btn-link:focus {
    color: #fff;
    background-color: transparent;
}

.dark-theme .btn-white:not(:hover),
.dark-theme .btn-outline:not(:hover) {
    border-color: #666;
    color: #fff;
}

.dark-theme .btn-link:not(.btn-primary) {
    color: #d7d7d7;
}

.dark-theme .btn-dark {
    color: #fff;
    border-color: #050505;
    background-color: #050505;
}

.dark-theme .btn-dark:hover,
.dark-theme .btn-dark:active,
.dark-theme .btn-dark:focus {
    color: #fff;
    border-color: #171717;
    background-color: #171717;
}

.dark-theme .btn-dark.btn-solid {
    color: #050505;
    border-color: #fff;
    background-color: #fff;
}

.dark-theme .btn-dark.btn-solid:hover,
.dark-theme .btn-dark.btn-solid:active,
.dark-theme .btn-dark.btn-solid:focus {
    border-color: #050505;
    background-color: #050505;
    color: #fff;
}

.dark-theme .btn-dark.btn-outline {
    color: #050505;
    border-color: #050505;
    background-color: transparent;
}

.dark-theme .btn-dark.btn-outline:hover,
.dark-theme .btn-dark.btn-outline:active,
.dark-theme .btn-dark.btn-outline:focus {
    background-color: #050505;
    color: #fff;
}

.dark-theme .btn-dark.btn-link {
    background-color: transparent;
    color: #050505;
}

.dark-theme .btn-dark.btn-link:hover,
.dark-theme .btn-dark.btn-link:active,
.dark-theme .btn-dark.btn-link:focus {
    color: #222;
}

.dark-theme .btn-dark.btn-underline:hover,
.dark-theme .btn-dark.btn-underline:active,
.dark-theme .btn-dark.btn-underline:focus {
    color: #050505;
}

/* -------------------------------------------
    Blog
        - Default
        - Video
        - List
        - Image gap
        - Ovelay
        - Large
        - Filters
---------------------------------------------- */
.post {
    font-family: var(--font-family-poppins);
}

.post .btn i {
    margin-left: 6px;
}

.post .btn i::before {
    margin: 0;
}

.post .post-details>*:last-child {
    margin-bottom: 0;
    margin-top: 10px;
}

.post-calendar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 4.5rem;
    height: 4.7rem;
    background: rgba(255, 255, 255, 0.8);
    color: #222;
    font-weight: 600;
    line-height: 1;
    border-radius: 0.3rem;
}

.post-calendar .post-day {
    display: block;
    margin-bottom: 1px;
    font-size: 1.6rem;
}

.post-calendar .post-month {
    display: block;
    margin-left: 2px;
    font-size: 1rem;
}

.post-media {
    position: relative;
    width: 100%;
    margin-bottom: 0;
    overflow: hidden;
    border-radius: 0.3rem;
}

.post-media img {
    display: block;
    width: 100%;
    height: auto;
    transition: transform 0.3s;
}

.post-media .post-calendar {
    position: absolute;
    left: 2rem;
    top: 2rem;
}

.post-media .owl-dots {
    bottom: 2.5rem;
}

.post-media .owl-dots .owl-dot span {
    border-color: #fff;
    background-color: transparent;
}

.post-media .owl-dots .owl-dot:hover span {
    border-color: #fff;
    background-color: #fff;
}

.post-details {
    padding: 2rem 0 2.5rem;
}

.post-meta {
    margin-bottom: 4px;
    font-family: inherit;
    font-size: 1.3rem;
    font-weight: inherit;
    line-height: 1.2;
    color: #999;
}

.post-meta a,
.post-meta span {
    font-weight: 400;
    color: #222;
}

.post-meta a {
    text-transform: capitalize;
}

.post-meta a:hover {
    color: #4dae67;
}

.post-meta span {
    transition: color 0.3s;
}

.post-meta .post-author {
    text-transform: uppercase;
}

.post-meta .post-comment {
    margin-bottom: 4px;
    font-family: inherit;
    font-size: 1.3rem;
    font-weight: inherit;
    line-height: 1.2;
    color: #999;
}

.post-meta .post-comment:hover span {
    color: inherit;
}

.post-title {
    margin-bottom: 1.2rem;
    font-size: 20px;
    font-family: var(--font-family-poppins);
    font-weight: 100 !important;
    line-height: 1.5;
}

.post-content {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 1.8rem;
    line-height: 1.72;
    letter-spacing: 0;
    color: #666;
}

@media (min-width: 576px) {
    .post-classic .post-title {
        font-size: 2.4rem;
    }
}

.post-sm .post-details {
    padding: 2.2rem 0.3rem 2rem;
}

.inner-video .post-media {
    position: relative;
}

.inner-video .video-play {
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: color 0.3s, opacity 0.3s;
    font-family: "Font Awesome 5 Free";
    font-size: 6rem;
    color: #fff;
    cursor: pointer;
    z-index: 10;
}

.inner-video .video-play::before {
    content: "";
}

.inner-video video {
    display: none;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.inner-video.playing video,
.inner-video.paused video {
    display: block;
}

.inner-video.playing .video-play,
.inner-video.paused .video-play {
    opacity: 0;
}

.inner-video.playing .video-play:before,
.inner-video.paused .video-play:before {
    content: "";
}

.inner-video.playing .post-media,
.inner-video.paused .post-media {
    background-color: #000;
}

.inner-video.playing .post-media:hover .video-play,
.inner-video.paused .post-media:hover .video-play {
    opacity: 1;
}

.inner-video.playing img,
.inner-video.paused img {
    visibility: hidden;
}

.inner-video.paused .video-play {
    opacity: 1;
}

.inner-video.paused .video-play:before {
    content: "";
}

.post-list {
    margin-bottom: 2rem;
}

.post-list .post-details {
    padding: 2rem 0;
}

.post-list img {
    min-height: 20rem;
    object-fit: cover;
}

@media (min-width: 576px) {
    .post-list {
        display: flex;
        align-items: center;
    }

    .post-list .post-media {
        margin-right: 2rem;
    }

    .post-list .post-details,
    .post-list .post-media {
        width: calc(50% - 1rem);
    }
}

.post-list-xs {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    margin-left: 2px;
}

.post-list-xs .post-calendar {
    color: #ccc;
    border-color: #ccc;
    background-color: transparent;
}

.post-list-xs .post-details {
    flex: 1;
    margin: 0 0 0 2rem;
    padding: 0;
}

.post-list-xs .post-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0;
    text-transform: none;
    font-size: inherit;
    font-weight: 400;
    line-height: 1.69;
    color: inherit;
    white-space: normal;
}

.post-list-xs .post-title:hover a {
    color: #fff;
}

.post-list-sm {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.post-list-sm .post-media {
    max-width: 9rem;
    flex: 0 0 9rem;
    height: 9rem;
}

.post-list-sm .post-media img {
    height: 100%;
    object-fit: cover;
}

.post-list-sm .post-details {
    padding: 0;
    margin-left: 1.6rem;
}

.post-list-sm .post-meta {
    margin-bottom: 0.4rem;
}

.post-list-sm .post-meta a {
    font-size: 1.3rem;
    font-weight: 400;
    color: #999;
}

.post-list-sm .post-title {
    margin-bottom: 0;
    white-space: normal;
    text-transform: none;
    font-size: 1.4rem;
    font-weight: 600;
    letter-spacing: 0;
}

.post-col .post {
    margin: 0;
    padding: 1rem 0.3rem;
}

.post-image-gap {
    padding: 2rem;
    background-color: #fff;
    transition: box-shadow 0.3s;
}

.post-image-gap .post-details {
    padding-bottom: 1rem;
}

.post-image-gap:hover {
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
}

.post-mask {
    position: relative;
}

.post-mask::before {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.75;
    background: rgba(51, 51, 51, 0.5);
    transition: all 0.3s;
    pointer-events: none;
    z-index: 1;
}

.post-mask img {
    min-height: 19rem;
}

.post-mask.gradient::before {
    height: 50%;
    background: linear-gradient(to bottom, rgba(125, 185, 232, 0) 0%, #000 100%);
}

.post-mask .post-details {
    position: absolute;
    padding: 0;
    left: 3rem;
    right: 3rem;
    bottom: 2.2rem;
    z-index: 2;
    transform: translateY(4rem);
    transition: transform 0.4s;
}

.post-mask .post-meta {
    color: #fff;
}

.post-mask .post-meta a,
.post-mask .post-meta span {
    color: inherit;
}

.post-mask .post-meta a {
    font-weight: 400;
}

.post-mask .post-title {
    margin: 0;
    text-transform: none;
    line-height: 1.32;
    color: #fff;
}

.post-mask .post-title a:hover {
    color: #fff;
}

.post-mask .btn-link {
    margin-top: 1.5rem;
    opacity: 0;
    transition: opacity 0.4s;
}

.post-mask:hover .post-media a::before {
    opacity: 0.1;
}

.post-mask:hover .post-details {
    transform: translateY(0);
}

.post-mask:hover .btn-link {
    opacity: 1;
}

.post-frame {
    padding: 2rem 2rem 0;
    box-shadow: 0px 5px 20px 3px rgba(0, 0, 0, 0.05);
}

.post-frame .post-details {
    padding: 2.2rem 0 2rem;
}

.post-frame .post-content {
    margin-bottom: 1.8rem;
    line-height: 1.72;
}

ul.blog-filters {
    padding-top: 4rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #333;
    border-top: 1px solid #eee;
}

ul.blog-filters li {
    margin-right: 2rem;
    margin-left: 2rem;
}

ul.blog-filters .nav-filter {
    letter-spacing: 0;
}

.blog-filters span {
    margin-left: 0.8rem;
    color: #aaa;
}

.blog-filters li {
    padding: 0;
    margin-right: 0.8rem;
    line-height: 2.143;
    border-bottom: 2px solid transparent;
}

.blog-filters a.nav-filter {
    border-width: 2px;
}

.post-outer .post-details {
    position: relative;
    background-color: #fff;
    width: calc(100% - 100px);
    min-width: 75%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1rem;
    text-align: center;
    transform: translateY(-50%);
    padding: 2rem 0 2.5rem;
}

.post-outer .post-details .btn {
    font-size: 1.4rem;
    font-weight: 600;
    letter-spacing: -0.025em;
}

.post-outer .post-meta {
    color: #222;
}

.post-outer .post-title {
    letter-spacing: -0.025em;
    margin-bottom: 1.8rem;
}

.owl-carousel.blog-centerzoom-carousel .post {
    position: relative;
}

.owl-carousel.blog-centerzoom-carousel .post-details {
    background-color: #1d1d1d;
}

.owl-carousel.blog-centerzoom-carousel .btn {
    font-size: 13px;
}

.owl-carousel.blog-centerzoom-carousel .owl-nav .owl-prev {
    top: 80.5%;
    left: 10.5%;
}

.owl-carousel.blog-centerzoom-carousel .owl-nav .owl-next {
    top: 80.5%;
    right: 10.5%;
}

@media (min-width: 992px) {
    .owl-carousel.blog-centerzoom-carousel .owl-item {
        padding-bottom: 300px;
    }

    .owl-carousel.blog-centerzoom-carousel .post-details {
        position: absolute;
    }

    .owl-carousel.blog-centerzoom-carousel .owl-item .post-media {
        transform: scale(0.75);
        transition: transform 0.2s;
        transform-origin: top center;
    }

    .owl-carousel.blog-centerzoom-carousel .owl-item .post-details {
        width: 75%;
        top: 75%;
        transition: width 0.2s, top 0.2s, left 0.2s;
    }

    .owl-carousel.blog-centerzoom-carousel .center .post-media {
        transform: scale(1.5);
    }

    .owl-carousel.blog-centerzoom-carousel .center .post-details {
        top: 150%;
        width: 150%;
        left: -25%;
    }

    .owl-carousel.blog-centerzoom-carousel .owl-item:not(.active)+.owl-item:not(.active) .post-media,
    .owl-carousel.blog-centerzoom-carousel .active+.owl-item:not(.active) .post-media {
        transform-origin: top left;
    }

    .owl-carousel.blog-centerzoom-carousel .owl-item:not(.active)+.active .post-media {
        transform-origin: top left;
    }

    .owl-carousel.blog-centerzoom-carousel .center+.active .post-media {
        transform-origin: top right;
    }

    .owl-carousel.blog-centerzoom-carousel .center+.active .post-details {
        left: 25%;
    }
}

/* -------------------------------------------
    Pagination
---------------------------------------------- */
.pagination {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 1.4rem;
    letter-spacing: 0.01em;
    justify-content: center;
    color: #222;
}

.page-link-prev i,
.page-link-next i {
    font-size: 1.9rem;
}

.page-link-prev i {
    margin-right: 0.5rem;
}

.page-link-next i {
    margin-left: 0.5rem;
}

.page-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 3rem;
    height: 4rem;
    padding: 0 0.3rem;
    line-height: 1;
    background-color: transparent;
    border: 0.1rem solid transparent;
    transition: border 0.3s;
}

:hover:not(.disabled)>.page-link,
.active>.page-link {
    border-color: #dadada;
    color: #222;
}

.disabled>.page-link {
    color: #aaa;
}

.page-link-next,
.page-link-prev {
    min-width: 8rem;
    padding: 1.2rem;
}

.page-item:not(:last-child) {
    margin-right: 0.5rem;
}

.page-item-dots {
    white-space: nowrap;
}

/* .page-item-dots::before {
    content: "...";
    margin-top: -0.9em;
    margin-right: 0.5rem;
    display: inline-block;
    vertical-align: middle;
} */

.show-info {
    margin: 0;
    color: #222;
}

.show-info span {
    margin: 0 0.5rem;
    color: #222;
}

.page-item-total {
    margin-right: 0.5rem;
}

@media (min-width: 576px) {

    .page-item-dots,
    .page-item-total {
        margin-right: 2rem;
    }
}

.dark-theme .show-info {
    color: #999;
}

/* -------------------------------------------
    Page Header
        - Page Subtitle
        - Page Title
        - Breadcrumb
---------------------------------------------- */
.page-subtitle {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-size: 2rem;
    line-height: 1.2;
}

.page-title {
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 1.125;
    color: #222;
}

.page-title+.breadcrumb {
    padding: 1em 0;
}

.breadcrumb-nav .breadcrumb {
    padding: 1.4rem 0;
}

.breadcrumb-nav .breadcrumb li:not(:last-child)::after {
    margin-left: 0.8rem;
    opacity: 0.5;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #666;
    font-family: var(--font-family-poppins);
    font-size: 1.4rem;
}

.breadcrumb a {
    color: #999;
}

.breadcrumb a:hover {
    color: #222;
}

.breadcrumb li:not(:last-child) a {
    color: #999;
    transition: color 0.3s;
}

.breadcrumb li:hover:not(:last-child) a {
    color: #222;
}

.breadcrumb .delimiter {
    margin: 0 0.7em;
    font-size: 1.19rem;
    opacity: 0.5;
}

.breadcrumb li:not(:last-child)::after {
    content: "";
    position: relative;
    margin: 0 0.6em;
    font-size: 1.2rem;
    font-weight: 600;
    font-family: "Font Awesome 5 Free";
    color: #999;
}

.breadcrumb li:last-child {
    color: #222;
}

.breadcrumb-sm {
    padding: 1.1rem 0;
    color: inherit;
}

.breadcrumb-sm li:last-child {
    color: inherit;
}

.page-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 250px;
    padding: 3em 1em;
    background-color: #c8c3bf;
    background-position: center;
    background-size: cover;
    text-align: center;
    color: #222;
}

.page-header .breadcrumb {
    justify-content: center;
}

.page-header .breadcrumb li:not(:last-child)::after {
    content: none;
}

.page-header>.page-subtitle,
.page-header>.page-title,
.page-header>.breadcrumb li,
.page-header>.breadcrumb li a {
    color: #fff;
}

.page-header>.breadcrumb li:not(:last-child) a {
    opacity: 0.5;
    transition: opacity 0.3s;
}

.page-header>.breadcrumb li:hover a {
    color: #fff;
    opacity: 1;
}

@media (max-width: 767px) {
    .page-title {
        font-size: 3.6rem;
    }
}

/* -------------------------------------------
    Products 
        - Default
        - Classic
        - Slideup
            - SlideUp-Content
            - SlideUp
        - Gallery
        - List
            - List
            - Small

        - Product Filters
---------------------------------------------- */
.equal-height .product {
    height: 100%;
}

.product-shadow {
    transition: box-shadow 0.3s;
}

.product-shadow:hover {
    box-shadow: 0 15px 25px -20px rgba(0, 0, 0, 0.5);
}

.product-label-round .product-label {
    padding: 0.5rem 1.1rem;
    border-radius: 1rem;
}

.text-center .product-details {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.product-wrap {
    margin-bottom: 2rem;
}

.product {
    position: relative;
    overflow: hidden;
    font-family: var(--font-family-poppins);
    font-size: 1.4rem;
    color: #999;
    transition: 0.3s;
    border: 1px solid var(--base-color);
    border-radius: 20px;
    padding: 0;
    background: red;
    ;
}

.product:hover .product-action,
.product:hover .product-action-vertical {
    visibility: visible;
    opacity: 1;
}

/* .product-media:hover img:first-child {
    opacity: 0;
    
}

.product-media:hover img:last-child {
    opacity: 1;
} */

.product-media {
    position: relative;
    margin-bottom: 0;
    transition: box-shadow 0.3s;
}

.product-media img {
    display: block;
    width: 100%;
    height: auto;
    transition: all 0.5s;
}

.product-media img:last-child {
    position: absolute;
    opacity: 0;
    left: 0;
    right: 0;
    top: 0;
    left: 0;
    /* object-fit: cover; */
}

.product-media img:first-child {
    position: relative;
    opacity: 1;
}

.product-label-group {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 1rem;
    top: 1rem;
    max-width: 6rem;
}

.product-label {
    display: inline-block;
    text-align: center;
    margin-bottom: 0.5rem;
    padding: 0.5rem 1.1rem;
    font-size: 12px;
    border-radius: 0px 0px 10px 10px;
    font-weight: 600;
    line-height: 20px;
    color: #fff;
    text-transform: uppercase;
}

.product-label.label-new {
    background: #4dae67;
}

.product-label.label-sale {
    background: #000;
}

.product-label.label-top {
    background: #4dae67;
}

.product-label.label-stock {
    background: #999;
}

.btn-product-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.6rem;
    height: 3.6rem;
    margin-bottom: 0.5rem;
    border: 1px solid #eee;
    border-radius: 50%;
    background-color: #fff;
    color: #999;
    font-size: 1.6rem;
    font-weight: 700;
    transition: border-color 0.3s, color 0.3s, background-color 0.3s;
}

.btn-product-icon:hover {
    border-color: var(--base-color);
    color: #fff;
    background-color: var(--base-color);
}

.btn-product-icon:not(.btn-wishlist) i {
    margin-bottom: 2px;
}

.btn-product-icon.btn-quickview i {
    font-size: inherit;
}

.btn-product-icon.btn-compare i {
    font-size: 1.8rem;
}

.btn-product {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 1.14em 0;
    background-color: #4dae67;
    color: var(--black);
    font-family: var(--font-family-poppins);
    font-size: 1.4rem;
    font-weight: 100;
    line-height: 1.2;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    transition: opacity 0.3s;
}

.btn-product:hover {
    color: #fff;
}

.btn-quickview {
    opacity: 0.8;
    transition: opacity 0.3s;
}

.btn-quickview:hover {
    opacity: 1;
}

.btn-quickview i {
    font-size: 1.4rem;
}

.btn-wishlist {
    line-height: 1;
}

.btn-wishlist.loading::before,
.btn-wishlist.loading::after {
    z-index: 1;
}

#ingredients_section .product-action-vertical {
    display: block;
}

.product-action-vertical {
    position: absolute;
    bottom: 15px;
    right: 15px;
    transition: opacity 0.3s, visibility 0.3s;
    opacity: 0;
    visibility: hidden;
    /*display: none;*/
}

.product-action-vertical .btn-wishlist.loading {
    overflow: hidden;
}

.product-action {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: transparent;
    z-index: 10;
    transition: opacity 0.3s, visibility 0.3s;
    opacity: 0;
}

.product-details {
    position: relative;
    background: transparent;
    padding-top: 1.4rem;
    padding-bottom: 2rem;
}

.product-details>.btn-wishlist {
    position: absolute;
    top: 1.2rem;
    right: 0;
    color: #999;
    z-index: 1;
    font-size: 1.6rem;
}

.product-details>.btn-wishlist i {
    display: inline-block;
    margin: 0;
    transition: transform 0.3s;
}

.product-details>.btn-wishlist:hover {
    color: #4dae67;
}

.product-details>.btn-wishlist:hover i {
    transform: rotateY(180deg);
}

.product-details> :last-child {
    margin-bottom: 0;
}

.product-details .product-action {
    position: relative;
    opacity: 1;
    visibility: visible;
}

.product-cat {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.product-cat a:hover {
    color: #4dae67;
}

.product-name {
    margin-bottom: 0.3rem;
    font-family: inherit;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    font-weight: 100;
    letter-spacing: -0.01em;
    color: var(--color-text);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: var(--font-family-poppins);
}

.product-name a:hover {
    color: #4dae67;
}

.product-price {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0.3rem;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.86;
    color: #222;
}

.product-price .old-price {
    color: #aaa;
}

.product-price .new-price {
    margin-right: 1rem;
    text-decoration: none;
}

.dark-theme .old-price {
    color: #666;
}

.dark-theme .product.product-with-qty .quantity,
.dark-theme .product.product-with-qty button {
    color: #999;
}

.dark-theme .rating-reviews {
    color: #999;
}

.dark-theme .ratings-full::before {
    color: #666;
}

.ratings-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1px;
    line-height: 1.1;
    /*margin-bottom: 1.2rem;*/
    font-size: 11px;
    letter-spacing: 0.2em;
}

.ratings-full {
    cursor: pointer;
}

.ratings-full,
.ratings {
    position: relative;
    font-family: "riode";
}

.ratings-full+.rating-reviews {
    margin-left: 1rem;
}

.ratings-full::before {
    content: "" "" "" "" "";
    color: rgba(0, 0, 0, 0.16);
    font-family: "riode";
}

.ratings-full:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.ratings {
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    overflow: hidden;
}

.ratings::before {
    content: "" "" "" "" "";
    color: #fd990b;
    font-family: "riode";
}

.rating-reviews {
    margin: 2px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.1rem;
    color: #aaa;
    letter-spacing: 0;
    text-transform: capitalize;
}

.rating-reviews:hover {
    color: #4dae67;
}

.product-variations {
    display: flex;
}

.ratings-container+.product-variations {
    margin-top: -5px;
}

.product-variations>a:not(.size-guide) {
    display: inline-block;
    position: relative;
    margin: 3px 5px 3px 0;
    padding: 0 5px;
    border-radius: 2px;
    background-color: transparent;
    background-size: cover;
    font-size: 1.2rem;
    font-family: inherit;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    width: 2.6rem;
    height: 2.6rem;
    color: #222;
    transition: 0.3s;
    border: 1px solid #eee;
}

.product-variations>a:not(.size-guide):last-child {
    margin-right: 0;
}

.product-variations .color:not(.active) {
    border: none;
}

.product-variations>a.active,
.product-variations>a:not(.size-guide):hover {
    border: 1px solid;
    box-shadow: inset 0 0 0 2px #fff;
}

.product-details .product-action,
.product-hide-details .product-action {
    position: relative;
    opacity: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.product-details .product-action .btn-wishlist,
.product-hide-details .product-action .btn-wishlist {
    position: static;
}

.product-details .btn-cart,
.product-hide-details .btn-cart {
    display: block;
    max-width: 14rem;
    line-height: 2.9;
    padding: 0 0.6em;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #222;
    background-color: transparent;
    border: 2px solid #ccc;
    margin-right: 5px;
    border-radius: 0.3rem;
}

.product-details .btn-cart i,
.product-hide-details .btn-cart i {
    margin-right: 0.8rem;
    font-size: 1.8rem;
}

.product-details .btn-cart,
.product-hide-details .btn-cart {
    flex: 1;
    height: 4.2rem;
    font-size: 1.3rem;
    transition: color 0.3s, background-color 0.3s, border-color 0.3s;
    z-index: 1;
}

.product-details .btn-quickview,
.product-hide-details .btn-quickview {
    margin: 0 0 0 1.6rem;
}

.product-details .btn-product-icon,
.product-hide-details .btn-product-icon {
    width: auto;
    border: 0;
    background-color: transparent;
    color: #222;
    transition: color 0.3s, background-color 0.3s, visibility 0.3s;
}

.product-details .btn-product-icon i,
.product-hide-details .btn-product-icon i {
    font-size: 1.8rem;
}

.product-details .btn-product-icon:hover,
.product-hide-details .btn-product-icon:hover {
    color: #4dae67;
}

.product:hover .product-action .btn-cart {
    color: #fff;
    background-color: #444;
    border-color: #444;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {

    .product-hide-details .btn-cart,
    .product-details .btn-cart {
        flex: 0 0 12.3rem;
    }
}

@media screen and (-ms-high-contrast: active) and (max-width: 575px),
(-ms-high-contrast: none) and (max-width: 575px) {

    .product-hide-details .btn-cart,
    .product-details .btn-cart {
        flex: 0 0 9.5rem;
    }
}

.product-classic .btn-quickview {
    opacity: 1;
}

.product-classic .btn-quickview i:before {
    content: "";
}

.product-classic .btn-wishlist {
    margin: 0 0 0 1.6rem;
}

.product-classic .btn-wishlist,
.product-classic .btn-quickview {
    transform: translateX(-200%);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.3s, opacity 0.3s, visibility 0.3s, color 0.3s, background-color 0.3s, border-color 0.3s;
}

.product-classic .btn-quickview {
    transition: transform 0.5s, opacity 0.5s, visibility 0.5s, color 0.3s, background-color 0.3s, border-color 0.3s;
}

.product-classic:hover .btn-wishlist,
.product-classic:hover .btn-quickview {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
}

.product-classic .product-details {
    padding-left: 0;
}

.product-classic .ratings-container {
    margin-bottom: 1.5rem;
}

.product-slideup-content {
    overflow: hidden;
}

.product-slideup-content .product-hide-details {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    transition: transform 0.3s;
    background-color: #fff;
}

.product-slideup-content .product-details {
    padding-bottom: 1px;
    background-color: #fff;
    transition: transform 0.3s;
}

.product-slideup-content .btn-wishlist,
.product-slideup-content .btn-quickview {
    opacity: 0;
    visibility: hidden;
    transition: transform 0.4s, opacity 0.3s, visibility 0.3s, color 0.3s, background-color 0.3s;
}

.product-slideup-content .btn-quickview {
    transform: translateX(-200%);
}

.product-slideup-content .btn-wishlist {
    transform: translateX(200%);
}

.product-slideup-content:hover .btn-wishlist,
.product-slideup-content:hover .btn-quickview {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
}

.product-slideup .product-details {
    padding-bottom: 0;
    background-color: #fff;
    transition: transform 0.3s;
}

.product-slideup .ratings-container {
    margin-bottom: 0;
}

.product-slideup .btn-wishlist,
.product-slideup .btn-quickview {
    opacity: 0;
    visibility: hidden;
    transition: transform 0.4s, opacity 0.3s, visibility 0.3s, color 0.3s, background-color 0.3s;
}

.product-slideup .btn-quickview {
    transform: translateX(-200%);
}

.product-slideup .btn-wishlist {
    transform: translateX(200%);
}

.product-slideup .product-action {
    display: flex;
    position: absolute;
    padding-top: 1.5rem;
    top: 100%;
    bottom: auto;
    visibility: hidden;
    opacity: 0;
}

.product-slideup:hover {
    transform: translateY(-58px);
}

.product-slideup:hover .btn-wishlist,
.product-slideup:hover .btn-quickview {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
}

.product-slideup:hover .product-action {
    visibility: visible;
    opacity: 1;
}

.product-slideup .btn-cart,
.product-slideup-content .btn-cart {
    margin-right: 0;
}

.product-slideup .btn-wishlist,
.product-slideup-content .btn-wishlist {
    position: static;
    margin: 0 1.6rem 0 0;
}

.split-line {
    overflow: hidden;
}

.product-image-gap {
    padding: 0.9rem;
    border: 1px solid #eee;
    background-color: #fff;
}

.product-image-gap .product-details {
    padding: 1.8rem 1rem 1rem;
}

.product-image-gap .ratings-container {
    margin-bottom: 0;
}

.product-image-gap:hover {
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.05);
}

@media (max-width: 479px) {
    .product-image-gap .product-details {
        padding: 1.8rem 0 1rem;
    }
}

.product-list {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.product-list .product-media {
    flex: 0 0 28rem;
    max-width: 28rem;
    margin: 0 2rem 0 0;
}

.product-list .product-details {
    padding: 0;
    flex: 1;
}

.product-list .product-label {
    font-size: 1.1rem;
}

.product-list .btn-quickview {
    opacity: 1;
}

.product-list .btn-wishlist {
    margin: 0 0 0 0.8rem;
}

.product-list .btn-wishlist,
.product-list .btn-quickview {
    transform: translateX(-200%);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.3s, opacity 0.3s, visibility 0.3s, color 0.3s, background-color 0.3s;
}

.product-list .btn-quickview {
    transition: transform 0.5s, opacity 0.5s, visibility 0.5s, color 0.3s, background-color 0.3s;
}

.product-list .ratings-container {
    margin-bottom: 0.8rem;
}

.product-list .product-name {
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: -0.025em;
    color: #222;
}

.product-list .product-price {
    font-size: 1.8rem;
    font-weight: 600;
}

.product-list .product-short-desc {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 2rem;
    text-transform: none;
    font-size: 1.4rem;
    line-height: 1.78;
    color: #666;
}

.product-list:hover .btn-quickview,
.product-list:hover .btn-wishlist {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
}

.product-list-sm {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.product-list-sm .product-media {
    flex: 0 0 10rem;
    max-width: 10rem;
    margin: 0 1rem 0 0;
}

.product-list-sm .product-details {
    flex: 0 0 calc(100% - 10rem);
    max-width: calc(100% - 10rem);
    padding: 0;
}

.product-list-sm .product-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0.7rem;
    white-space: normal;
    color: #222;
    padding-left: 0;
}

.product-list-sm .product-name:hover {
    color: #4dae67;
}

.product-list-sm .ratings-container {
    margin: 0;
}

.product-list-sm .product-price {
    font-size: 1.4rem;
}

.product-list-sm+.product-list {
    margin-top: 2rem;
}

.product-list-sm .btn-cart {
    margin-top: 2rem;
    height: 3.3rem;
    max-width: 11.7rem;
    border: 1px solid #d7d7d7;
    color: #fd990b;
    background: transparent;
    font-size: 1.2rem;
}

.product-list-sm .btn-cart:hover {
    border-color: #fd990b;
    background: #fd990b;
}

.product.text-center .product-cat {
    padding: 0;
}

/*.product.text-center .product-price,
.product.text-center .product-variations,
.product.text-center .ratings-container,
.product.text-center .product-action {
  justify-content: center;
}*/
.product.shadow-media:hover .product-media {
    box-shadow: 0px 20px 20px -16px rgba(0, 0, 0, 0.5);
}

.product.cart-full .product-price,
.product.product-with-qty .product-details .product-price {
    margin-bottom: 1rem;
}

.product.cart-full .btn-cart,
.product.product-with-qty .product-details .btn-cart {
    font-size: 16px;
    padding: 0;
    background: var(--base-color);
    font-family: var(--font-family-poppins);
    color: var(--black);
    border-radius: 5px;
    line-height: 38px;
    font-weight: 100 !important;
    text-transform: capitalize;
}

.product-action {
    padding: 10px 0px;
}

.product.cart-full .btn-cart:hover,
.product.cart-full .btn-cart:focus,
.product.cart-full .btn-cart:active,
.product.product-with-qty .product-details .btn-cart:hover,
.product.product-with-qty .product-details .btn-cart:focus,
.product.product-with-qty .product-details .btn-cart:active {
    background-color: var(--base-color);
    border-color: var(--base-color);
    color: var(--black);
    font-weight: 100 !important;
}

.product.cart-full .btn-cart {
    max-width: none;
    width: 100%;
}

.product.product-with-qty .product-details {
    padding-left: 0;
    padding-right: 0;
}

.product.product-with-qty .btn-cart {
    max-width: 20rem;
    margin-right: 0;
}

.product.product-with-qty .btn-cart i {
    display: none;
}

.product.product-with-qty .product-quantity {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2rem;
}

.product.product-with-qty button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: #333;
    font-size: 1rem;
    width: 24px;
    height: 24px;
    border-radius: 0.3rem;
    border: none;
    background: #f4f4f4;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.product.product-with-qty button:hover {
    background-color: #4dae67;
    color: #fff;
}

.product.product-with-qty .quantity {
    min-height: auto;
    width: 3.2rem;
    padding: 0;
    font-weight: 700;
    font-size: 1.2rem;
    border: none;
    text-align: center;
    color: #222;
}

.product.product-variable:not(.cart-full) .btn-cart::after {
    display: none;
    font-family: "riode";
    content: "";
    font-size: 1.8rem;
    font-weight: 400;
}

.product:hover.product-with-qty .btn-cart {
    background-color: transparent;
    color: #222;
}

@media (max-width: 575px) {
    .product-list {
        display: block;
    }

    .product-list .product-media {
        margin: 0;
        max-width: 100%;
    }

    .product-list .product-details {
        padding: 1.8rem 0 2rem;
    }

    .product-action-vertical {
        top: 10px;
        right: 10px;
    }
}

@media (max-width: 479px) {

    .product-details .btn-cart,
    .product-hide-details .btn-cart {
        max-width: 4.2rem;
        display: flex;
    }

    .product-details .btn-cart span,
    .product-hide-details .btn-cart span {
        display: none;
    }

    .product-details .btn-cart i,
    .product-hide-details .btn-cart i {
        margin-right: 0;
    }

    .product.product-with-qty .product-details .btn-cart {
        max-width: 4.2rem;
        padding: 0.78em 0;
    }

    .product.product-with-qty .btn-cart i {
        display: block;
    }

    .product.product-with-qty .product-quantity {
        margin-right: 1rem;
    }

    .cart-full .product-details .btn-cart span {
        display: inline;
    }

    .cart-full .btn-cart i {
        display: none;
    }

    .product-label-group {
        left: 1.2rem;
        top: 1.2rem;
    }

    .btn-quickview {
        font-size: 1.1rem;
    }

    .btn-product-icon {
        width: 3.2rem;
        height: 3.2rem;
        font-size: 1.3rem;
    }

    .product.product-variable:not(.cart-full) .btn-cart::after {
        display: block;
    }

    .product.product-variable:not(.cart-full) .btn-cart i {
        display: none;
    }
}

.product-filters {
    margin-right: 1.5rem;
}

/* -------------------------------------------
    Banner
        - Default
        - Video Banner
        - Hover Effect - Button Hide
---------------------------------------------- */
.banner {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    font-size: 1rem;
}

.banner figure img {
    display: block;
    width: 100%;
    height: auto;
}

.banner.inner-banner::before {
    content: "";
    position: absolute;
    left: 1rem;
    right: 1rem;
    top: 1rem;
    bottom: 1rem;
    border: 2px solid rgba(225, 225, 225, 0.3);
    z-index: 1;
}

.banner.inner-banner .banner-content {
    z-index: 2;
}

.banner .banner-content {
    position: relative;
}

.banner-radius {
    border-radius: 1rem;
    overflow: hidden;
}

.banner-subtitle {
    letter-spacing: -0.025em;
    line-height: 30px;
    padding-bottom: 20px;
}

.banner-title,
.banner-price-info {
    line-height: 1;
}

.banner-fixed>.container,
.banner-fixed>.container-fluid,
.banner-fixed>.banner-content {
    position: absolute;
    z-index: 1;
}

.banner-fixed>.container,
.banner-fixed>.container-fluid {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.banner-fixed.content-middle .banner-content {
    top: 50%;
    transform: translateY(-50%);
}

.banner-fixed.content-center .banner-content {
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
}

.banner-fixed.content-center.content-middle .banner-content {
    transform: translate(-50%, -50%);
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .intro-slider .banner img {
        height: 100%;
        width: 100%;
    }
}

.video-banner {
    position: relative;
}

.video-banner .btn-play {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 49px;
    width: 51px;
    height: 51px;
    font-size: 22px;
    border-radius: 50%;
    border: 1px solid;
    transition: background-color 0.3s, border-color 0.3s;
}

.video-banner .btn-play:hover {
    background-color: #4dae67;
    border-color: #4dae67;
}

.banner-button-hide .banner-content {
    transition: padding 0.3s;
}

.banner-button-hide .btn {
    position: absolute;
    bottom: 0;
    transform: translateX(-50%);
    left: 50%;
    transition: 0.3s;
}

.banner-button-hide:not(:hover) .btn {
    margin: 0;
    visibility: hidden;
    opacity: 0;
}

.banner-button-hide:hover .banner-content {
    padding-bottom: 6.2rem;
}

@media (max-width: 991px) {
    .banner {
        font-size: 8px;
    }
}

@media (max-width: 479px) {
    .banner {
        font-size: 7px;
    }
}

/* -------------------------------------------
    Categories
        Base
        - Default
        - Carousel
        - Icon Category
        - Ellipse Category
        - Semi Circle Category
        - Background
        - Group Category
            - Image
            - Icon
        - Banner Category (Masonry)
        - Overlay Category
        - Block Category
---------------------------------------------- */
.category {
    position: relative;
    font-size: 1rem;
}

.category img {
    display: block;
    /*width: 100%;*/
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.category .category-name {
    margin-bottom: 0.3rem;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.2;
    color: inherit;
    text-transform: capitalize;
}

.category .category-name a {
    color: inherit;
    color: #000;
}

.category .category-count {
    font-size: 1.2rem;
    line-height: 1.2;
}

.category-rounded .category-media {
    overflow: hidden;
    border-radius: 1rem;
}

.category-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    transition: background 0.3s;
}

.category-absolute .category-content {
    cursor: pointer;
    position: absolute;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    height: 5.2rem;
    border-radius: 0 0 1rem 1rem;
    overflow: hidden;
}

.category-absolute .btn {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transform: translateY(100%);
    opacity: 0;
    transition: transform 0.3s, opacity 0.3s;
    line-height: 1;
}

.category-absolute .category-content:hover .btn {
    transform: translateY(0);
    opacity: 1;
}

.category-default .category-content {
    background-color: #fff;
    border-radius: 3px;
}

.category-default .category-name,
.category-light .category-name {
    margin-bottom: 0;
    color: #222;
    letter-spacing: -0.025em;
}

.category-default .btn,
.category-badge .btn {
    padding: 0;
    line-height: 5rem;
}

.category-default1 .category-content {
    background-color: #fff;
    color: #222;
}

.category-default1 .category-name {
    margin: 0;
}

.category-default1:hover .category-content {
    background-color: #4dae67;
    color: #fff;
}

.category-light .category-name {
    padding: 1.8rem 3.9rem;
    background-color: #fff;
    border-radius: 0.3rem;
    transition: background-color 0.3s, padding 0.3s;
}

.category-light .category-name a {
    position: relative;
    transition: padding 0.3s;
}

.category-light .category-name a::after {
    content: "";
    display: inline-block;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-family: "riode";
    font-size: 1.6rem;
    font-weight: 400;
    opacity: 0;
    transition: opacity 0.3s;
}

.category-light:hover .category-name {
    padding-left: 2.8rem;
    padding-right: 2.7rem;
    background-color: #4dae67;
    color: #fff;
}

.category-light:hover .category-name a {
    padding-right: 2.3rem;
}

.category-light:hover .category-name a::after {
    opacity: 1;
}

.category-icon {
    padding: 2.6rem 0.5rem;
    border: 1px solid #eee;
    color: #222;
    text-align: center;
    transition: border 0.3s;
    border-radius: 0.3rem;
}

.category-icon i {
    margin-bottom: 1.3rem;
    font-size: 4.2rem;
    color: #666;
    transition: transform 0.3s;
}

.category-icon .category-name {
    margin: 0;
    color: #222;
}

.category-icon:hover i {
    transform: translateY(-4px);
}

.category-icon:hover .category-content {
    background: transparent;
}

.category-icon-inline {
    display: inline-flex;
    align-items: center;
}

.category-icon-inline .category-media {
    font-size: 3.2rem;
    line-height: 1;
    color: #444;
    transition: color 0.3s;
}

.category-icon-inline .category-media i {
    margin-right: 0.8rem;
}

.category-icon-inline .category-media svg {
    display: block;
    margin-right: 1rem;
    width: 3.5rem;
    height: 3.9rem;
    stroke: #444;
    fill: #444;
    transition: stroke 0.3s;
}

.category-icon-inline .category-name {
    margin: 0;
    text-transform: none;
    text-align: left;
    font-size: 1.3rem;
    font-weight: inherit;
    font-family: inherit;
    line-height: 1.08;
    color: inherit;
    transition: color 0.3s;
}

.category-icon-inline:hover .category-media,
.category-icon-inline:hover .category-name {
    color: #4dae67;
}

.category-icon-inline:hover svg {
    stroke: #4dae67;
    fill: #4dae67;
}

.category-icon-inline:hover i,
.category-icon-inline:hover svg {
    animation: slideUpShorter 0.6s;
}

@keyframes slideUpShorter {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-4px);
    }

    100% {
        transform: translateY(0);
    }
}

.ellipse-section .owl-stage-outer {
    padding: 5rem;
    margin: -5rem;
}

.category-ellipse .category-media {
    border-radius: 50%;
    overflow: hidden;
    padding: 1.2rem;
    background-color: #fff;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
}

.category-ellipse img {
    transform: rotateZ(-10deg) translateX(-14px);
    transition: transform 0.3s;
}

.category-ellipse .category-content {
    padding-top: 2rem;
}

.category-ellipse .category-name {
    letter-spacing: -0.025em;
    color: #222;
}

.category-ellipse .category-name:hover {
    color: #4dae67;
}

.category-ellipse .category-count {
    text-transform: uppercase;
}

.category-ellipse:hover img {
    transform: rotateZ(0) translateX(0);
}

.cat-content-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #222;
    cursor: pointer;
    background-color: #eaf0f9;
}

.category-ellipse2 .category-media {
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
}

.category-ellipse2 .category-content {
    width: 100%;
    min-height: 25%;
    background-color: #fff;
}

.category-ellipse2 .category-name:hover a {
    color: #4dae67;
}

.dark-cta-section {
    background-color: #313438;
}

.category-classic .category-media {
    overflow: hidden;
    border-radius: 1.1rem;
}

.category-classic .category-content {
    right: 0;
    background-color: rgba(38, 38, 38, 0.808);
    color: #fff;
    border-radius: 0 0 1rem 1rem;
}

.category-classic .category-name {
    margin-bottom: 0;
    text-transform: uppercase;
}

.category-i-over figure::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #4dae67;
    opacity: 0.9;
    border-radius: 1rem;
}

.category-i-over figure i {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    top: 37.5%;
    transform: translate(-50%, -50%);
    width: 10rem;
    height: 10rem;
    margin-bottom: 0;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.15);
    box-shadow: 0 5px 15px 0 #4dae67;
    font-size: 4.2rem;
    z-index: 1;
    color: #fff;
}

.category-classic .category-content,
.category-i-over .category-content {
    left: 0;
    bottom: 0;
    width: 100%;
    min-height: 25%;
}

.category-classic .category-count,
.category-i-over .category-count {
    display: block;
    opacity: 0;
    line-height: 0;
    visibility: hidden;
    transition: opacity 0.3s, line-height 0.3s, visibility 0.3s, padding 0.3s;
}

.category-classic:hover .category-count,
.category-i-over:hover .category-count {
    visibility: visible;
    line-height: 1.2;
    opacity: 1;
    padding-top: 4px;
}

.category-center img {
    border-radius: 0;
}

.category-center .category-content {
    bottom: auto;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
}

.category-center .category-name {
    font-size: 2.6rem;
    color: #fff;
}

.category-center .owl-dots {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.category-group-image,
.category-group-icon {
    display: flex;
    align-items: center;
    border: 1px solid #eee;
    border-radius: 0.3rem;
}

.category-group-image>*,
.category-group-icon>* {
    flex: 1;
}

.category-group-image li:not(:last-child),
.category-group-icon li:not(:last-child) {
    margin-bottom: 0.9rem;
}

.category-group-image {
    color: #666;
}

.category-group-image .category-content {
    position: static;
    max-width: 50%;
    flex: 0 0 50%;
    padding: 2.3rem 1rem 2.3rem 0;
    background: transparent;
    text-align: left;
    align-items: flex-start;
}

.category-group-image .category-name {
    width: 100%;
    margin-bottom: 1.5rem;
    text-transform: none;
    font-size: 1.6rem;
    font-weight: 700;
    color: #222;
    letter-spacing: -0.01em;
}

.category-group-image a {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.category-group-image .category-list {
    font-size: 1.3rem;
    line-height: 1.2;
    width: 100%;
}

.category-group-image .category-list a {
    position: relative;
    display: inline-block;
    transition: -webkit-text-decoration 0.3s;
    transition: text-decoration 0.3s;
    transition: text-decoration 0.3s, -webkit-text-decoration 0.3s;
}

.category-group-image .category-list a:hover {
    color: #222;
    text-decoration: underline;
}

.category-group-image .category-list a:active {
    color: #4dae67;
}

.category-group-image:hover .category-name {
    margin-bottom: 1.5rem;
}

.category-group-image:hover .category-content {
    background: transparent;
}

.category-group-icon {
    display: flex;
    align-items: center;
    border: 0;
    color: #fff;
}

.category-group-icon>* {
    flex: 1;
}

.category-group-icon .category-media {
    text-align: center;
}

.category-group-icon .category-media i {
    display: inline-block;
    font-size: 6.8rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.8rem;
    line-height: 1;
    transition: transform 0.3s;
    color: #fff;
}

.category-group-icon>a {
    padding-right: 1rem;
}

.category-group-icon>a:hover i {
    transform: translateY(-0.5rem);
}

.category-group-icon .category-content {
    padding: 28px 0px 28px 10px;
    background: transparent;
    text-align: left;
    align-items: flex-start;
}

.category-group-icon .category-name {
    margin-bottom: 0;
    text-align: center;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    color: #fff;
}

.category-group-icon .category-list {
    font-size: 1.3rem;
    line-height: 1.2;
}

.category-group-icon .category-list li {
    white-space: nowrap;
}

.category-group-icon .category-list li::before {
    content: "";
    padding-right: 0.8rem;
    font-family: "Font Awesome 5 Free";
    font-size: 1.3rem;
    font-weight: 600;
    color: #fff;
}

.category-group-icon .category-list a {
    position: relative;
    display: inline-block;
    transition: -webkit-text-decoration 0.3s;
    transition: text-decoration 0.3s;
    transition: text-decoration 0.3s, -webkit-text-decoration 0.3s;
}

.category-group-icon .category-list a:hover {
    color: inherit;
    text-decoration: underline;
}

.category-group-icon:hover .category-name {
    margin-bottom: 0;
}

.category-group-icon:hover .category-content {
    background: transparent;
}

.category-banner {
    overflow: hidden;
    color: #222;
}

.category-banner.text-white .btn {
    color: #fff;
}

.category-banner .category-content {
    top: 4.5rem;
    left: 5rem;
    bottom: auto;
    width: auto;
    height: auto;
    align-items: flex-start;
    z-index: 1;
    background-color: transparent;
    transition: top 0.3s, padding 0.3s;
}

.category-banner .category-name {
    text-transform: none;
    text-align: left;
    font-size: 2rem;
    font-weight: 700;
    font-family: inherit;
    letter-spacing: -0.025em;
}

.category-banner .category-count {
    visibility: visible;
    opacity: 1;
    font-size: 1.4rem;
    line-height: 1.2;
    transition: color 0.3s;
    z-index: 1;
}

.category-banner .btn {
    top: auto;
    right: auto;
    transform: none;
}

.category-banner:hover .category-content {
    top: 3rem;
    padding-bottom: 3rem;
    background-color: transparent;
}

.category-banner:hover .btn {
    opacity: 1;
}

.category-badge .category-content {
    padding: 1.4rem 4rem;
    left: 2rem;
    right: auto;
    bottom: 2rem;
    background-color: #fff;
}

.category-badge .category-name {
    margin-bottom: 0;
    font-weight: 700;
    letter-spacing: 0.007em;
    color: #222;
}

.category-overlay .category-content {
    align-items: center;
    width: 100%;
}

.category-overlay .category-count {
    padding: 0;
    line-height: 0;
    opacity: 0;
    transform: translateY(-1rem);
    transition: transform 0.3s, opacity 0.3s, line-height 0.3s, padding 0.3s;
}

.category-overlay .category-name {
    margin: 0;
}

.category-overlay:hover .category-content {
    padding: 0;
    top: 50%;
}

.category-overlay:hover .category-count {
    padding-top: 1rem;
    transform: translateY(0);
    opacity: 1;
}

.category-block {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10.7rem;
    padding: 1.5rem;
    color: #fff;
    background-color: #333;
    transition: background-color 0.3s;
}

.category-block .category-name {
    margin: 0;
    text-align: center;
    font-weight: 400;
    line-height: 1.7rem;
    text-transform: none;
}

.category-block:hover {
    background-color: #4dae67;
}

.category-block:hover .category-name {
    color: #fff;
}

@media (max-width: 1300px) {
    .ellipse-section .owl-stage-outer {
        padding: 5rem 3rem;
        margin: 5rem -3rem;
    }
}

@media (max-width: 1199px) {
    .ellipse-section .owl-stage-outer {
        padding: 5rem 2rem;
        margin: -5rem -2rem;
    }
}

@media (max-width: 479px) {
    .ellipse-section .owl-stage-outer {
        padding: 5rem 1.5rem;
        margin: -5rem -1.5rem;
    }
}

/* -------------------------------------------
    Icon Box
---------------------------------------------- */
.icon-box .icon-box-title {
    margin: 0 0 0.7rem;
    text-transform: uppercase;
    font-size: 1.5rem;
    letter-spacing: -0.025em;
    line-height: 1.6;
}

.icon-box p {
    margin: 0;
    font-size: 1.4rem;
    line-height: 1.72;
    letter-spacing: 0;
    color: #000;
}

.icon-box i::before {
    margin: 0;
}

.icon-box .icon-box-content {
    text-align: center;
}

.icon-box-icon {
    display: inline-block;
    margin-bottom: 2.6rem;
    font-size: 3.4rem;
    font-weight: 600;
    line-height: 0;
    color: #222;
}

.icon-box-icon svg {
    height: 3.4rem;
    width: 4.8rem;
}

.icon-box-side {
    display: flex;
    align-items: center;
}

.icon-box-side .icon-box-content {
    text-align: left;
}

.icon-box-side .icon-box-icon {
    margin-right: 1.5rem;
    margin-bottom: 0;
}

.icon-box-side .icon-box-title {
    margin: 0;
}

.icon-border .icon-box-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 5.2rem;
    height: 5.2rem;
    border-radius: 50%;
    border: 1px solid #222;
    font-size: 2.5rem;
}

.icon-border svg {
    height: 2.4rem;
}

.icon-inversed .icon-box-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background-color: #ccc;
    color: #fff;
    font-size: 2.5rem;
}

.icon-inversed svg {
    height: 2.4rem;
    fill: white;
}

.icon-solid .icon-box-icon {
    font-size: 4.8rem;
    color: #4dae67;
}

.icon-solid svg {
    width: 5.4rem;
    height: 5.4rem;
    fill: #4dae67;
}

.icon-solid.icon-box-side {
    align-items: flex-start;
}

.icon-solid.icon-box-side svg {
    width: 4.8rem;
    height: 4.8rem;
}

.icon-box-tiny .icon-box-icon {
    margin: 0 1rem 0 0;
    font-size: 2.8rem;
}

.icon-box-tiny svg {
    height: 2.8rem;
    width: 2.8rem;
}

.icon-box-tiny .icon-box-title {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 991px) {
    .icon-box-side {
        flex-direction: column;
    }

    .icon-box-side .icon-box-icon {
        display: inline-block;
        margin: 0 0 2rem 0;
        padding: 2rem;
    }

    .icon-box-side .icon-box-content {
        text-align: center;
    }
}

/* -------------------------------------------
    @Component - Product Single
---------------------------------------------- */
.product-thumbs-wrap,
.product-single-carousel {
    flex-basis: 100%;
    max-width: 100%;
}

.product-single-carousel .owl-nav .owl-prev,
.product-single-carousel .owl-nav .owl-next {
    color: #222;
    border: 0;
}

.product-image {
    position: relative;
}

.product-thumbs-wrap {
    position: relative;
    margin-top: 1rem;
    overflow: hidden;
}

.product-thumbs-wrap img {
    display: block;
}

.product-thumbs-wrap button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.4rem;
    border: 0;
    transition: opacity 0.3s, transform 0.3s;
    z-index: 1;
    opacity: 0;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
    /* -webkit-appearance: none; */
    cursor: pointer;
}

.product-thumbs-wrap:hover button:not(.disabled) {
    opacity: 0.9;
    transform: none;
}

.product-thumbs-wrap button:not(.disabled):hover {
    opacity: 1;
}

.product-thumbs-wrap>button {
    display: none;
}

.product-thumbs-wrap button,
.product-thumbs .owl-nav .owl-prev,
.product-thumbs .owl-nav .owl-next {
    background-color: #fff;
    color: #666;
    font-size: 1.6rem;
}

.product-thumbs-wrap button,
.product-thumb::before {
    /* position: absolute; */
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.product-thumbs {
    transition: top 0.3s ease-out;
    display: flex;
}

.product-thumbs.owl-carousel {
    margin: 0 -0.5rem;
    width: calc(100% + 1rem);
}

.product-thumbs .owl-stage {
    display: flex;
}

.product-thumbs .owl-prev {
    transform: translateX(-100%);
    left: 0.5rem;
}

.product-thumbs .owl-next {
    left: auto;
    right: 0.5rem;
    transform: translateX(100%);
}

.product-thumb {
    position: relative;
    margin: 0 0.5rem;
    cursor: pointer;
    overflow: hidden;
}

.product-thumb::before {
    content: "";
    transition: border-color 0.3s;
    border: 2px solid transparent;
}

/* .product-thumb.active::before {
    border-color: #4dae67;
} */

.product-thumb .btn-play {
    position: relative;
    display: block;
    z-index: 2;
}

.product-thumb .btn-play:before {
    content: "";
    position: absolute;
    left: -100px;
    top: -100px;
    right: -100px;
    bottom: -100px;
    background: rgba(0, 0, 0, 0.1);
    transition: background 0.3s;
}

.product-thumb .btn-play:after {
    content: "";
    font-family: "Font Awesome 5 Free";
    font-size: 16px;
    font-weight: 800;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 36px;
    text-align: center;
    transition: box-shadow 0.3s;
}

.product-thumb .btn-play:hover:before {
    background: rgba(0, 0, 0, 0.2);
}

.product-thumb .btn-play:hover:after {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.2);
}

.product-thumb .btn-video:after {
    content: "";
}

.product-single {
    color: inherit;
}

.product-single .product-details {
    padding: 10px !important;
    background-color: transparent;
}

.container-fluid .product-single .product-details {
    padding-top: 4px;
}

aside+div .product-single .product-details {
    padding-top: 2px;
}

.product-single .product-cat {
    margin-bottom: 1.3rem;
    color: #999;
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: -0.025em;
}

.product-single .product-cat span {
    margin-right: 0.7rem;
}

.product-single .product-name {
    margin-bottom: 0.6rem;
    font-size: 2.6rem;
    font-weight: 700;
    letter-spacing: -0.025em;
    white-space: normal;
    color: #222;
}

.product-single .product-price {
    display: block;
    margin-bottom: 1.1rem;
    color: #fd990b;
    font-size: 3rem;
    font-weight: 700;
    letter-spacing: -0.025em;
    line-height: 1;
}

.product-single .old-price {
    font-size: 2.4rem;
    font-weight: 600;
}

.product-single .rating-reviews {
    font-size: 1.2rem;
}

.product-single .rating-reviews:not(:hover) {
    color: #999;
}

.product-single .ratings-container {
    margin-bottom: 1.7rem;
    font-size: 16px;
}

.product-single .ratings-container .ratings:before {
    color: #666;
}

.product-single .ratings-full {
    margin-top: -2px;
}

.product-single label {
    color: #222;
    font-weight: 600;
    text-transform: uppercase;
}

.product-single .product-action {
    display: inline-block;
}

.product-single .product-action .btn-wishlist {
    position: relative;
}

.product-single .divider {
    margin-left: -1rem;
    margin-right: 1.8rem;
}

.product-single .social-links {
    color: #999;
}

.product-single .social-link {
    border: none;
}

.product-single .social-link.social-facebook:hover {
    background-color: transparent;
    color: #3b5998;
}

.product-single .social-link.social-twitter:hover {
    background-color: transparent;
    color: #1da1f2;
}

.product-single .social-link.social-pinterest:hover {
    background-color: transparent;
    color: #bd081c;
}

.product-single .product-footer>* {
    margin-bottom: 1rem;
}

.product-single .btn-wishlist,
.product-single .btn-compare {
    display: inline-block;
    padding: 0.5rem 0;
    font-weight: 400;
    font-size: 1.4rem;
    background-color: transparent;
    transition: color 0.3s;
    text-transform: none;
    color: #666;
}

.product-single .btn-wishlist i,
.product-single .btn-compare i {
    display: inline-block;
    margin: 0 0.5rem 0.3rem 0;
    vertical-align: middle;
    font-size: 1.8rem;
    line-height: 0;
}

.product-single .btn-wishlist:hover,
.product-single .btn-compare:hover {
    color: #4dae67;
}

.product-single .btn-compare i {
    margin-right: 0.8rem;
    font-size: 2.1rem;
}

.product-single .quantity {
    font-weight: 700;
}

.product-single .product-meta+.product-countdown-container {
    margin-top: 0.4rem;
}

.product-single .product-countdown-container {
    display: inline-flex;
    align-items: center;
    margin-bottom: 2.5rem;
    padding: 1rem 1.6rem;
    border: 1px solid #444;
    background-color: #444;
    text-transform: none;
    line-height: 1;
    color: #fff;
    border-radius: 0.3rem;
}

.product-single .product-countdown-container label {
    margin-right: 0.5rem;
    text-transform: none;
    color: #fff;
}

.product-single .product-countdown-container .product-sale-info {
    position: relative;
    padding-right: 1.5rem;
}

.product-single .product-countdown-container .product-sale-info i {
    margin-right: 0.8rem;
}

.product-single .product-countdown-container .product-sale-info::after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 2.4rem;
    background: rgba(255, 255, 255, 0.2);
}

.product-single .product-countdown-container .product-sale-info+label {
    margin-left: 1.5rem;
}

.product-single .product-label-group {
    top: 2rem;
    left: 2.5rem;
    z-index: 2;
    align-items: flex-start;
}

.inner-product-label-group {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 1rem;
    top: 0rem;
    max-width: 8rem;
}

.inner-product-label-group {
    top: 1rem;
    /*left: 0rem;*/
    z-index: 2;
    align-items: flex-start;
}

.inner-product-label-group2 {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 1rem;
    top: 0rem;
    max-width: 8rem;
}

.inner-product-label-group2 label {
    color: #fff !important;
    background: #de324a;
    margin: 0px !important;
    line-height: 15px !important;
    padding: 6px !important;
    font-size: 10px;
}

.inner-product-label-group2 {
    top: 5rem;
    /*left: 0rem;*/
    z-index: 2;
    align-items: flex-start;
}

.inner-product-label.label-sale {
    background: #000;
    color: #fff !important;
}

.inner-product-label {
    display: inline-block;
    text-align: center;
    margin-bottom: 0.5rem;
    padding: 0.5rem 1.1rem;
    font-size: 12px;
    border-radius: 0px 20px 0px 20px;
    font-weight: 600;
    line-height: 20px;
    color: #fff;
    text-transform: uppercase;
}

.product-single .product-label {
    color: #fff;
}

.product-single .product-variations>a:not(.size-guide) {
    width: 3rem;
    height: 3rem;
}

.product-single .color {
    border: 0;
}

.product-single .size {
    width: 3.6rem;
}

.product-single .accordion ul {
    list-style: none;
    padding-left: 0;
}

.product-single .rating-form label,
.product-single form label {
    font-weight: 400;
    font-size: 1.3rem;
    text-transform: none;
    color: #666;
}

.dark-theme .ratings-container .ratings:before {
    color: #4dae67;
}

.dark-theme .social-links {
    color: #666;
}

.dark-theme .btn-wishlist,
.dark-theme .btn-compare {
    color: #999;
}

.dark-theme .btn-wishlist.added,
.dark-theme .btn-compare.added {
    color: #fff;
}

.dark-theme .product-variation-clean:not(:hover) {
    color: #fff;
}

.dark-theme .product-form.btn-cart:disabled,
.dark-theme .product-form.btn-external:disabled {
    background-color: #666;
    color: #aaa;
}

.product-meta {
    margin-bottom: 1.8rem;
    color: #999;
    font-size: 1.2rem;
}

.product-meta span {
    margin: 0 1.9rem 0 3px;
}

.product-form {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    line-height: 3rem;
    margin: 0 0 1rem;
}

.product-form>label {
    min-width: 7rem;
    margin-bottom: 1rem;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.025em;
}

.product-form.product-variations {
    line-height: 37px;
}

.product-form .select-box::before {
    right: 1.4rem;
    font-size: 1.2rem;
    color: #222;
}

.product-form select {
    max-width: none;
    padding: 0.8rem 3rem 0.8rem 1.4rem;
    color: #222;
    border-color: #ebebeb;
    font-size: 1.3rem;
}

.product-form .product-variations {
    display: block;
    margin-top: -3px;
    margin-bottom: -3px;
}

.product-form.product-qty {
    line-height: 4.5rem;
}

.product-form .quantity-minus {
    border-radius: 0.3rem 0 0 0.3rem;
}

.product-form .quantity-plus {
    border-radius: 0 0.3rem 0.3rem 0;
}

.product-form .input-group {
    margin-right: 1rem;
}

.product-form .btn-cart,
.product-form .btn-external {
    border: 0;
    flex: 1;
    min-width: 13rem;
    font-size: 1.4rem;
    border-radius: 0.3rem;
    background: var(--base-color);
    transition: background-color 0.3s;
    color: var(--black);
    font-weight: 100;
    cursor: pointer;
    max-width: 20.7rem;
    height: 4.5rem;
}

.product-form .btn-cart:disabled,
.product-form .btn-external:disabled {
    background-color: #e4eaec;
    cursor: not-allowed;
    color: #999;
}

.product-form .btn-cart i,
.product-form .btn-external i {
    margin-right: 0.8rem;
    margin-top: -1px;
    font-size: 1.8rem;
    line-height: 0;
    vertical-align: middle;
}

.product-form .btn-cart i::before,
.product-form .btn-external i::before {
    margin: 0;
}

.product-form .btn-cart:hover:not(:disabled),
.product-form .btn-external:hover:not(:disabled) {
    background-color: #429558;
}

.product-external .product-short-desc {
    color: #ee8204;
}

.product-external .product-form button {
    background: #ee8204;
}

.stock {
    display: inline-block;
    padding: 4px 2rem;
    margin-bottom: 2rem;
    border-radius: 2px;
    color: #fff;
    font-weight: 600;
}

.in-stock {
    background: #fd990b;
}

.out-of-stock {
    background: #ccc;
}

.product-single .product-label.label-stock {
    white-space: nowrap;
    background: #ccc;
}

.hurryup-bar>p {
    margin-bottom: 0.5rem;
    color: #222;
    background: transparent;
    padding: 0;
}

.hurryup-bar .bar {
    position: relative;
    display: block;
    width: 100%;
    max-width: 38rem;
    height: 14px;
    background: #eee;
    border-radius: 10px;
}

.hurryup-bar .stock-bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: block;
    background: #fd990b;
    border-radius: 10px;
}

.product-form-group {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex: 1;
}

.product-form-group>* {
    margin-bottom: 1rem;
}

.product-form-group .product-variations {
    margin-bottom: 7px;
}

.product-form-group tr {
    border-bottom: 1px solid #eee;
}

.product-form-group td {
    padding: 2rem 0;
}

.product-form-group .item-name {
    padding-left: 2rem;
    color: #222;
    line-height: 1.4;
}

.product-form-group .item-price {
    font-weight: 600;
    color: #222;
    padding-left: 4.5rem;
}

.size-guide {
    display: inline-flex;
    align-items: center;
    font-weight: 300;
}

.size-guide i {
    margin-right: 0.8rem;
    font-size: 2.1rem;
    color: #666;
}

.select-box+.size-guide {
    margin-left: 10px;
}

.product-variation-price {
    display: none;
    padding-top: 25px;
}

.product-variation-price span {
    margin-bottom: 1rem;
    color: #222;
    font-size: 2.4rem;
    font-weight: 700;
    letter-spacing: -0.025em;
}

.product-variation-clean {
    display: block;
    position: absolute;
    margin-top: 1rem;
    padding: 0.3em 1em;
    left: 0;
    top: calc(100% - 10px);
    font-size: 1rem;
    line-height: 1.6;
    background: #f4f4f4;
    color: #000;
}

.related-products {
    margin-top: 6.5rem;
}

@media (min-width: 992px) {
    .pg-vertical .product-thumbs-wrap {
        order: -1;
        max-width: 109px;
        margin: 0 1rem 0 0;
    }

    .pg-vertical .product-single-carousel {
        max-width: calc(100% - 119px);
    }

    .pg-vertical .product-thumbs {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .pg-vertical .product-thumb {
        margin: 0 0 1rem;
    }

    .pg-vertical .thumb-up,
    .pg-vertical .thumb-down {
        display: flex;
        width: 100%;
        height: 2.4rem;
    }

    .pg-vertical .thumb-up {
        transform: translateY(-100%);
    }

    .pg-vertical .thumb-up i::before {
        content: "";
    }

    .pg-vertical .thumb-down {
        top: auto;
        transform: translateY(100%);
    }

    .pg-vertical .thumb-down i::before {
        content: "";
    }

    .pg-vertical .product-label-group {
        left: 14rem;
    }
}

.product-single.product-sticky-both .p-sticky {
    top: 88px;
}

.product-single.product-sticky-both .product-details {
    padding: 0;
}

.product-tabs.tab-nav-simple .nav-link {
    font-size: 2rem;
    font-weight: 700;
    text-transform: capitalize;
    color: #222;
    letter-spacing: 0;
}

/* -------------------------------------------
    Mini Popup
---------------------------------------------- */
.minipopup-area {
    position: fixed;
    left: 30px;
    bottom: 30px;
    z-index: 9999;
    display: none;
}

.dark-theme .minipopup-box {
    background-color: #222;
}

.dark-theme .minipopup-box .product.product-cart .product-name,
.dark-theme .minipopup-box .product.product-cart .product-quantity,
.dark-theme .minipopup-box .product.product-compare .product-name,
.dark-theme .minipopup-box .product.product-compare .product-quantity,
.dark-theme .minipopup-box .price-box>.product-price {
    color: #999;
}

.dark-theme .minipopup-box .minipopup-title {
    color: #fff;
}

.minipopup-box {
    position: absolute;
    left: -8rem;
    width: 303px;
    padding: 2rem;
    background-color: #fff;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    transform: translateY(-100%);
    opacity: 0;
    transition: opacity 0.3s, left 0.3s ease-out, transform 0.3s;
    z-index: 9999;
}

.minipopup-box.show {
    opacity: 1;
    left: 0;
}

.minipopup-box.focus {
    transform: scale(0.98) translateY(-100%);
}

.minipopup-box .product.product-cart,
.minipopup-box .product.product-compare {
    margin-top: 0;
}

.minipopup-box .product.product-cart .product-name,
.minipopup-box .product.product-compare .product-name {
    display: block;
    font-size: 1.4rem;
    margin-bottom: 3px;
    color: #000;
    font-weight: 400;
}

.minipopup-box .product.product-cart,
.minipopup-box .product.product-compare {
    margin-top: 0;
}

.minipopup-box .product.product-cart .product-media,
.minipopup-box .product.product-compare .product-media {
    margin-right: 1.7rem;
    height: 9rem;
}

.minipopup-box .product.product-cart .product-detail,
.minipopup-box .product.product-compare .product-detail {
    margin-top: 0.3rem;
}

.minipopup-box .product.product-cart .product-name,
.minipopup-box .product.product-compare .product-name {
    display: block;
    font-size: 1.4rem;
    margin-bottom: 1.1rem;
    color: #000;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: -0.025em;
}

.minipopup-box .product.product-cart .product-name:hover,
.minipopup-box .product.product-compare .product-name:hover {
    color: #4dae67;
}

.minipopup-box .product.product-cart .product-price,
.minipopup-box .product.product-compare .product-price {
    margin-bottom: -0.2rem;
    font-size: 1.3rem;
}

.minipopup-box .product.product-cart .product-quantity,
.minipopup-box .product.product-compare .product-quantity {
    margin-right: 0.7rem;
    font-size: 1.4rem;
    font-weight: 600;
    color: #222;
}

.minipopup-box .product.product-cart .product-quantity::after,
.minipopup-box .product.product-compare .product-quantity::after {
    margin: 0 0 -0.2rem 0.7rem;
    font-size: 1.2rem;
}

.minipopup-box .product.product-cart.product-purchased .purchased-title,
.minipopup-box .product.product-compare.product-purchased .purchased-title {
    margin-bottom: 1.9rem;
}

.minipopup-box .product.product-cart.product-purchased .product-detail,
.minipopup-box .product.product-compare.product-purchased .product-detail {
    margin-top: 0.1rem;
}

.minipopup-box .product.product-cart.product-purchased .product-name,
.minipopup-box .product.product-compare.product-purchased .product-name {
    margin-bottom: 1.2rem;
}

.minipopup-box img {
    max-width: 9rem;
    max-height: 9rem;
}

.minipopup-box .ratings-container {
    margin-bottom: 0;
}

.minipopup-box .product-price {
    color: #4dae67;
    margin-bottom: 3px;
}

.minipopup-box .action-group {
    justify-content: space-around;
    margin-top: 2rem;
}

.minipopup-box .btn.btn-sm {
    padding: 0.92em 2.07em;
    font-weight: 600;
    letter-spacing: -0.025em;
    border-width: 1px;
}

.minipopup-box .btn.btn-outline {
    margin-right: 0.7rem;
    padding: 0.92em 2.2em;
}

.minipopup-title {
    margin-bottom: 1.5rem;
    color: #222;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    letter-spacing: -0.035em;
    line-height: 1;
}

.minipopup-title .btn {
    margin-left: 1rem;
}

.purchased-time {
    color: #4dae67;
    font-size: 1.2rem;
}

@media (max-width: 479px) {
    .minipopup-area {
        left: 9px;
    }
}

/* -------------------------------------------
    Overlay
     - Global
     - Light
     - Dark
     - Zoom
     - Effect1
     - Effect2
     - Effect3
     - Effect4
     - Blur
     - Brightness
     - Contrast
     - Grayscale
     - Hue Rotate
     - Opacity
     - Saturate
     - Sepia
     - Particle Overlay Effect
     - Snowfall
     - Sparkle
---------------------------------------------- */
.overlay,
.overlay-visible {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    color: #fff;
    transition: padding 0.3s ease-out, background-color 0.3s, opacity 0.3s;
}

.overlay {
    padding-top: 10rem;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;
}

a>.overlay {
    cursor: pointer;
}

figure:hover .overlay {
    padding-top: 0;
    opacity: 1;
}

.overlay.social-links {
    flex-direction: row;
}

.overlay .social-links {
    justify-content: center;
}

.overlay a:hover {
    color: #fff;
    text-decoration: underline;
}

.overlay a.social-link {
    border-color: #fff;
}

.overlay .social-link:hover {
    color: #fff;
    text-decoration: none;
}

figure:hover .overlay-visible {
    background: rgba(126, 164, 177, 0.9);
    padding-bottom: 15rem;
}

.overlay-transparent {
    background: transparent;
}

.overlay-dark,
.overlay-light,
.overlay-effect1,
.overlay-effect2,
.overlay-effect3,
.overlay-effect4 {
    overflow: hidden;
}

.overlay-dark figure,
.overlay-light figure,
.overlay-effect1 figure,
.overlay-effect2 figure,
.overlay-effect3 figure,
.overlay-effect4 figure {
    overflow: hidden;
}

.overlay-dark figure,
.overlay-dark .banner,
.overlay-light figure,
.overlay-light .banner,
.overlay-effect1 figure,
.overlay-effect1 .banner,
.overlay-effect2 figure,
.overlay-effect2 .banner,
.overlay-effect3 figure,
.overlay-effect3 .banner,
.overlay-effect4 figure,
.overlay-effect4 .banner {
    position: relative;
}

.overlay-dark figure::after,
.overlay-dark figure>a::after,
.overlay-dark.banner::after,
.overlay-light figure::after,
.overlay-light figure>a::after,
.overlay-light.banner::after,
.overlay-effect1 figure::after,
.overlay-effect1 figure>a::after,
.overlay-effect1.banner::after,
.overlay-effect2 figure::after,
.overlay-effect2 figure>a::after,
.overlay-effect2.banner::after,
.overlay-effect3 figure::after,
.overlay-effect3 figure>a::after,
.overlay-effect3.banner::after,
.overlay-effect4 figure::after,
.overlay-effect4 figure>a::after,
.overlay-effect4.banner::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #222;
    opacity: 0;
    transition: opacity 0.3s, background 0.3s, transform 0.3s;
}

.overlay-dark:hover figure::after,
.overlay-dark:hover figure>a::after,
.overlay-dark.banner:hover::after,
.overlay-light:hover figure::after,
.overlay-light:hover figure>a::after,
.overlay-light.banner:hover::after,
.overlay-effect1:hover figure::after,
.overlay-effect1:hover figure>a::after,
.overlay-effect1.banner:hover::after,
.overlay-effect2:hover figure::after,
.overlay-effect2:hover figure>a::after,
.overlay-effect2.banner:hover::after,
.overlay-effect3:hover figure::after,
.overlay-effect3:hover figure>a::after,
.overlay-effect3.banner:hover::after,
.overlay-effect4:hover figure::after,
.overlay-effect4:hover figure>a::after,
.overlay-effect4.banner:hover::after {
    opacity: 0.2;
}

.overlay-dark.banner-fixed::after,
.overlay-dark.post>figure::after,
.overlay-light.banner-fixed::after,
.overlay-light.post>figure::after,
.overlay-effect1.banner-fixed::after,
.overlay-effect1.post>figure::after,
.overlay-effect2.banner-fixed::after,
.overlay-effect2.post>figure::after,
.overlay-effect3.banner-fixed::after,
.overlay-effect3.post>figure::after,
.overlay-effect4.banner-fixed::after,
.overlay-effect4.post>figure::after {
    content: none;
}

.overlay-dark.post>figure>a::after,
.overlay-light.post>figure>a::after,
.overlay-effect1.post>figure>a::after,
.overlay-effect2.post>figure>a::after,
.overlay-effect3.post>figure>a::after,
.overlay-effect4.post>figure>a::after {
    z-index: 1;
}

.overlay-effect2 figure::before,
.overlay-effect2 figure>a::before,
.overlay-effect2.banner::before,
.overlay-effect4 figure::before,
.overlay-effect4 figure>a::before,
.overlay-effect4.banner::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    opacity: 0.2;
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.overlay-effect2.banner-fixed::before,
.overlay-effect2.post>figure::before,
.overlay-effect4.banner-fixed::before,
.overlay-effect4.post>figure::before {
    content: none;
}

.overlay-light figure>a::after,
.banner.overlay-light figure::after {
    background: #ccc;
}

.overlay-dark figure>a::after,
.banner.overlay-dark figure::after {
    background: #222;
}

.overlay-zoom {
    overflow: hidden;
}

.overlay-zoom figure {
    overflow: hidden;
}

.overlay-zoom img {
    transition: transform 0.3s;
}

.overlay-zoom:hover img {
    transform: scale(1.08);
}

.overlay-effect1:hover figure::after,
.overlay-effect1:hover figure>a::after,
.overlay-effect1:hover::after {
    transform: scale(1, 1);
    visibility: visible;
    opacity: 0;
    transition: transform 0.5s linear, opacity 0.5s linear;
}

.overlay-effect1 figure>a::after,
.overlay-effect1 figure::after {
    transform: scale(0, 1);
    opacity: 0.4;
    background-color: #fff;
    visibility: hidden;
}

.overlay-effect2:hover figure::after,
.overlay-effect2:hover figure>a::after,
.overlay-effect2:hover::after,
.overlay-effect2:hover figure::before,
.overlay-effect2:hover figure>a::before,
.overlay-effect2:hover::before {
    opacity: 0;
    transform: none;
}

.overlay-effect2 figure>a::after,
.overlay-effect2 figure::after {
    transform: translateX(-100%);
    background-color: #fff;
    opacity: 0.2;
    transition: transform 0.9s ease, opacity 0.9s ease;
}

.overlay-effect2 figure>a::before,
.overlay-effect2 figure::before {
    transform: translateX(100%);
    transition: transform 0.9s ease, opacity 0.9s ease;
}

.overlay-effect3:hover figure::after,
.overlay-effect3:hover figure>a::after,
.overlay-effect3:hover::after {
    top: 100%;
    left: -100%;
    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 200%, 0);
}

.overlay-effect3 figure>a::after,
.overlay-effect3 figure::after {
    left: 50%;
    width: 120%;
    height: 60px;
    background-color: #fff;
    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -150%, 0);
    transition: 0.5s linear, top 0.5s linear, left 0.5s linear;
}

.overlay-effect4:hover figure::after,
.overlay-effect4:hover figure>a::after,
.overlay-effect4:hover::after,
.overlay-effect4:hover figure::before,
.overlay-effect4:hover figure>a::before,
.overlay-effect4:hover::before {
    transform: scale(1, 1);
}

.overlay-effect4 figure>a::after,
.overlay-effect4 figure::after {
    transform: scale(0, 1);
    background-color: #fff;
}

.overlay-effect4 figure>a::before,
.overlay-effect4 figure::before {
    transform: scale(1, 0);
    transition: transform 0.3s;
}

.overlay-filter img {
    transition: filter 0.4s;
}

.overlay-blur:hover img {
    filter: blur(4px);
}

.overlay-brightness:hover img {
    filter: brightness(1.5);
}

.overlay-contrast:hover img {
    filter: contrast(1.5);
}

.overlay-grayscale:hover img {
    filter: grayscale(1);
}

.overlay-hue:hover img {
    filter: hue-rotate(270deg);
}

.overlay-opacity:hover img {
    filter: opacity(0.5);
}

.overlay-saturate:hover img {
    filter: saturate(3);
}

.overlay-sepia:hover img {
    filter: sepia(0.5);
}

.particle-effect {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.snowfall {
    background-image: url(../images/new-images/effects/s1.png), url(../images/new-images/effects/s2.png), url(../images/new-images/effects/s3.png);
    animation: snow 10s linear infinite;
    z-index: 1;
}

@keyframes snow {
    0% {
        background-position: 0px 0px, 0px 0px, 0px 0px;
    }

    50% {
        background-position: 500px 500px, 100px 300px, -200px 250px;
    }

    100% {
        background-position: 400px 800px, 300px 600px, -200px 400px;
    }
}

.sparkle {
    background-image: url(../images/new-images/effects/sparkle1.png), url(../images/new-images/effects/sparkle2.png);
    animation: sparkle 60s linear infinite;
}

@keyframes sparkle {
    0% {
        background-position: 0px 0px, 0px 0px, 0px 0px;
    }

    100% {
        background-position: -500px -1000px, -400px -400px, 300px 300px;
    }
}

@keyframes kenBurnsToRight {
    0% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes kenBurnsToLeft {
    0% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.kenBurnsToRight {
    animation-name: kenBurnsToRight;
    animation-timing-function: linear;
    animation-fill-mode: both;
    transform-origin: right;
}

.kenBurnsToLeft {
    animation-name: kenBurnsToLeft;
    animation-timing-function: linear;
    animation-fill-mode: both;
    transform-origin: left;
}

.kenBurnsToLeftTop {
    animation-name: kenBurnsToLeft;
    animation-timing-function: linear;
    animation-fill-mode: both;
    transform-origin: left top;
}

.kenBurnsToRightTop {
    animation-name: kenBurnsToRight;
    animation-timing-function: linear;
    animation-fill-mode: both;
    transform-origin: right top;
}

/* -------------------------------------------
  Sidebars
    - Base
    - Sidebar Elements
    - Right Sidebar
    - Fixed Sidebar
    - Tag
------------------------------------------- */
.page-content.with-sidebar {
    padding-bottom: 10rem;
    overflow: hidden;
}

.sidebar-active .sidebar .sidebar-overlay,
.sidebar-active .sidebar .sidebar-close,
.top-sidebar-active .top-sidebar .sidebar-overlay,
.top-sidebar-active .top-sidebar .sidebar-close,
.right-sidebar-active .right-sidebar .sidebar-overlay,
.right-sidebar-active .right-sidebar .sidebar-close {
    visibility: visible;
    opacity: 1;
}

.sidebar-active .sidebar .sidebar-content,
.top-sidebar-active .top-sidebar .sidebar-content,
.right-sidebar-active .right-sidebar .sidebar-content {
    transform: translateX(0);
    opacity: 1;
}

.top-sidebar-active .category-sidebar {
    display: none;
}

.sidebar-active .page-wrapper {
    margin-left: 250px;
    margin-right: -250px;
}

.right-sidebar-active .page-wrapper {
    margin-left: -250px;
    margin-right: 250px;
}

.sidebar-overlay,
.sidebar-toggle,
.sidebar-content {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1200;
}

.sidebar-overlay {
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.4s, opacity 0.4s;
}

.sidebar-close {
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.4s, opacity 0.4s;
}

.sidebar-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 160px;
    width: 40px;
    height: 40px;
    font-size: 1.4rem;
    color: #fff;
    background-color: rgba(28, 26, 26, 0.8);
}

.sidebar-close {
    position: fixed;
    margin: 0;
    left: calc(100vw - 52px);
    top: 12px;
    font-size: 3.5rem;
    color: #ccc;
    z-index: 1201;
}

.sidebar-close:hover {
    color: #ccc;
}

.sidebar-content {
    bottom: 0;
    width: 30rem;
    padding: 2rem;
    transform: translateX(-100%);
    overflow: auto;
    background-color: #fff;
    opacity: 0;
    line-height: 1.3;
    transition: transform 0.4s, opacity 0.4s;
}

.sidebar-content .widget {
    border-top: 3px solid #eee;
}

.sidebar-content .widget-body {
    margin-bottom: 1.8rem;
    opacity: 1;
    transition: opacity 0.3s;
}

.sidebar-content .sidebar-close {
    display: flex;
    position: static;
    align-items: center;
    margin-bottom: 2rem;
    text-transform: uppercase;
    letter-spacing: -0.025em;
    line-height: 1;
}

.sidebar-content .d-icon-times {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.6rem;
}

.sidebar-content .widget.price-with-count .filter-items>li {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sidebar-content .btn-filter {
    padding: 0.86em 2em;
    border-radius: 2px;
}

.sidebar-content .btn-filter:hover,
.sidebar-content .btn-filter:focus,
.sidebar-content .btn-filter:active {
    background-color: #4dae67;
    border-color: #4dae67;
}

.right-sidebar {
    order: 2;
}

.right-sidebar .sidebar-toggle {
    left: auto;
    right: 0;
}

.right-sidebar .sidebar-close {
    left: 50px;
}

.right-sidebar .sidebar-content {
    transform: translateX(100%);
    left: auto;
    right: 0;
}

@media (max-width: 991px) {
    .right-sidebar .sidebar-close {
        left: 20px;
    }
}

@media (min-width: 992px) {

    .sidebar-fixed .sidebar-toggle,
    .sidebar-fixed .sidebar-overlay,
    .sidebar-fixed .sidebar-close {
        display: none;
    }

    .sidebar-fixed .sidebar-content {
        position: relative;
        overflow: visible;
        padding: 0;
        opacity: 1;
        z-index: 22;
    }

    .sidebar-fixed .sidebar-content {
        width: auto;
        transform: none;
    }
}

.right-sidebar .sidebar-content::-webkit-scrollbar {
    width: 0;
}

.tag {
    display: inline-block;
    padding: 0.6rem 1.3rem;
    margin: 0.5rem 1rem 0.5rem 0;
    border: 1px solid #ccc;
    font-size: 1.2rem;
    line-height: 1.35;
    letter-spacing: -0.01em;
    transition: color 0.3s, border-color 0.3s;
}

.tag:hover {
    color: #4dae67;
    border-color: #4dae67;
}

.sidebar-content .form-control {
    border-color: #ccc;
    border-radius: 0.3rem;
}

.widget-collapsible>.widget-title.collapsed {
    padding-bottom: 2.7rem;
}

.widget-collapsible>.widget-title.collapsed .widget-collapsible>.widget-title {
    padding-bottom: 0;
}

.widget-collapsible .toggle-btn {
    display: block;
    position: absolute;
    top: 36px;
    right: 12px;
    padding: 0;
    width: 10px;
    opacity: 1;
}

.widget-collapsible .toggle-btn::before,
.widget-collapsible .toggle-btn::after {
    content: "";
    position: absolute;
    border-top: 2px solid #666;
    width: 10px;
    transition: transform 0.3s;
}

.widget-collapsible>.collapsed .toggle-btn::before {
    transform: rotate(90deg);
}

.widget-collapsible>.collapsed .toggle-btn::after {
    transform: rotate(180deg);
}

.widget-collapsible .collapsed~.widget-body {
    opacity: 0.1;
}

.widget-collapsible p {
    margin-bottom: 0;
    color: #aaa;
    line-height: 1.86;
}

.widget-collapsible .widget-title,
.toolbox-wrap .widget-title {
    position: relative;
    cursor: pointer;
    margin: 0;
    padding: 2.6rem 0.3rem 1.8rem;
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: -0.03em;
    text-transform: none;
    border: none;
    transition: padding 0.3s;
}

.filter-items.search-ul li {
    padding: 13.5px 3px;
}

.filter-items li {
    padding: 13px 3px 13px 30px;
    font-size: 1.3rem;
    color: #222;
}

.filter-items>li:not(:last-child) {
    border-bottom: 1px solid #eee;
}

.filter-items a {
    position: relative;
    display: block;
}

.filter-items a:hover {
    color: #4dae67;
}

.filter-items .active>a::before {
    content: "";
    color: #fff;
    background-color: #222;
    border-color: #222;
}

.filter-items.search-ul a::before {
    content: none;
}

.filter-items .children li {
    padding: 5.5px 0;
}

.filter-items li span {
    margin-left: 0.5rem;
    color: #aaa;
}

.filter-items .with-ul>a>i {
    content: "";
    position: absolute;
    top: 50%;
    right: 0.7rem;
    line-height: 0;
    margin: -1.3rem -3px 0 0;
    padding: 1.2rem 5px;
    font-size: 1.2rem;
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    transition: transform 0.3s;
}

.filter-items .show>a {
    color: #4dae67;
}

.filter-items .show>a i {
    transform: rotate(-180deg);
}

.filter-items ul {
    display: none;
    position: relative;
    margin: 1rem 0 -0.5rem;
    padding-left: 1.3rem;
}

.filter-items ul::before {
    content: "";
    position: absolute;
    left: 2px;
    top: 4px;
    bottom: 3px;
    border-left: 1px solid #eee;
}

.filter-items .color {
    top: 50%;
    display: inline-block;
    margin-right: 0.8rem;
    margin-top: -4.5px;
    width: 0.9rem;
    height: 0.9rem;
    border-radius: 50%;
}

.widget-vendor-info li span {
    display: inline-block;
    margin-left: 0;
}

.widget-vendor-info li span:first-child {
    min-width: 10.5rem;
    padding-right: 1rem;
    color: #333;
}

.widget-vendor-info li span.ratings-container {
    margin-bottom: 0;
    padding-right: 0;
    font-size: 1.3rem;
}

.widget-vendor-info .seller-name .details {
    color: #222;
}

.widget-vendor-info .filter-items li {
    display: flex;
    padding: 14px 3px 13px 0px;
}

.widget-contact-vendor .form-control {
    min-height: 4.1rem;
    padding: 1.2rem 2rem;
    border: 1px solid #e1e1e1;
    line-height: 1.5;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 300;
    color: #999;
    transition: color 0.3s, border-color 0.3s;
}

.widget-contact-vendor .form-control:focus {
    border-color: #222;
}

.widget-contact-vendor .form-control:not(:last-child) {
    margin-bottom: 1rem;
}

.widget-contact-vendor .btn {
    margin: 0.8rem 0 1.2rem;
    padding: 0.8em 1.65em;
    letter-spacing: -0.025em;
}

/* -------------------------------------------
    Instagram
---------------------------------------------- */
.instagram {
    position: relative;
    overflow: hidden;
    border-radius: 0.3rem;
}

.instagram a {
    display: block;
    position: relative;
    height: 100%;
}

.instagram a::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 35px;
    bottom: 48px;
    background: #e7f2f9;
    opacity: 1;
    transition: opacity 0.3s;
    z-index: -1;
    border-radius: 10px;
}

.instagram a::after {
    /* content: ""; */
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(90deg) scale(2);
    font-size: 3rem;
    font-family: "riode";
    color: #fff;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    z-index: 1;
}

.instagram img {
    /* display: block;
    transition: transform 1s;
    width: 100%;
    height: auto; */
}

figure.instagram img:hover {
    transform: translateY(-2.1rem);
    transition: transform 1s;
}

.instagram:hover a::before {
    opacity: 0.5;
}

.instagram:hover a::after {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(0deg) scale(1);
}

/* .instagram:hover img {
  transform: scale(1.15);
} */

.instagram-info .instagram-content {
    position: absolute;
    left: 3rem;
    bottom: 3rem;
    font-size: 1.4rem;
    letter-spacing: 0;
    color: #fff;
    opacity: 0;
    z-index: 2;
    transition: opacity 0.3s;
}

.instagram-info .instagram-content i {
    display: inline-block;
    margin-right: 0.8rem;
    vertical-align: middle;
    font-size: 1.6rem;
}

.instagram-info .instagram-content .d-icon-comments::before {
    font-size: 1em;
}

.instagram-info .instagram-content a {
    display: inline-block;
    color: #fff;
}

.instagram-info .instagram-content a:not(:last-child) {
    margin-right: 2rem;
}

.instagram-info .instagram-content a::before {
    content: none;
}

.instagram-info:hover .instagram-content {
    opacity: 1;
}

/* 6. header */
/* -------------------------------------------
    Header
        -Header
        -Welcome-msg
        -Header Search
        -Other 
            - Login
            - Call
            - Wishlist
        -Sticky Header
        -Other Options
---------------------------------------------- */
.header {
    font-family: var(--font-family-poppins);
    letter-spacing: -0.025em;
    background: #fff;
    border-bottom: 1px solid #22222236;
}

.header .container,
.header .container-fluid,
.header .inner-wrap {
    display: flex;
    align-items: center;
    text-align: center;
}

.header .inner-wrap {
    width: 100%;
}

.header-transparent {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 20;
    background: transparent;
}

.header-transparent .fixed {
    background: #222;
}

.header-top,
.header-middle,
.header-bottom {
    display: flex;
}

.header-border {
    border-bottom: 1px solid #eee;
}

.has-center .header-left,
.has-center .header-right {
    flex: 1;
}

.has-center .header-right {
    justify-content: flex-end;
}

.has-center .header-center {
    margin-left: 2rem;
    margin-right: 2rem;
}

/*.header-left {
  flex: 1;
}*/

.header-left,
.header-right,
.header-center {
    display: flex;
    align-items: center;
}

.header-right {
    margin-left: auto;
}

.header-right a {
    display: flex;
    align-items: center;
}

.header-top {
    padding-top: 6px;
    padding-bottom: 6px;
    border-bottom: 1px solid #eee;
    font-size: 1.3rem;
    letter-spacing: -0.025em;
    background: var(--base-color);
}

.header-top .header-right>* {
    margin-left: 2rem;
}

.header-top .header-right>*:first-child {
    margin-left: 0;
}

.header-top .divider {
    margin: 0 -0.3rem 0 2rem;
}

.header-top .contact {
    margin-right: 0.2rem;
    line-height: 1;
}

.header-top .contact i {
    margin-right: 0.7rem;
    font-size: 1.5rem;
    margin: 0 0.65rem 0 0;
}

.header-top .help {
    line-height: 1;
    font-size: 12px;
    font-family: var(--font-family-poppins);
}

.header-top .help i {
    margin-right: 0.7rem;
    font-size: 1.6rem;
}

.header-top .login-link,
.header-top .login-toggle,
.header-top .register-link,
.header-top .register-toggle {
    cursor: pointer;
}

.header-top .login-link i,
.header-top .login-toggle i,
.header-top .register-link i,
.header-top .register-toggle i {
    margin-right: 0.9rem;
    font-size: 1.5rem;
}

.header-top .delimiter {
    margin: 0 0.5rem;
    color: #aaa;
}

.header-top .wishlist {
    margin-right: 2rem;
}

.header-top .wishlist i {
    margin-right: 0.6rem;
    font-size: 1.7rem;
}

.currency-dropdown a,
.language-dropdown a {
    white-space: nowrap;
}

.header-middle {
    padding-top: 2.8rem;
    padding-bottom: 2.7rem;
    color: #333;
    background: #fff;
    font-size: 1.4rem;
    font-weight: 700;
}

.header-middle .login-link {
    display: flex;
    align-items: center;
    margin-right: 0rem;
}

.header-middle .login-link span {
    cursor: pointer;
}

.header-middle .login-link i {
    font-size: 2rem;
}

.header-middle .login-link:hover {
    color: #4dae67;
}

.header-middle .logo {
    margin-right: 8rem;
    margin-bottom: 0.1rem;
}

.header-middle .compare {
    margin-right: 3.5rem;
}

.header-middle .compare i {
    font-size: 3.2rem;
    margin-bottom: 0.1rem;
}

.header-middle .main-nav {
    margin-right: 1.5rem;
}

.header-middle .header-right .cart-dropdown>a::after {
    content: none;
}

.header-middle .icon-box {
    margin-right: 1.8rem;
    padding-bottom: 0.1rem;
    transition: none;
}

.header-middle .icon-box:hover .icon-box-icon,
.header-middle .icon-box:hover p,
.header-middle .icon-box:hover .icon-box-title {
    color: inherit;
}

.header-middle .icon-box-icon,
.header-middle p,
.header-middle .icon-box-title {
    transition: color 0.3s;
}

.header-middle .icon-box-icon {
    margin-bottom: 0;
    padding: 0;
    font-size: 3rem;
}

.header-middle .icon-box-title {
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 1;
    letter-spacing: -0.027em;
}

.header-middle p {
    font-size: 1.4rem;
    font-weight: 700;
    color: #222;
    letter-spacing: -0.0428em;
    line-height: 1;
}

.logo {
    max-width: 200px;
}

.logo img {
    display: block;
}

.header-bottom {
    padding: 5px 0px;
    color: #222;
    background: #dfdfdf54;
    font-size: 1.4rem;
    font-weight: 700;
}

.header-bottom .header-right {
    margin-right: 0.3rem;
}

.header-bottom .header-right i {
    margin-right: 1rem;
    font-size: 2.4rem;
    font-weight: 700;
}

.welcome-msg {
    margin: 0;
    padding: 3px 0px;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    letter-spacing: -0.0125em;
    line-height: 1.5;
    white-space: nowrap;
}

.header-search {
    position: relative;
}

.header-search .input-wrapper {
    display: flex;
    position: relative;
}

.header-search input.form-control {
    flex: 1;
    min-width: 40px;
    padding: 1.1rem 1.5rem;
    border: 0;
    background-color: #eee;
    font-size: 1.3rem;
    letter-spacing: inherit;
}

.header-search .btn-search {
    padding: 0;
    min-width: 40px;
    background-color: #4dae67;
    border: 0;
    color: #fff;
    font-size: 2rem;
}

.header-search .btn-search i {
    margin: 0 0.1rem 0.6rem 0;
    vertical-align: middle;
    font-size: 2rem;
}

.header-search .select-box {
    background-color: #f4f4f4;
}

.header-search .select-box select {
    position: relative;
    padding-left: 1.1rem;
    padding-right: 3rem;
    border: 0;
    font-weight: 400;
    font-size: 1.3rem;
    letter-spacing: 0;
    z-index: 1;
}

.header-search.hs-toggle .input-wrapper {
    position: absolute;
    right: -1.5rem;
    top: 100%;
    min-width: 31rem;
    padding: 1.5rem;
    height: auto;
    background-color: #fff;
    box-shadow: 0 2px 35px rgba(0, 0, 0, 0.1);
    visibility: hidden;
    opacity: 0;
    transform: translate3d(0, -10px, 0);
    z-index: 100;
    transition: transform 0.4s 0.2s, visibility 0.4s 0.2s, opacity 0.4s 0.2s;
}

.header-search.hs-toggle:hover {
    color: #4dae67;
}

.header-search.hs-toggle .form-control {
    padding: 1.4rem 2rem;
    background-color: #f4f4f4;
}

.header-search.hs-toggle .btn-search {
    background-color: #f4f4f4;
    min-width: 5rem;
    font-size: 1.8rem;
    color: #222;
    border-radius: 0;
}

.header-search.hs-toggle .btn-search i {
    margin-bottom: 0.4rem;
    font-size: inherit;
}

.header-search.hs-expanded {
    max-width: 65rem;
    width: 100%;
    margin: 0 auto;
    flex: 1;
}

.header-search.hs-expanded .input-wrapper {
    width: 100%;
    border: 2px solid #4dae67;
    border-radius: 0.5rem;
    height: 4.5rem;
}

.header-search.hs-expanded .select-box {
    width: 13.2rem;
    border-radius: 0.5rem 0 0 0.5rem;
    color: #666;
}

.header-search.hs-expanded .select-box,
.header-search.hs-expanded .select-box select {
    cursor: pointer;
}

.header-search.hs-expanded .select-box::before {
    right: 11px;
    font-size: 1rem;
}

.header-search.hs-expanded .select-box::after {
    content: "";
    display: block;
    position: absolute;
    top: 8px;
    right: -3px;
    height: 25px;
    border-right: 1px solid #eee;
}

.header-search.hs-expanded input.form-control {
    flex: 1;
    min-height: 100%;
    padding-left: 1.9rem;
    padding-right: 1.9rem;
    letter-spacing: 0;
    color: #ababab;
    background-color: #f4f4f4;
}

.header-search.hs-expanded .btn-search {
    min-width: 4.7rem;
    background-color: #f4f4f4;
    color: #444;
    font-size: 1.6rem;
}

.header-search.hs-expanded .btn-search:hover {
    color: #4dae67;
}

.header-search.search-left .input-wrapper {
    left: 0;
    right: auto;
}

.header-search.hs-simple {
    flex: 1;
    max-width: 52.2rem;
}

.header-search.hs-simple input.form-control {
    padding: 0 5rem 0 1.55rem;
    border-radius: 100px;
    border: none;
    border-color: none;
    font-family: inherit;
    background: #f4f4f4;
    color: #999;
    font-size: 15px;
}

.header-search.hs-simple .btn-search {
    position: absolute;
    background: transparent;
    color: #333;
    min-width: 48px;
    height: 100%;
    border-radius: 5px;
    right: 0;
}

.header-search.hs-simple .btn-search:hover {
    color: #4dae67;
}

.header .header-search.hs-toggle:hover .input-wrapper,
.header-search.hs-toggle.show .input-wrapper {
    transform: translate3d(0, 0, 0);
    visibility: visible;
    transition: transform 0.4s;
    opacity: 1;
}

.mobile-search {
    display: none;
}

.mobile-search .search-toggle i {
    font-size: 2rem;
    vertical-align: middle;
}

.hs-toggle.dir-up::after {
    top: auto;
    bottom: 100%;
    border-bottom-color: transparent;
    border-top-color: #fff;
}

.hs-toggle.dir-up .input-wrapper {
    top: auto;
    bottom: 100%;
    transform: translate3d(0, 10px, 0);
}

.search-toggle {
    display: inline-block;
    vertical-align: middle;
    padding: 1.1rem 0;
    transition: color 0.3s;
}

.search-toggle span {
    cursor: pointer;
}

.search-toggle i {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 0.9;
}

.search-toggle.with-label {
    display: inline-flex;
    align-items: center;
}

.search-toggle.with-label i {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 2px;
    font-size: 1.6rem;
}

.search-toggle.with-label span {
    margin-left: 0.5rem;
    cursor: pointer;
    font-weight: inherit;
    letter-spacing: inherit;
    text-transform: uppercase;
}

.main-nav {
    margin: 0 0 0 2px;
}

.call {
    display: flex;
    align-items: center;
}

.call span {
    font-weight: inherit;
    text-transform: uppercase;
    cursor: pointer;
}

.call i+span {
    margin-left: 0.9rem;
}

.call i {
    margin: 0 7px 2px 0;
    font-size: 1.5rem;
    cursor: pointer;
}

.call:hover {
    color: #4dae67;
}

.wishlist {
    margin-right: 1.5rem;
}

.wishlist i {
    font-size: 2.3rem;
    cursor: pointer;
}

.wishlist:hover {
    color: #4dae67;
}

@keyframes fixedTop {
    0% {
        margin-top: -60px;
    }

    100% {
        margin-top: 0;
    }
}

@keyframes fixedBottom {
    from {
        transform: translateY(100%);
        transform-origin: center top 0px;
    }

    to {
        transform: translateY(0);
    }
}

.sticky-header.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1100;
    animation: fixedTop 0.4s;
}

@media (max-width: 991px) {
    .sticky-wrapper {
        height: auto !important;
    }
}

.mobile-menu-toggle {
    display: none !important;
    margin-right: 2rem !important;
    font-size: 2.5rem !important;
    color: #222 !important;
}

.mobile-menu-toggle.menu-bar::before,
.mobile-menu-toggle.menu-bar::after {
    content: "";
    display: block !important;
    width: 100% !important;
}

.mobile-menu-toggle.menu-bar::before {
    height: 11px;
    border-top: 2px solid;
    border-bottom: 2px solid;
}

.mobile-menu-toggle.menu-bar::after {
    height: 9px;
    border-bottom: 2px solid;
}

@media (max-width: 1199px) {
    .main-nav .menu>li {
        margin-right: 1.5rem;
    }

    .header-middle .logo {
        margin-right: 2rem;
    }
}

@media (max-width: 991px) {
    .header .main-nav {
        display: none !important;
    }

    .header-top .header-left {
        margin-right: 2rem !important;
    }

    .header-middle .header-right {
        justify-content: flex-end !important;
    }

    .header-middle .header-right .divider {
        display: none !important;
    }

    .header-middle .header-center {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .header-middle .compare,
    .header-middle .icon-box {
        margin-right: 1.5rem !important;
    }

    .mobile-menu-toggle {
        display: block !important;
    }
}

@media (max-width: 767px) {
    .header-middle .mobile-search {
        display: block !important;
    }

    .header-middle .header-search {
        display: none !important;
    }

    .header-top .login-link,
    .header-top .register-link,
    .header-top .divider,
    .header-top .delimiter {
        display: none !important;
    }
}

@media (max-width: 480px) {
    .header-top .header-left {
        position: relative;
        overflow: hidden;
    }

    .header-middle .logo {
        margin-right: 0;
    }

    .header-middle .wishlist,
    .header-middle .divider {
        display: none;
    }

    .header-middle .icon-box {
        margin-right: 1rem;
    }

    .welcome-msg {
        transform: translateX(0);
        animation: 6s linear 2s 1 show_msg_first, 12s linear 8s infinite show_msg;
    }
}

@keyframes show_msg_first {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-100%);
    }
}

@keyframes show_msg {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(-100%);
    }
}

.label-down {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.label-down i {
    display: inline-block;
}

.label-down span {
    display: block;
}

.label-down i+span {
    margin-top: 0.7rem;
}

.label-block span {
    display: block;
}

.label-block .cart-name {
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
    font-weight: 400;
    text-transform: capitalize;
}

.label-block .cart-name ::after {
    content: none;
}

.label-block .cart-price {
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: -0.05em;
}

.label-block.cart-toggle i {
    font-size: 3rem;
}

.mobile-link {
    display: block;
    margin-right: 0;
    margin-bottom: -3px;
}

.mobile-link i {
    font-size: 2.4rem;
}

.mobile-link+.mobile-link {
    margin-left: 2rem;
}

.mobile-link:hover {
    color: #4dae67;
}

@media (min-width: 768px) {
    .mobile-link {
        display: none;
    }
}

/* -------------------------------------------
    Dropdowns
        - Dropdown
        - Dropdown-expanded
        - CartDropdown
        - Category Dropdown
---------------------------------------------- */
.dropdown {
    position: relative;
}

.dropdown .product {
    overflow: unset;
}

.dropdown:hover .dropdown-box,
.dropdown.show .dropdown-box {
    visibility: visible;
    opacity: 1;
    top: 99%;
}

.dropdown:hover .dropdown-box,
.dropdown.show .dropdown-box {
    transform: translate3d(0, 0, 0);
}

.dropdown:hover>a,
.dropdown.show>a {
    color: #4dae67;
}

.dropdown a {
    display: flex;
    align-items: center;
}

.dropdown a .dropdown-image {
    max-width: 1.4rem;
    margin-right: 0.7rem;
    height: auto;
}

.dropdown>a {
    line-height: 1;
    padding: 9px 0;
}

.dropdown>a::after {
    display: inline-block;
    margin-left: 8px;
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    font-size: 10px;
    line-height: 1;
    content: "";
}

.dropdown li.active>a,
.dropdown li:hover>a {
    color: #4dae67;
}

.dropdown.dir-up::after {
    border-bottom-color: transparent;
    border-top: 11px solid #fff;
    transform: translate3d(-50%, 8px, 0);
}

.dropdown.dir-up:hover .dropdown-box,
.dropdown.dir-up.show .dropdown-box {
    top: auto;
    bottom: 100%;
}

.dropdown.dir-up:hover::after,
.dropdown.dir-up.show::after {
    top: auto;
    bottom: calc(100% - 20px);
    transform: translate3d(-50%, 0, 0);
}

.dropdown-box {
    position: absolute;
    right: 0;
    top: -9999px;
    margin: 0;
    padding: 0.5rem 0;
    color: #666;
    background-color: #fff;
    box-shadow: 0 5px 30px 2px rgba(0, 0, 0, 0.2);
    visibility: hidden;
    opacity: 0;
    z-index: 19;
    transition: transform 0.2s ease-out, opacity 0.2s, visibility 0.2s;
    transform: translate3d(0, -10px, 0);
}

.dropdown-box a {
    font-weight: 400;
    padding: 0.6rem 1rem;
}

.dropdown-box li {
    font-size: inherit;
    line-height: 1.1;
}

.dropdown:not(.category-dropdown):not(.off-canvas) .dropdown-box {
    z-index: 1061;
}

@media (min-width: 992px) {
    .dropdown-expanded::after {
        content: none;
    }

    .dropdown-expanded::before {
        position: absolute;
        content: "";
        top: 50%;
        transform: translateY(-50%);
        left: -2.1rem;
        width: 1px;
        height: 2.5rem;
        background-color: #e1e1e1;
    }

    .dropdown-expanded>a {
        display: none;
    }

    .dropdown-expanded .dropdown-box {
        position: static;
        display: flex;
        visibility: visible;
        opacity: 1;
        background-color: transparent;
        box-shadow: none;
        border: 0;
        padding: 9px 0;
        transform: none;
        color: inherit;
    }

    .dropdown-expanded .dropdown-box a {
        padding: 0;
        letter-spacing: -0.025em;
    }

    .dropdown-expanded .dropdown-box>li {
        margin-right: 2.3rem;
    }

    .dropdown-expanded .dropdown-box>li:last-child {
        margin-right: 0;
    }
}

@media (max-width: 991px) {
    .dropdown.dropdown-expanded li:hover>a {
        color: #4dae67;
    }
}

.cart-dropdown .dropdown-box,
.compare-dropdown .dropdown-box,
.wishlist-dropdown .dropdown-box {
    right: -1rem;
    padding: 3rem;
    min-width: 33.5rem;
}

.cart-dropdown .products,
.compare-dropdown .products,
.wishlist-dropdown .products {
    max-height: 360px;
    overflow-x: hidden;
    margin-right: -5px;
    padding-right: 1rem;
}

.cart-dropdown>a {
    padding: 0.7rem 0;
}

.cart-dropdown .cart-toggle {
    padding: 0.6rem 0 0.7rem;
}

.cart-dropdown .cart-toggle::after {
    content: none;
}

.cart-dropdown .cart-label {
    display: block;
    cursor: pointer;
    margin: 0 1rem 0 0;
    font-weight: inherit;
    text-transform: uppercase;
    letter-spacing: -0.025em;
}

.cart-dropdown .minicart-icon {
    display: inline-block;
    font-size: 1.2rem;
    color: #4dae67;
}

.cart-dropdown .minicart-icon2 {
    display: inline-block;
    font-size: 1.2rem;
    color: #4dae67;
}

.cart-dropdown .cart-count {
    display: inline-block;
    transition: color 0.4s;
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 25px;
    color: #4dae67;
}

.cart-dropdown .cart-total {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2.1rem;
    padding: 1.7rem 0 1.5rem;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: #edeef0;
    font-size: 1.4rem;
    line-height: 1;
    letter-spacing: normal;
}

.cart-dropdown .cart-total label {
    margin: 0 auto 0 0.3rem;
    line-height: inherit;
    color: #666;
    font-weight: 400;
}

.cart-dropdown .cart-total .price {
    font-weight: 700;
    font-size: 1.6rem;
    color: #222;
}

.cart-dropdown .cart-action {
    column-gap: 10px;
    text-align: center;
}

.cart-dropdown .cart-action .btn {
    display: flex;
    justify-content: center;
    border-radius: 3px;
    padding: 0.9em 2em;
    line-height: 1.5;
    letter-spacing: 0.01em;
    font-weight: 700;
}

.cart-dropdown .cart-action .btn.btn-link {
    display: inline-block;
    margin-bottom: 2rem;
    padding: 0;
    border-bottom: 2px solid #4dae67;
    border-radius: 0;
    text-transform: capitalize;
    line-height: 1.3;
}

.cart-dropdown .cart-action .btn.btn-link:hover,
.cart-dropdown .cart-action .btn.btn-link:active,
.cart-dropdown .cart-action .btn.btn-link:focus {
    color: #4dae67;
}

.cart-dropdown i {
    font-size: 2.4rem;
}

.cart-dropdown:hover .minicart-icon {
    background: #4dae67;
}

.cart-dropdown:hover .minicart-icon::before {
    transform: rotateY(180deg);
}

.cart-dropdown:hover .cart-count {
    color: #fff;
}

.cart-dropdown.cart-dropdown-white .cart-label,
.cart-dropdown.cart-dropdown-white .cart-price,
.cart-dropdown.cart-dropdown-white .cart-count {
    color: #fff;
}

.cart-dropdown.cart-dropdown-white .minicart-icon {
    border-color: #fff;
}

.cart-dropdown.cart-dropdown-white:hover .minicart-icon {
    background-color: #fff;
}

.cart-dropdown.cart-dropdown-white:hover .cart-count {
    color: #4dae67;
}

.cart-dropdown.type2 .cart-toggle {
    align-items: center;
}

.cart-dropdown.type2:hover {
    color: #4dae67;
}

.cart-dropdown.type2 .cart-count {
    position: absolute;
    right: -8px;
    top: 3px;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.9rem;
    line-height: 1.7;
    text-align: center;
    border-radius: 50%;
    background-color: #4dae67;
    color: #fff;
    z-index: 1;
}

.cart-dropdown.type2 .label-block .cart-count {
    width: 1.9rem;
    height: 1.9rem;
    font-size: 1.1rem;
    line-height: 1.8rem;
}

.cart-dropdown.type3 .cart-toggle {
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    background-color: #4dae67;
    color: rgba(255, 255, 255, 0.8);
    transition: 0.3s;
}

.cart-dropdown.type3 .cart-toggle i {
    font-size: 1.5rem;
    margin-right: 7px;
}

.dark-theme .cart-dropdown .cart-total {
    border-color: #333;
}

.compare-dropdown .compare-toggle:after {
    content: none;
}

.compare-dropdown .compare-btn {
    display: flex;
    justify-content: center;
    border-radius: 3px;
    padding: 0.9em 2em;
    line-height: 1.5;
    letter-spacing: 0.01em;
    font-weight: 700;
}

.wishlist-dropdown .wishlist-toggle:after {
    content: none;
}

.wishlist-dropdown .wishlist-btn {
    display: flex;
    justify-content: center;
    border-radius: 3px;
    padding: 0.9em 2em;
    line-height: 1.5;
    letter-spacing: 0.01em;
    font-weight: 700;
}

.header-top .login-dropdown {
    margin-left: 0;
}

.off-canvas .dropdown-box {
    position: fixed;
    top: 0;
    right: -34rem;
    max-width: 34rem;
    width: 100%;
    height: 100vh;
    min-width: auto;
    padding: 2.9rem 3rem;
    opacity: 1;
    visibility: visible;
    transition: right 0.3s;
    transform: none;
    z-index: 2999;
    box-shadow: none;
    overflow-x: hidden;
    overflow-y: auto;
}

.off-canvas .dropdown-box .login-popup {
    padding: 0;
}

.off-canvas .canvas-overlay {
    position: fixed;
    left: 0;
    width: 100vw;
    top: -10vh;
    height: 120vh;
    background: rgba(0, 0, 0, 0.3);
    z-index: 2998;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
}

.off-canvas .canvas-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding-bottom: 1.3rem;
}

.off-canvas .canvas-title {
    margin-bottom: 0;
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: -0.018em;
}

.off-canvas .btn-close {
    font-size: 12px;
    font-weight: 600;
    color: #999;
}

.off-canvas .btn-close:hover,
.off-canvas .btn-close:focus,
.off-canvas .btn-close:active {
    color: #4dae67;
}

.off-canvas .btn-close i {
    margin-right: 0;
    font-size: 1.9rem;
}

.off-canvas .product-cart,
.off-canvas .product-compare,
.off-canvas .product-wishlist {
    margin-top: 2rem;
}

.off-canvas.opened .dropdown-box {
    right: 0;
}

.off-canvas.opened .canvas-overlay {
    opacity: 1;
    visibility: visible;
}

.off-canvas:hover .dropdown-box {
    top: 0;
}

@media (max-width: 479px) {
    .off-canvas .dropdown-box {
        max-width: 30.4rem;
    }
}

.product.product-cart-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1.3rem;
    border-bottom: 1px solid #edeef0;
}

.product.product-cart-header span,
.product.product-cart-header a {
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: -0.025em;
    line-height: 1;
    color: #222529;
}

.product.product-cart-header a {
    padding: 0;
    padding-bottom: 0;
    color: #222529;
    line-height: 1;
}

.product.product-cart-header a:hover {
    text-decoration: underline;
}

.product.product-cart,
.product.product-compare,
.product.product-wishlist {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
}

.product.product-cart:not(:first-child),
.product.product-compare:not(:first-child),
.product.product-wishlist:not(:first-child) {
    margin-top: 2rem;
}

.product.product-cart .product-media,
.product.product-compare .product-media,
.product.product-wishlist .product-media {
    position: static;
    width: 8rem;
    margin-right: 2.5rem;
}

.product.product-cart .product-media a,
.product.product-compare .product-media a,
.product.product-wishlist .product-media a {
    display: block;
    height: 100%;
    padding: 0;
}

.product.product-cart .product-media img,
.product.product-compare .product-media img,
.product.product-wishlist .product-media img {
    height: 100%;
}

.product.product-cart .product-detail,
.product.product-compare .product-detail,
.product.product-wishlist .product-detail {
    flex: 1;
    margin: 0 1rem 0rem 0;
}

.product.product-cart .product-name,
.product.product-compare .product-name,
.product.product-wishlist .product-name {
    white-space: normal;
    padding: 0;
    margin-bottom: 0.9rem;
    margin-right: 1rem;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.34;
    letter-spacing: -0.35px;
    font-family: var(--font-family-poppins);
    color: #666;
}

.product.product-cart .product-name:hover,
.product.product-compare .product-name:hover,
.product.product-wishlist .product-name:hover {
    color: #4dae67;
}

.product.product-cart .price-box,
.product.product-compare .price-box,
.product.product-wishlist .price-box {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    line-height: 1;
}

.product.product-cart .product-price,
.product.product-compare .product-price,
.product.product-wishlist .product-price {
    font-size: 1.6rem;
    letter-spacing: -0.35px;
}

.product.product-cart .product-quantity,
.product.product-compare .product-quantity,
.product.product-wishlist .product-quantity {
    align-items: center;
    display: flex;
    margin-right: 1rem;
    font-weight: 400;
}

.product.product-cart .product-quantity::after,
.product.product-compare .product-quantity::after,
.product.product-wishlist .product-quantity::after {
    margin-left: 1rem;
    content: "X";
    text-transform: none;
    line-height: 0;
    font-size: 1.5rem;
}

.product.product-cart .product-price,
.product.product-compare .product-price,
.product.product-wishlist .product-price {
    margin: 0;
    color: #222;
}

.product.product-cart .btn-close,
.product.product-compare .btn-close,
.product.product-wishlist .btn-close {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 2.2rem;
    height: 2.2rem;
    right: -0.4rem;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    background-color: #fff;
    color: #222;
    font-size: 1.2rem;
    border: 1px solid #ccc;
}

.product.product-cart .btn-close i,
.product.product-compare .btn-close i,
.product.product-wishlist .btn-close i {
    margin-right: 4px;
    margin-top: 0px;
    font-size: 1.2rem;
}

.product.product-cart .btn-close:hover,
.product.product-compare .btn-close:hover,
.product.product-wishlist .btn-close:hover {
    color: #4dae67;
    border-color: #4dae67;
}

.product.product-cart:last-child,
.product.product-compare:last-child,
.product.product-wishlist:last-child {
    margin-bottom: 0;
}

@media (max-width: 991px) {
    .cart-dropdown .cart-label {
        display: none;
    }
}

@media (max-width: 575px) {

    .cart-dropdown .product .product-media,
    .compare-dropdown .product .product-media {
        margin-right: 1rem;
    }

    .cart-dropdown .dropdown-box,
    .compare-dropdown .dropdown-box {
        min-width: 31rem;
    }

    .cart-dropdown .cart-total,
    .compare-dropdown .cart-total {
        font-size: 1.3rem;
    }
}

.category-dropdown>a {
    padding: 1.7rem 1.7rem;
    background: #4dae67;
}

.category-dropdown>a::after {
    content: none;
}

.category-dropdown>a i {
    font-size: 1.8rem;
}

.category-dropdown>a span {
    margin-left: 1rem;
}

.category-dropdown .dropdown-box {
    padding: 0;
    left: 0;
    min-width: 12rem;
    box-shadow: none;
    background-color: #f4f4f4;
    transition: opacity 0.2s, z-index 0s, transform 0.2s ease-out;
    visibility: hidden;
    top: 100%;
}

.category-dropdown::before,
.category-dropdown::after {
    left: 25px;
}

.category-dropdown::after {
    border-bottom-color: #f4f4f4;
    visibility: hidden;
    top: calc(100% - 20px);
}

.category-dropdown.menu-fixed .dropdown-box,
.category-dropdown.menu-fixed::after {
    visibility: hidden;
}

.category-dropdown.dropdown.show .dropdown-box {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transform: translate3d(0, 0, 0);
    transition: opacity 0.5s, z-index 0s, transform 0s;
}

.category-dropdown.dropdown.show .dropdown-box,
.category-dropdown.dropdown.show::after {
    visibility: visible;
}

.category-dropdown.dropdown.show::after {
    transform: translate3d(-50%, 0, 0);
}

.category-dropdown.has-border::after {
    border-bottom-color: #fff;
}

.category-dropdown.has-border::before,
.category-dropdown.has-border::after {
    content: "";
    position: absolute;
    z-index: 1000;
    top: -9999px;
    transform: translateX(-50%);
    border: 11px solid transparent;
    border-bottom: 11px solid #eee;
    transition: opacity 0.4s ease;
    visibility: hidden;
    opacity: 0;
    cursor: pointer;
}

.category-dropdown.has-border .dropdown-box {
    background-color: #fff;
    border: 1px solid #eee;
}

.category-dropdown.has-border.menu-fixed::before {
    visibility: visible;
    opacity: 1;
}

.sticky-header:not(.fixed) .category-dropdown.menu-fixed::after {
    top: 100%;
    transform: translate3d(-50%, 0, 0);
}

.sticky-header:not(.fixed) .category-dropdown.menu-fixed .dropdown-box {
    top: calc(100% + 20px);
    transform: none;
}

.sticky-header:not(.fixed) .category-dropdown.menu-fixed .dropdown-box,
.sticky-header:not(.fixed) .category-dropdown.menu-fixed::after {
    visibility: visible;
    opacity: 1;
}

.sticky-header:not(.fixed) .category-dropdown.menu-fixed.has-border::before {
    top: calc(100% - 1px);
    visibility: visible;
    opacity: 1;
}

@media (-webkit-min-device-pixel-ratio: 1.5),
(min--moz-device-pixel-ratio: 1.5),
(min-device-pixel-ratio: 1.5) {
    .sticky-header:not(.fixed) .category-dropdown.menu-fixed.has-border::before {
        top: calc(100% - 2px);
    }
}

/* -------------------------------------------
    Menu
        - Default

        - Menu(MainMenu)
            - MegaMenu
            - MenuBanner
            - Tips

        - MobileMenu 

            - MobileMenuWrapper
            - MobileMenuContainer
            - MobileMenu
            - MobileMenuOverlay
            - MobileMenuCloseButton

        - CategoryMenu
 ------------------------------------------- */
.menu {
    display: flex;
    align-items: center;
}

.menu a {
    display: inline-block;
}

.menu .menu-title {
    margin-bottom: 1rem;
    padding: 0 1rem;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
}

.menu ul {
    padding: 2rem 0;
    background: #fff;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0;
    color: #666;
}

.menu li {
    position: relative;
    line-height: 1.5;
}

.menu li a {
    padding: 0.7rem 0 0.7rem 1rem;
}

.menu li>ul,
.menu li .megamenu {
    position: absolute;
    top: -9999px;
    left: 100%;
    margin: 0;
    box-shadow: 0 2px 35px rgba(0, 0, 0, 0.1);
    z-index: 1041;
    visibility: hidden;
    opacity: 0;
    transition: transform 0.2s ease-out;
    transform: translate3d(0, -10px, 0);
}

.menu li>ul {
    min-width: 22.6rem;
    background: #fff;
}

.menu li>ul li {
    padding: 0 2rem;
}

.menu>li.submenu-container .megamenu {
    width: 1180px;
}

.menu li:hover>a:not(.menu-title),
.menu .active>a:not(.menu-title) {
    color: var(--base-color);
    /*font-size: 16px;*/
}

.menu li:hover>ul,
.menu li:hover .megamenu,
.menu .show>ul,
.menu .show .megamenu {
    visibility: visible;
    opacity: 1;
    top: -2rem;
    transform: translate3d(0, 0, 0);
}

.menu>li {
    margin-right: 3rem;
}

.menu>li:last-child {
    margin-right: 0;
}

.menu>li>a {
    position: relative;
    padding: 1.3rem 0;
    font-weight: 100;
    letter-spacing: inherit;
    line-height: 1;
    text-transform: capitalize;
    font-size: 16px;
    font-family: var(--font-family-poppins);
}

.menu>li>a::after {
    margin-left: 0.8rem;
}

.menu>li>ul,
.menu>li .megamenu {
    left: -1.9rem;
}

.menu>li:hover>ul,
.menu>li:hover .megamenu,
.menu>li.show>ul,
.menu>li.show .megamenu {
    top: 100%;
}

.menu .submenu>a {
    position: relative;
    display: block;
}

.menu>.submenu>a {
    z-index: 10;
    font-size: 16px;
}

.menu>.submenu>a::after {
    position: static;
    /* content: ""; */
    margin-top: 0;
}

.menu.no-arrow>li>a::after {
    content: none;
}

.menu .submenu>a {
    position: relative;
    display: block;
}

.submenu>a::after {
    display: inline-block;
    position: absolute;
    margin-top: 2px;
    right: 0.5rem;
    top: 50%;
    line-height: 0;
    vertical-align: middle;
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    font-size: 1.1rem;
    color: inherit;
    /* content: ""; */
}

.vertical-menu {
    display: block;
    min-width: 28rem;
}

.vertical-menu>li {
    margin-right: 0;
    padding: 0 2rem;
}

.vertical-menu>li>ul,
.vertical-menu>li .megamenu {
    transform: translate3d(-15px, 0, 0);
}

.vertical-menu>li>a {
    display: inline-block;
}

.vertical-menu>li>a::after {
    content: none;
}

.vertical-menu>li:hover>ul,
.vertical-menu>li:hover .megamenu,
.vertical-menu>li.show>ul,
.vertical-menu>li.show .megamenu {
    left: 100%;
    top: -1rem;
}

.vertical-menu>.submenu::after {
    border: 11px solid transparent;
    border-right: 11px solid #fff;
}

.vertical-menu>.submenu:hover::after,
.vertical-menu>.submenu.show::after {
    top: 50%;
    left: calc(100% - 20px);
    transform: translate3d(0, -50%, 0);
}

.vertical-menu>.submenu>a::after {
    position: absolute;
    line-height: 0;
    content: "";
}

.vertical-menu.no-arrow>li {
    display: flex;
}

.vertical-menu.no-arrow>li>a::after {
    content: none;
}

.toggle-menu {
    display: block;
}

.toggle-menu>li {
    margin-right: 0;
}

.toggle-menu .submenu::after,
.toggle-menu .submenu>a::after {
    content: none;
}

.toggle-menu .submenu li {
    padding: 0 0.5rem;
}

.toggle-menu li>ul {
    display: none;
    position: static;
    visibility: visible;
    opacity: 1;
    transform: none;
    background: transparent;
    box-shadow: none;
}

.toggle-btn {
    display: block;
    position: absolute;
    padding: 6px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.6;
}

.toggle-btn::before {
    content: "";
    display: block;
    font-size: 1rem;
    font-weight: 600;
    font-family: "Font Awesome 5 Free";
    line-height: 1;
    transition: transform 0.5s;
}

.show>a .toggle-btn::before {
    transform: rotate(630deg);
}

.menu-active-underline>li>a::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 9px;
    left: 0;
    width: 100%;
    border-bottom: 2px solid;
    transform-origin: right center;
    transform: scale(0, 1);
    transition: transform 0.3s;
}

.menu-active-underline>li.active>a,
.menu-active-underline>li:hover>a {
    color: inherit;
}

.menu-active-underline>li.active>a::before,
.menu-active-underline>li:hover>a::before {
    transform-origin: left center;
    transform: scale(1, 1);
}

.main-nav {
    margin: 0 0 0 2px;
}

.megamenu {
    display: flex;
    padding: 1rem;
    min-width: 94rem;
    background: #fff;
}

.megamenu ul {
    padding: 0;
}

.megamenu .row {
    flex: 1;
    padding: 0 1rem;
}

.megamenu .row>* {
    padding: 1.8rem 1rem 0.8rem;
}

.megamenu .menu-banner {
    padding: 0;
    overflow: hidden;
}

.megamenu .menu-banner figure {
    height: 100%;
}

.megamenu .menu-banner img {
    height: 100%;
    object-fit: cover;
}

.megamenu .menu-banner .btn-link:hover {
    color: #4dae67;
}

.menu-banner1 .banner-content {
    left: 9%;
}

.menu-banner1 .banner-title,
.menu-banner1 .banner-subtitle {
    font-size: 3.6rem;
}

.menu-banner1 .banner-subtitle {
    margin-bottom: 0.4rem;
}

.menu-banner1 .banner-title {
    margin-bottom: 1.8rem;
    padding-left: 1.2rem;
    position: relative;
}

.menu-banner1 .banner-title span {
    display: inline-block;
    position: absolute;
    left: -0.9rem;
    top: 50%;
    font-size: 1.2rem;
    line-height: 1;
    transform: rotateZ(-90deg) translateX(0.6rem);
    letter-spacing: -0.1em;
}

.menu-banner2 .banner-content {
    bottom: 10%;
}

.menu-banner2 .banner-title {
    margin-bottom: 0.6rem;
    font-size: 2.6rem;
}

.menu-banner2 .banner-subtitle {
    font-size: 1.6rem;
}

.tip {
    display: inline-block;
    position: relative;
    top: -1px;
    left: 7px;
    padding: 0.3rem 0.5rem;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    color: #fff;
    text-transform: uppercase;
    border-radius: 2px;
}

.tip.tip-hot {
    background-color: #fd990b;
}

.tip.tip-new {
    background-color: #4dae67;
}

.mobile-menu-wrapper {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    font-family: var(--font-family-poppins);
    transition: visibility 0.4s;
}

.mobile-menu-container {
    max-width: 296px;
    padding: 2rem 1.5rem;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: var(--white);
    box-shadow: 1px 0 5px rgba(0, 0, 0, 0.5);
    transform: translateX(-296px);
    transition: transform 0.4s;
}

.mobile-menu-container .input-wrapper {
    display: flex;
    margin-bottom: 0.6rem;
    height: 4rem;
}

.mobile-menu-container .input-wrapper .form-control {
    min-height: 4rem;
    color: #7a8088;
    border: 1px solid;
    border-color: #2e3237;
    border-right: 0;
    background-color: transparent;
}

.mobile-menu-container .input-wrapper .btn-search {
    padding: 0;
    width: 4rem;
    background-color: #4dae67;
    border-color: #4dae67;
    color: #fff;
    font-size: 1.4rem;
    border-radius: 3px;
}

.mobile-menu-container .input-wrapper .btn-search i {
    margin: 0 0 0.5rem;
    font-size: inherit;
}

.mobile-menu-container .mobile-menu {
    margin-bottom: 0.5rem;
    background: #222529;
}

.mobile-menu-container .tab {
    margin-top: 3rem;
}

.mobile-menu-container .tab-content {
    background-color: transparent;
}

.mobile-menu-container .nav {
    border-width: 2px;
}

.mobile-menu-container .nav-link {
    margin-bottom: -2px;
    color: #fff;
    font-size: 13px;
    padding: 10px;
}

.mobile-menu-container .tab-pane {
    padding-top: 10px;
}

.mobile-menu-container .nav-item:hover .nav-link {
    color: #fff;
    border-color: transparent;
}

.mobile-menu-container .nav-item.show .nav-link,
.mobile-menu-container .nav-item .nav-link.active {
    color: #4dae67;
    border-color: #4dae67;
}

.mobile-menu-container .nav-item+.nav-item {
    margin-left: 1px;
}

.mobile-menu-container.scrollable::-webkit-scrollbar-thumb,
.mobile-menu-container .sidebar-content::-webkit-scrollbar-thumb {
    margin-right: 2px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    cursor: pointer;
}

.mobile-menu {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: -0.025em;
    color: #e1e1e1;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: -0.025em;
}

.mobile-menu ul {
    display: none;
    width: 100%;
}

.mobile-menu>li:first-child {
    padding-top: 0.5rem;
}

.mobile-menu>li:last-child {
    padding-bottom: 0.5rem;
}

.mobile-menu li a {
    display: block;
    position: relative;
    padding: 1.3rem 0.6rem 1.3rem 1rem;
}

.mobile-menu li i {
    display: inline-block;
    margin-bottom: 1px;
    font-size: 2rem;
    margin-right: 1rem;
    line-height: 0;
    vertical-align: middle;
}

.mobile-menu li li a {
    padding-left: 2.4rem;
}

.mobile-menu li li li a {
    padding-left: 3.6rem;
}

.mobile-menu li:not(:last-child) {
    border-bottom: 1px solid #2e3237;
}

.mobile-menu-title {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 700;
    color: #fff;
}

.mobile-menu-overlay {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0;
    transition: opacity 0.4s;
}

.mobile-menu-close {
    position: fixed;
    left: calc(100vw - 50px);
    top: 25px;
    z-index: 10001;
    transition: opacity 0.3s;
    opacity: 0;
}

.mobile-menu-close i {
    font-size: 2.8rem;
    color: #e1e1e1;
}

.mmenu-anim {
    transform: translateY(30%);
}

.mmenu-anim,
.mmenu-anim>li {
    transition: transform 0.4s, opacity 0.3s;
    transition-timing-function: cubic-bezier(0.5, 0, 0.3, 1);
}

.mmenu-anim>li:nth-child(0) {
    opacity: 0;
    transform: translateY(0px);
}

.mmenu-anim>li:nth-child(1) {
    opacity: 0;
    transform: translateY(50px);
}

.mmenu-anim>li:nth-child(2) {
    opacity: 0;
    transform: translateY(100px);
}

.mmenu-anim>li:nth-child(3) {
    opacity: 0;
    transform: translateY(150px);
}

.mmenu-anim>li:nth-child(4) {
    opacity: 0;
    transform: translateY(200px);
}

.mmenu-anim>li:nth-child(5) {
    opacity: 0;
    transform: translateY(250px);
}

.mmenu-anim>li:nth-child(6) {
    opacity: 0;
    transform: translateY(300px);
}

.mmenu-anim>li:nth-child(7) {
    opacity: 0;
    transform: translateY(350px);
}

.mmenu-anim>li:nth-child(8) {
    opacity: 0;
    transform: translateY(400px);
}

.mmenu-anim>li:nth-child(9) {
    opacity: 0;
    transform: translateY(450px);
}

.mmenu-anim>li:nth-child(10) {
    opacity: 0;
    transform: translateY(500px);
}

.mmenu-anim>li:nth-child(11) {
    opacity: 0;
    transform: translateY(550px);
}

.mmenu-anim>li:nth-child(12) {
    opacity: 0;
    transform: translateY(600px);
}

.mmenu-active {
    overflow: hidden;
}

.mmenu-active .mmenu-anim>li,
.mmenu-active .mmenu-anim {
    opacity: 1;
    transform: translateY(0);
}

.mmenu-active .page-wrapper {
    margin-left: 296px;
    margin-right: -296px;
}

.mmenu-active .mobile-menu-wrapper {
    visibility: visible;
}

.mmenu-active .mobile-menu-container {
    transform: translateX(0);
}

.mmenu-active .mobile-menu-overlay {
    opacity: 0.8;
}

.mmenu-active .mobile-menu-close {
    opacity: 1;
}

@media (max-width: 400px) {
    .mobile-menu-close {
        left: calc(100vw - 40px);
        top: 10px;
    }
}

.category-menu {
    background: #fef0e3;
}

.category-menu .menu-title {
    padding: 1.8rem 0.2rem 1.8rem;
    margin: 0;
    text-transform: capitalize;
    font-size: 1.8rem;
    font-weight: 700;
    font-family: var(--font-family-poppins);
    line-height: 1;
    letter-spacing: -0.05em;
    color: #222;
}

.category-menu .menu-body:last-child li:last-child {
    border: 0;
}

.category-menu i {
    vertical-align: middle;
    line-height: 0;
    margin-right: 1rem;
    padding-left: 0.2rem;
    font-size: 2rem;
    color: #666;
}

.category-menu>li>a {
    display: block;
    padding: 1.2rem 0 1.3rem;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.46;
    letter-spacing: -0.01em;
    text-transform: capitalize;
    color: #675545;
}

.category-menu>li:not(:last-child)>a {
    border-bottom: 1px solid #f0e0d1;
}

.category-menu li:hover>a:not(.menu-title) {
    color: #4dae67;
}

.category-menu li:hover>a:not(.menu-title) i {
    color: inherit;
}

.category-menu .submenu>a::after {
    font-size: 1rem;
}

.category-menu .submenu .megamenu {
    min-width: 68.5rem;
    padding: 0 0 0 0.9rem;
}

.category-menu .submenu .megamenu>li {
    padding: 0 1rem;
    flex: 1;
}

.category-menu .submenu .megamenu>li:last-child {
    padding-right: 0;
    flex: none;
}

.category-menu .submenu .megamenu .menu-title {
    padding: 2.7rem 0rem 0.2rem;
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: -0.025em;
}

.category-menu .submenu .megamenu .divider {
    height: 1px;
    width: 100%;
    margin: 0;
}

.category-menu .submenu .megamenu ul {
    position: relative;
    left: 0;
    top: 0;
    padding: 0;
    font-size: 1.3rem;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    min-width: auto;
    z-index: auto;
    transform: none;
}

.category-menu .submenu .megamenu ul li {
    padding: 0;
    line-height: 1.2;
}

.category-menu .submenu .megamenu ul li a {
    padding: 0.7rem 0;
}

.category-menu .submenu .megamenu .menu-banner .banner-content {
    left: 3rem;
}

.category-menu .submenu .megamenu .menu-banner .banner-subtitle {
    font-size: 1.4rem;
}

.category-menu .submenu .megamenu .menu-banner .banner-title {
    font-size: 2.4rem;
    line-height: 1.2;
    margin-bottom: 2.8rem;
}

.category-menu .submenu .megamenu .menu-banner .btn-md {
    padding: 0.7em 1.41em 0.7em 1.41em;
}

.category-menu .submenu .megamenu .menu-banner5 .banner-content {
    top: 2.6rem;
}

.category-menu .submenu .megamenu .menu-banner5 .banner-subtitle {
    margin-bottom: 1.1rem;
}

.category-menu .submenu .megamenu .menu-banner5 .banner-title {
    line-height: 1.25;
}

.category-menu .submenu .megamenu.type2 {
    display: block;
    min-width: 88rem;
    padding: 0 1rem 2rem;
}

.category-menu .submenu .megamenu.type2 .menu-title {
    padding-top: 1.5rem;
}

.category-menu .submenu .megamenu.type2 figure {
    text-align: center;
}

.category-menu ul {
    padding: 2rem 0;
    background: #fff;
    font-family: var(--font-family-poppins);
    font-size: 1.4rem;
    line-height: 1.5;
    letter-spacing: 0;
    color: #666;
}

@media (max-width: 1199px) {
    .category-menu .submenu .megamenu.type2 {
        min-width: 72rem;
    }
}

/* 7. footer */
/* -------------------------------------------
    Footer
        - Footer top
        - Footer middle
        - Footer bottom
        - Copyright
        - Widget newsletter
        - Widget about
        - Sticky Footer
---------------------------------------------- */
.footer {
    font-family: var(--font-family-poppins);
    font-size: 1.3rem;
    background: #222;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
}

.footer p {
    font-family: var(--font-family-poppins);
    font-size: 16px !important;
    line-height: 30px;
    color: var(--white);
}

.footer a:not(.social-link):hover {
    color: #fff;
}

.footer .social-link {
    letter-spacing: 0.005em;
    color: var(--white);
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
    border-color: var(--white);
    margin-right: 10px;
}

.footer .social-link:hover {
    color: #fff;
}

.footer .widget-title {
    border-bottom: none;
}

.dark-theme .footer {
    background-color: #181818;
}

.dark-theme .footer-top,
.dark-theme .footer-middle {
    border-color: #282828;
}

.dark-theme .sticky-footer .header-search.show,
.dark-theme .sticky-footer .header-search:hover {
    color: #fff;
}

/*.logo-footer, .logo-footer img {
  display: block;
}*/

.footer-top {
    padding: 3.5rem 0 3.1rem;
}

.footer-middle {
    padding: 50px 0px;
}

.footer-middle .widget {
    margin-bottom: 3rem;
}

.footer-middle .widget-title {
    padding: 0;
    margin-bottom: 1.6rem;
    font-family: inherit;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    text-transform: initial;
    color: #fff;
}

.footer-middle .widget-body {
    padding: 8px 0 0;
    color: #a6a6a6;
    font-size: 1.3rem;
    font-family: var(--font-family-poppins);
}

.footer-middle .widget-body li {
    line-height: 1.2;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    color: #fff;
}

.footer-middle .widget-body li:last-child {
    margin-bottom: 0;
}

.footer-middle .widget-instagram .widget-body {
    padding-top: 0;
    margin: -5px;
}

.footer-middle .widget-instagram .col-3 {
    padding: 5px;
}

.footer-middle .widget-instagram img {
    display: block;
    width: 100%;
    height: auto;
}

.footer-middle label {
    font-weight: 700;
    text-transform: uppercase;
    color: #777;
    margin-bottom: 1rem;
}

.widget.widget-info a {
    font-weight: 500;
}

.footer-main {
    padding: 2.6rem 0 0.4rem;
}

.footer-bottom {
    padding: 2rem 0rem;
    border-top: 1px solid #333;
}

.footer-bottom,
.footer-bottom .container,
.footer-bottom .container-fluid {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-bottom .footer-left,
.footer-bottom .footer-right {
    flex: 1;
}

.footer-bottom .footer-left {
    display: flex;
}

.footer-bottom .footer-right {
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 991px) {
    .footer-top .logo-footer {
        margin-bottom: 2.7rem;
    }

    .footer-top .logo-footer img {
        margin-left: auto;
        margin-right: auto;
    }

    .newsletter-info {
        margin-bottom: 2rem;
    }

    .footer-middle {
        padding: 4.8rem 0 4.8rem;
    }

    .footer-middle .widget-body {
        padding: 0;
    }

    .footer-bottom,
    .footer-bottom>.container {
        display: block;
    }

    .footer-bottom .footer-left,
    .footer-bottom .footer-right {
        justify-content: center;
    }

    .footer-bottom .footer-left,
    .footer-bottom .footer-center {
        margin-bottom: 2.7rem;
    }

    .footer-center {
        text-align: center;
    }
}

.footer .copyright {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    color: var(--white);
    letter-spacing: normal;
    line-height: 1.5;
    font-family: var(--font-family-poppins);
}

.widget-newsletter .newsletter-info {
    max-width: 45rem;
    width: 100%;
}

.widget-newsletter .input-wrapper-inline {
    height: 4.9rem;
}

.widget-newsletter .input-wrapper {
    max-width: 48rem;
}

.widget-newsletter input {
    padding: 1rem 1.5rem;
    min-height: 100%;
    border: 0;
    border-radius: 5px;
    color: #000;
    background: #f4f4f4;
    font-family: var(--font-family-poppins);
    font-size: 16px !important;
}

.widget-newsletter .btn {
    padding: 1.2em 1.38em 1.07em;
    font-family: var(--font-family-poppins);
    font-size: 16px;
    position: relative;
    right: 0px;
    background: var(--base-color);
    border: none;
    color: #000;
    border-radius: 5px;
    top: 2px;
}

.btn:hover {
    background: var(--base-color);
    color: #000;
}

.widget-newsletter .btn i {
    font-size: 2.5rem;
    margin: -4px;
    margin-left: 2px;
}

.widget-newsletter .widget-title {
    padding: 0;
    margin-bottom: 2rem;
    font-family: inherit;
    font-size: 20px;
    font-family: var(--font-family-poppins);
    font-weight: 600;
    letter-spacing: 0;
    line-height: 30px;
    text-transform: inherit;
    color: #fff;
}

.widget-newsletter p {
    margin-bottom: 2rem;
    font-family: inherit;
    font-size: 16px;
    font-family: var(--font-family-poppins);
    letter-spacing: normal;
    line-height: 2;
    color: var(--white);
    margin-top: -1px;
}

.footer .widget-category .category-box {
    display: flex;
    flex-wrap: wrap;
    align-items: end;
    margin-bottom: 2rem;
}

.footer .widget-category .category-name {
    margin-bottom: 0;
    margin-right: 2.4rem;
    font-size: 1.4rem;
    letter-spacing: 0;
    color: #ccc;
}

.footer .widget-category a {
    display: inline-block;
    position: relative;
    margin-right: 2rem;
    font-size: 1.3rem;
    font-weight: 400;
    color: #999;
}

.footer .widget-category a::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    border-bottom: 1px solid;
    transform: scaleX(0);
    transition: transform 0.3s;
}

.footer .widget-category a:hover::before {
    transform: scale(1);
}

.footer .widget-category a:not(:last-child)::after {
    content: "";
    position: absolute;
    height: 14px;
    margin-left: 1rem;
    top: 50%;
    border-left: 1px solid #454545;
    transform: translateY(-50%);
}

.footer .widget-about .logo-footer {
    display: block;
    margin-bottom: 5rem;
}

.footer .widget-about p {
    margin-bottom: 3rem;
    color: #999;
    line-height: 1.85;
    letter-spacing: -0.025em;
}

.footer .widget-about .widget-body {
    padding: 0;
}

.payment img {
    display: block;
}

.sticky-footer {
    display: flex;
}

.sticky-footer>* {
    flex: 1;
}

.sticky-footer .search-toggle {
    padding: 0;
    color: inherit;
}

.sticky-footer .header-search.show,
.sticky-footer .header-search:hover {
    color: #222;
}

.sticky-footer .hs-toggle .input-wrapper {
    min-width: 29rem;
    right: 1.5rem;
    margin-bottom: 2rem;
}

.sticky-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.5rem 0 1.3rem;
}

.sticky-link i {
    width: 2.3rem;
    height: 2.4rem;
    text-align: center;
    font-size: 2.3rem;
    line-height: 1;
    transform: translateY(0);
    transition: transform 0.35s ease;
}

.sticky-link i.d-icon-search {
    font-size: 1.9rem;
}

.sticky-link span {
    margin-top: 0.8rem;
    font-size: 1rem;
    line-height: 1;
    letter-spacing: 0.025em;
    text-transform: uppercase;
}

.sticky-link.active {
    color: #222;
}

.sticky-link:hover {
    color: #222;
}

.sticky-link:hover i {
    transform: translateY(-5px);
}

@media (min-width: 576px) {
    .sticky-footer {
        padding: 0 4rem;
    }
}

@media (min-width: 768px) {
    .sticky-footer {
        display: none;
    }
}

@media (max-width: 991px) {
    .footer-middle .row>div:last-child .widget {
        margin-bottom: 1rem;
    }
}

/* 8. pages */
/* -------------------------------------------
    Single Product Page
---------------------------------------------- */
.cart-added-alert {
    display: none;
    margin-bottom: 1rem;
}

.container>.cart-added-alert:first-child {
    margin-top: -1rem;
}

.cart-added-alert span {
    color: #222;
}

.cart-added-alert .btn-success,
.cart-added-alert span {
    vertical-align: middle;
}

.product-details .product-navigation {
    padding: 0.2rem 2px 0.3rem;
}

.product-gallery {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3rem;
    position: sticky;
}

.product-gallery.product-gallery-sticky {
    top: 2rem;
    padding-bottom: 3rem;
    transition: top 0.3s;
}

.sticky-header-active .product-gallery.product-gallery-sticky {
    top: 8rem;
}

.product-gallery.row>* {
    display: flex;
    align-items: center;
}

.product-gallery.row .product-image-full {
    right: 2rem;
}

.product-image-full {
    display: block;
    position: absolute;
    padding: 1rem;
    right: 1rem;
    bottom: 1rem;
    color: #999;
    font-size: 2rem;
    line-height: 1;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
}

:hover>.product-image-full {
    opacity: 1;
}

.product-navigation {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 2px 1.1rem;
}

.product-navigation .breadcrumb {
    margin: 0 2rem 1rem 0;
    font-size: inherit;
}

.product-navigation .product-nav {
    margin-bottom: 1rem;
}

.product-nav {
    display: flex;
    position: relative;
    color: #999;
}

.product-nav i {
    vertical-align: middle;
    font-size: 1.9rem;
    line-height: 0;
}

.product-nav li+li {
    margin-left: 2rem;
}

.product-nav .product-nav-prev>a,
.product-nav .product-nav-next>a {
    display: flex;
    align-items: center;
}

.product-nav .product-nav-prev i {
    margin-right: 2px;
}

.product-nav .product-nav-next i {
    margin-left: 2px;
}

.product-nav .product-name {
    padding-right: 0;
    color: #666;
    font-size: 1.2rem;
    font-weight: 400;
    white-space: normal;
}

.product-nav li:hover .product-nav-popup {
    opacity: 1;
    visibility: visible;
    transform: none;
}

.product-nav-popup {
    position: absolute;
    top: 126%;
    right: 16px;
    z-index: 30;
    width: 120px;
    padding: 0 5px 5px;
    line-height: 1.5;
    text-align: center;
    background-color: #fff;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.1);
    visibility: hidden;
    opacity: 0;
    transform: scale(0.9);
    transform-origin: top;
    transition: opacity 0.3s, transform 0.3s;
}

.product-nav-popup::before {
    content: "";
    position: absolute;
    top: -8px;
    right: 18px;
    width: 16px;
    height: 16px;
    transform: rotate(45deg);
    background-color: #fff;
    box-shadow: inherit;
}

.product-nav-popup img {
    position: relative;
    padding-top: 5px;
    background-color: #fff;
}

.product-nav-prev .product-nav-popup::before {
    right: 6.6rem;
}

.product-tabs .nav-link {
    padding: 1rem 2.5rem;
    line-height: 1.2;
}

.product-tabs .nav-item {
    margin-right: 0;
}

.product-tabs .tab-pane {
    padding: 1.5rem 0.2rem;
    line-height: 1.86;
}

.product-footer+.product-tabs {
    margin-top: 2.5rem;
}

.product-tabs .tab-pane .list-type li,
.product-status .list-type li {
    padding-left: 3rem;
}

.product-status {
    line-height: 2;
}

#product-tab-description .description-title,
.card-description .description-title {
    font-size: 2rem;
    line-height: 24px;
}

#product-tab-description .description-title~p,
.card-description .description-title~p {
    line-height: 1.86;
}

#product-tab-description li,
.card-description li {
    position: relative;
    padding-left: 2.4rem;
}

#product-tab-description li::before,
.card-description li::before {
    position: absolute;
    display: block;
    left: 3px;
    top: 2px;
    content: "";
    font-family: "riode";
    font-weight: 700;
    font-size: 1.2rem;
    color: #222;
}

#product-tab-description .table tbody,
.card-description .table tbody {
    line-height: 1.8;
}

#product-tab-description .table th,
#product-tab-description .table td,
.card-description .table th,
.card-description .table td {
    padding: 10px;
    text-align: left;
    vertical-align: top;
    border-bottom: 1px solid #e9e9e9;
}

@media (min-width: 768px) {

    #product-tab-description .pl-md-6,
    .card-description .pl-md-6 {
        padding-left: 3rem !important;
    }
}

#product-tab-description .btn-play,
.card-description .btn-play {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    justify-content: center;
    padding: 0;
    font-size: 35px;
    width: 78px;
    height: 78px;
    border-radius: 50%;
    background-color: #222;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    color: #fff;
}

#product-tab-description .icon-box-icon,
.card-description .icon-box-icon {
    font-size: 3rem;
    border-color: #cdcdcd;
}

#product-tab-description .icon-box-content p,
.card-description .icon-box-content p {
    font-size: 1.3rem;
    color: #999;
}

#product-tab-description .divider,
.card-description .divider {
    margin: 1.3rem 0 1.3rem;
    height: 4.4rem;
}

#product-tab-description figure img,
.card-description figure img {
    vertical-align: middle;
}

#product-tab-description .icon-box-icon,
.card-body .icon-box-icon {
    width: 5.1rem;
    height: 5.1rem;
    margin-right: 2rem;
}

#product-tab-description .icon-box-icon .d-icon-truck,
.card-body .icon-box-icon .d-icon-truck {
    font-size: 3.6rem;
}

#product-tab-description .icon-box-title,
.card-body .icon-box-title {
    margin-bottom: 0.2rem;
}

#product-tab-description .icon-box-wrap {
    max-width: 559px;
}

#product-tab-additional label,
.card-additional label {
    display: inline-block;
    min-width: 20rem;
    color: #222;
}

#product-tab-additional p,
.card-additional p {
    display: inline-block;
    margin-bottom: 0;
}

#product-tab-size-guide,
.card-sizeguide {
    display: block;
}

#product-tab-size-guide .size-image,
.card-sizeguide .size-image {
    flex: 0 0 36%;
    text-align: center;
    padding: 0 10px;
}

#product-tab-size-guide .size-table,
.card-sizeguide .size-table {
    flex: 1;
    font-size: 16px;
    text-transform: uppercase;
    color: #21293c;
}

#product-tab-size-guide .size-table thead th,
.card-sizeguide .size-table thead th {
    padding: 30px 0 30px 10px;
    font-weight: 600;
    background: #f4f4f2;
    text-align: left;
}

#product-tab-size-guide .size-table tbody tr>*,
.card-sizeguide .size-table tbody tr>* {
    padding: 12px 0 12px 10px;
    font-weight: 700;
    text-align: left;
}

#product-tab-size-guide .size-table tbody tr:nth-child(2n),
.card-sizeguide .size-table tbody tr:nth-child(2n) {
    background-color: #ebebeb;
}

#product-tab-reviews .comments-list li,
.card-reviews .comments-list li {
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    border-bottom: 1px solid #eee;
}

#product-tab-reviews .comments,
.card-reviews .comments {
    padding: 1rem;
}

#product-tab-reviews .comment p,
.card-reviews .comment p {
    margin-bottom: 0;
}

#product-tab-reviews .comment-rating,
.card-reviews .comment-rating {
    right: 0;
    top: 3rem;
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

#product-tab-reviews .ratings-full,
.card-reviews .ratings-full {
    margin-right: 0;
}

#product-tab-reviews .reply,
.card-reviews .reply {
    margin-bottom: 0;
}

#product-tab-reviews .reply p,
.card-reviews .reply p {
    margin-bottom: 2.5rem;
    font-size: 1.3rem;
    color: #666;
}

#product-tab-reviews .reply .btn,
.card-reviews .reply .btn {
    padding: 1.2em 2.97em;
}

#product-tab-reviews .reply .btn i,
.card-reviews .reply .btn i {
    font-size: 1.9rem;
    margin-left: 1rem;
}

#product-tab-reviews .form-checkbox,
.card-reviews .form-checkbox {
    font-size: 1.3rem;
}

#product-tab-reviews .form-control-label,
.card-reviews .form-control-label {
    padding-left: 0;
}

#product-tab-reviews .form-control-label::before,
.card-reviews .form-control-label::before {
    display: inline-block;
    position: relative;
    transform: none;
    vertical-align: middle;
    margin-right: 0.5rem;
}

#product-tab-reviews .btn.btn-link.active,
#product-tab-reviews .btn.btn-link:hover,
.card-reviews .btn.btn-link.active,
.card-reviews .btn.btn-link:hover {
    color: #4dae67;
}

#product-tab-reviews {
    padding-top: 3rem;
}

.product-details .collapse::after {
    color: #4dae67;
}

.accordion-border .card {
    padding: 0 1.3rem;
}

.rating-form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 2.2rem;
}

.rating-form label {
    margin-right: 1rem;
}

.rating-stars {
    display: flex;
    position: relative;
    height: 14px;
    font-size: 1.4rem;
}

.rating-stars a {
    color: #999;
    text-indent: -9999px;
    letter-spacing: 1px;
    width: 16px;
    letter-spacing: 0.2em;
}

.rating-stars a:before {
    content: "";
    position: absolute;
    left: 0;
    height: 14px;
    line-height: 1;
    font-family: "riode";
    text-indent: 0;
    overflow: hidden;
    white-space: nowrap;
}

.rating-stars a.active:before,
.rating-stars a:hover:before {
    content: "";
    color: #fd990b;
}

.rating-stars .star-1 {
    z-index: 10;
}

.rating-stars .star-2 {
    z-index: 9;
}

.rating-stars .star-3 {
    z-index: 8;
}

.rating-stars .star-4 {
    z-index: 7;
}

.rating-stars .start-5 {
    z-index: 6;
}

.rating-stars .star-1:before {
    width: 20%;
}

.rating-stars .star-2:before {
    width: 40%;
}

.rating-stars .star-3:before {
    width: 60%;
}

.rating-stars .star-4:before {
    width: 80%;
}

.rating-stars .star-5:before {
    content: "";
}

.product-sticky-content:not(.fixed) .sticky-product-details {
    display: none;
}

.product-sticky-content:not(.fixed) .container {
    padding: 0;
}

.product-sticky-content.fixed {
    padding: 1rem 0;
}

.product-sticky-content.fixed .container {
    display: flex;
    align-items: center;
}

.product-sticky-content.fixed .product-form {
    flex: 1;
}

.product-sticky-content.fixed .product-form-group {
    justify-content: flex-end;
}

.product-sticky-content.fixed .product-form,
.product-sticky-content.fixed .product-form-group>* {
    margin-bottom: 0;
}

.product-sticky-content.fixed .product-form>label {
    display: none;
}

.product-sticky-content.fixed .input-group {
    margin-right: 0.8rem;
}

.sticky-product-details {
    display: flex;
    align-items: center;
}

.sticky-product-details img {
    display: block;
    width: 9rem;
    height: 9rem;
}

.sticky-product-details .product-image {
    margin-right: 1rem;
    max-width: 9rem;
}

.sticky-product-details .product-title {
    margin-bottom: 0.5rem;
    font-weight: 700;
}

.sticky-product-details .product-price {
    font-weight: 600;
}

.sticky-product-details .product-title,
.sticky-product-details .product-price {
    font-size: 2rem;
}

.sticky-product-details .product-price,
.sticky-product-details .ratings-container {
    margin-bottom: 0;
}

.sticky-product-details .product-info {
    display: flex;
}

.sticky-product-details .product-price {
    margin-right: 2rem;
}

aside .service-list {
    padding: 0 2rem;
    border: 1px solid #eee;
}

aside .service-list>* {
    justify-content: flex-start;
    padding: 2.2rem 0;
}

aside .service-list> :not(:last-child) {
    border-bottom: 1px solid #eee;
}

aside .service-list i {
    margin-left: 5px;
    font-size: 3.2rem;
}

aside .service-list .icon-box-title {
    font-size: 1.5rem;
    letter-spacing: 0;
    line-height: 1.2;
}

aside .service-list p {
    line-height: 1.2;
}

aside .service-list .icon-box1 i {
    margin-left: 0;
    font-size: 3.7rem;
}

aside .banner-content {
    left: 5%;
    top: 12%;
    width: 90%;
}

aside .banner-subtitle {
    font-weight: 500;
    font-size: 16px;
    color: #777777;
}

aside .banner-title {
    font-size: 2.3rem;
    line-height: 1.4em;
}

aside .owl-nav-top .owl-nav {
    top: -5.1rem;
}

aside .owl-nav-top .owl-nav i {
    padding: 0.1rem;
    font-size: 1.2rem;
}

aside .owl-nav-top .owl-nav i::before {
    font-weight: 600;
}

.review-form-section .review-medias {
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.review-form-section .review-medias>.btn {
    display: flex;
    align-items: center;
}

.review-form-section .file-input {
    position: relative;
    margin-right: 2rem;
    padding: 0;
    width: auto;
    background-repeat: no-repeat;
    background-size: cover;
}

.review-form-section .file-input-wrapper {
    display: block;
    width: 80px;
    height: 80px;
    background-repeat: no-repeat;
    background-size: cover;
}

.review-form-section input[type="file"] {
    width: 0 !important;
    height: 0 !important;
    overflow: hidden;
    opacity: 0;
}

.review-form-section .btn-action {
    position: absolute;
    right: -10px;
    top: -10px;
    height: 22px;
    width: 22px;
    display: flex;
    justify-content: center;
    line-height: 22px;
    font-size: 10px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: box-shadow 0.3s;
}

.review-form-section .btn-action:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
}

.review-form-section .btn-upload::before {
    content: "";
}

.review-form-section .btn-remove {
    top: auto;
    bottom: -10px;
}

.review-form-section .btn-remove::before {
    content: "";
    font-size: 12px;
}

.review-medias {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.avg-rating-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.avg-rating-container mark {
    font-size: 6rem;
    font-weight: 700;
    letter-spacing: -0.025em;
    line-height: 1;
    color: #4dae67;
    background: none;
    margin-right: 1.8rem;
}

.avg-rating-container .rating-reviews:hover {
    color: #999;
}

.ratings-item {
    display: flex;
    align-items: center;
}

.ratings-item .ratings-container {
    margin-right: 1.8rem;
}

.ratings-item .progress-value {
    margin-left: 2rem;
}

.rating-percent {
    background: #eee;
    height: 0.8rem;
    width: 20rem;
    max-width: 100%;
    border-radius: 1rem;
}

.rating-percent span {
    display: block;
    height: 100%;
    border-radius: inherit;
    background: #999;
}

.comments .toolbox {
    padding: 0 0 1rem;
    margin-bottom: 3rem;
    border-bottom: 1px solid #eee;
}

.comments .toolbox .btn {
    font-size: 1.3rem;
    padding: 0.82em 1.08em;
}

.comments .toolbox .toolbox-sort .form-control {
    min-width: 15rem;
}

.comments .toolbox-pagination {
    border: none;
}

.comments .page-item {
    margin-bottom: 0;
}

.comments .file-input-wrappers {
    display: flex;
}

.comments .file-input-wrappers>* {
    width: 6rem;
    height: 6rem;
    margin-right: 1rem;
    cursor: zoom-in;
}

.comments .file-input-wrappers img {
    height: 100%;
}

.comments .feeling i {
    font-size: 1.4rem;
}

.comments .btn-play {
    display: flex;
    justify-content: center;
    align-items: center;
}

.comments .btn-play i {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    background: rgba(0, 0, 0, 0.7);
}

.review-form-section .review-form-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    right: -100%;
    background-color: #fff;
    z-index: 1101;
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.1);
    padding: 3rem;
    transition: right 0.3s;
    overflow: auto;
}

.review-form-section .review-overlay {
    position: fixed;
    left: 0;
    width: 100vw;
    top: -10vh;
    height: 120vh;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1100;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
}

.review-form-section.opened .review-form-wrapper {
    right: 0;
}

.review-form-section.opened .review-overlay {
    opacity: 1;
    visibility: visible;
}

@media (max-width: 575px) {

    #product-tab-reviews .comments-list>ul,
    .card-body .comments-list>ul {
        padding: 0;
    }

    #product-tab-reviews .comment-body,
    .card-body .comment-body {
        padding-left: 1.5rem;
    }

    #product-tab-additional label {
        min-width: 13rem;
    }
}

@media (min-width: 576px) {

    #product-tab-size-guide thead th:first-child,
    #product-tab-size-guide .size-table tbody th,
    .card-sizeguide thead th:first-child,
    .card-sizeguide .size-table tbody th {
        padding-left: 3rem;
    }

    #product-tab-size-guide {
        display: flex;
    }
}

@media (min-width: 992px) {
    .product-form select {
        width: 20rem;
    }

    .product-single .product-details {
        padding-left: 1rem;
    }

    .product-details.row {
        padding-left: 0;
    }

    .product-details.row> :last-child {
        padding-left: 2rem;
    }
}

@media (min-width: 1600px) {
    .container-fluid .product-thumbs.owl-carousel {
        width: calc(100% + 20px);
        margin: 0 -10px;
    }

    .container-fluid .product-thumb {
        margin: 0 10px;
    }

    .container-fluid .product-thumbs-wrap {
        margin-top: 20px;
    }

    .container-fluid .product-thumbs .owl-prev {
        left: 10px;
    }

    .container-fluid .product-thumbs .owl-next {
        right: 10px;
    }

    .container-fluid .pg-vertical .product-thumb {
        margin: 0 0 20px;
    }

    .container-fluid .pg-vertical .product-thumbs-wrap {
        margin: 0 20px 0 0;
    }

    .container-fluid .pg-vertical .product-single-carousel {
        max-width: calc(100% - 129px);
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    aside .service-list {
        padding: 0 1rem;
    }
}

@media (min-width: 768px) and (max-width: 1035px) {
    .product-single #product-tab-size-guide {
        display: block;
    }
}

@media (max-width: 991px) {
    aside .service-list .icon-box-side {
        flex-direction: row;
    }

    aside .service-list .icon-box-side .icon-box-icon {
        padding: 0;
        margin: 0 2rem 0 0;
    }

    aside .service-list .icon-box-side .icon-box-content {
        text-align: left;
    }
}

.product-sticky-both .btn-cart {
    margin-bottom: 1rem;
}

.product-sticky-both .product-action {
    display: flex;
}

.product-sticky-both .btn-wishlist {
    margin-bottom: 1rem;
}

@media (min-width: 992px) {
    .product-sticky-both .btn-cart {
        max-width: 100%;
    }

    .product-sticky-both .product-form .select-box,
    .product-sticky-both .product-form .p-relative {
        margin-right: 0;
        width: 100%;
    }

    .product-sticky-both .product-form select {
        flex: 1;
        width: 100%;
    }
}

@media (max-width: 991px) {

    #product-tab-description .icon-box-side,
    .card-body .icon-box-side {
        flex-direction: row;
    }

    #product-tab-description .icon-box-icon,
    .card-body .icon-box-icon {
        display: inline-flex;
        margin-bottom: 0;
        padding: 0;
    }

    #product-tab-description .icon-box-content,
    .card-body .icon-box-content {
        text-align: left;
    }

    .review-form-wrapper {
        width: 45rem;
    }
}

@media (max-width: 575px) {
    .review-form-wrapper {
        width: 30rem;
    }

    .review-form-wrapper .file-input-wrapper {
        width: 6rem;
        height: 6rem;
    }

    .review-form-wrapper .file-input {
        margin-right: 1rem;
    }
}

.dark-theme .product-nav .product-name,
.dark-theme .avg-rating-title,
.dark-theme .progress-value {
    color: #999;
}

.dark-theme .size-image img {
    filter: invert(1);
}

.dark-theme .size-table {
    color: #fff;
}

.dark-theme .size-table thead th {
    background: #999;
}

.dark-theme .size-table tbody tr:nth-child(2n) {
    background-color: #999;
}

.dark-theme .rating-percent {
    background: #999;
}

.dark-theme .rating-percent span {
    background: #fff;
}

/* -------------------------------------------
    Shop Page
        - Shop Banner

        - Toolbox
            (support toolbox, toolbox-left, toolbox-right)
            (support label, select-box)
        - Toolbox Item
            (support full)
        - Layout Button
        - Select Menu
            (support as sidebar's widget < 992px)
            (support toggle)
        - Select Items
        - Toolbox Pagination
        - Toolbox Horizontal
        - Toolbox Navigation
            (support as sidebar-fixed)
            (please place sidebar before toolbox: to know sidebar's close status)
---------------------------------------------- */
.shop-banner-default .banner-subtitle,
.shop-boxed-banner .banner-subtitle {
    margin-bottom: 0.6rem;
    font-size: 2em;
}

.shop-banner-default .banner-title,
.shop-boxed-banner .banner-title {
    margin-bottom: 2.4rem;
    font-size: 4em;
    letter-spacing: -0.035em;
}

.shop-banner-default .btn,
.shop-boxed-banner .btn {
    padding: 1.2em 2.8em;
    border-color: #919eb2;
}

.shop-banner-default .btn i,
.shop-boxed-banner .btn i {
    vertical-align: middle;
    margin-left: 0.7rem;
    font-size: 2.2rem;
}

.shop-banner-default .btn:hover,
.shop-banner-default .btn:active,
.shop-banner-default .btn:focus,
.shop-boxed-banner .btn:hover,
.shop-boxed-banner .btn:active,
.shop-boxed-banner .btn:focus {
    border-color: #fff;
}

.shop-banner-default {
    padding: 7.5rem 5.7% 8.3rem;
}

.shop-boxed-banner {
    padding: 7.4rem 7% 8.4rem;
}

.cph-header {
    min-height: 350px;
    height: auto;
}

.cph-header .category img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.cph-header .category-icon {
    padding: 1.7rem 1rem;
}

.toolbox,
.toolbox-left,
.toolbox-right,
.toolbox-item,
.toolbox-item-full {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.toolbox {
    position: relative;
    z-index: 21;
    justify-content: space-between;
}

.toolbox.toolbox-pagination {
    z-index: 20;
}

.toolbox {
    font-size: 1.3rem;
    line-height: 1.5;
    padding: 3.5rem 0.2rem 1rem;
    transition: margin 0.4s;
    background-color: transparent;
}

.toolbox.sticky-toolbox {
    padding-top: 3rem;
}

.toolbox>*:not(:last-child),
.toolbox-left>*:not(:last-child),
.toolbox-right>*:not(:last-child) {
    margin-right: 1.8rem;
}

.toolbox label {
    margin-right: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
}

.toolbox select {
    cursor: pointer;
    padding-top: 0.85rem;
    padding-bottom: 0.85rem;
    border-color: #ebebeb;
    z-index: 0;
}

.toolbox .select-box .form-control {
    color: #222;
}

.toolbox .select-box::before {
    font-size: 1rem;
    right: 1rem;
    z-index: 1;
}

.toolbox .toolbox-sort .form-control {
    max-width: 15rem;
    padding-left: 1.4rem;
}

.toolbox .toolbox-sort::before {
    right: 1.4rem;
    z-index: 2;
}

.toolbox-item {
    flex-wrap: nowrap;
    margin-bottom: 1rem;
}

.toolbox-item label {
    color: #222;
}

.toolbox-item-full {
    flex-basis: 100%;
}

.btn-layout {
    color: #dadada;
    font-size: 22px;
    padding: 0 2px;
}

.btn-layout:last-child {
    padding-right: 0;
}

.btn-layout:hover,
.btn-layout.active {
    color: #333;
}

.btn-layout+.btn-layout {
    margin-left: 2px;
}

.select-menu {
    color: #222;
}

.select-menu::before {
    right: 1.25em;
    font-size: 1.2rem;
    margin-top: 1px;
}

.select-menu ul,
.select-menu ul::before {
    content: "";
    position: absolute;
    border: 1px solid #eee;
    width: 11px;
}

.select-menu ul {
    top: 100%;
    z-index: 1000;
    width: 18rem;
    margin-top: 1rem;
    padding: 1rem 1.4rem 1rem;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
}

.select-menu ul::before {
    top: -6px;
    left: 22px;
    height: 11px;
    border-width: 1px 0 0 1px;
    background-color: #fff;
    transform: rotate(45deg);
}

.select-menu.opened>ul {
    opacity: 1;
    visibility: visible;
}

.dark-theme .select-menu {
    color: #999;
}

.dark-theme select.form-control {
    background-color: transparent;
}

.dark-theme .toolbox-item label {
    color: #999;
}

.dark-theme .toolbox .select-box .form-control {
    color: #999;
}

.dark-theme .btn-layout {
    color: #666;
}

.dark-theme .btn-layout:hover,
.dark-theme .btn-layout.active {
    color: #4dae67;
}

.select-menu select,
.select-menu-toggle {
    position: relative;
    border: 1px solid #ebebeb;
    padding: 0.8rem 4rem 0.8rem 1.4rem;
    font-size: 1.2rem;
    line-height: 1.5;
    letter-spacing: -0.03em;
}

.select-menu-toggle {
    border-radius: 2px;
}

.select-menu select {
    max-width: 17.2rem;
    padding: 0.85rem 2.6rem 0.85rem 0.9rem;
}

.select-item {
    padding: 6px 0.7rem 4px 1.2rem;
    color: #222;
    background: #eee;
    letter-spacing: -0.01em;
}

.select-item i {
    padding: 0.5rem 0.6rem 0.7rem;
    margin-left: 5px;
    font-size: 9px;
    vertical-align: middle;
}

.select-items {
    display: none;
}

.toolbox+.select-items {
    font-size: 1.2rem;
    margin: 2px 0 1.8rem;
}

.select-items>* {
    display: inline-block;
    margin: 0 0.8rem 0.5rem 0;
}

.select-items .filter-clean {
    margin-left: 1.2rem;
}

.sticky-content-wrapper+.select-items {
    font-size: 1.2rem;
    margin: 2px 0 1.8rem;
}

.toolbox-pagination {
    position: relative;
    margin-bottom: 1rem;
    padding: 2.5rem 0.2rem;
    border-top: 1px solid #eee;
}

.toolbox-horizontal .show-info {
    color: #999;
    font-size: inherit;
}

.toolbox-horizontal.fixed .sidebar-overlay,
.toolbox-horizontal.fixed .sidebar-content {
    min-height: 100vh;
}

.left-sidebar-toggle,
.sidebar-content .filter-actions .sidebar-toggle-btn,
.toolbox .top-sidebar-toggle,
.right-sidebar-toggle {
    padding: 0.61em 0.92em 0.61em;
    margin-right: 2rem;
    font-weight: 700;
    line-height: 1.2;
}

.left-sidebar-toggle .d-icon-filter-2,
.sidebar-content .filter-actions .sidebar-toggle-btn .d-icon-filter-2,
.toolbox .top-sidebar-toggle .d-icon-filter-2,
.right-sidebar-toggle .d-icon-filter-2 {
    font-size: 1.4rem;
}

.left-sidebar-toggle .d-icon-filter-2::before,
.sidebar-content .filter-actions .sidebar-toggle-btn .d-icon-filter-2::before,
.toolbox .top-sidebar-toggle .d-icon-filter-2::before,
.right-sidebar-toggle .d-icon-filter-2::before {
    font-weight: 600;
}

.toolbox .toolbox-left .left-sidebar-toggle {
    font-weight: 600;
}

.toolbox-item.right-sidebar-toggle {
    margin-right: 0;
}

.toolbox-wrap {
    display: flex;
    flex-direction: column-reverse;
    line-height: 1.3;
}

.toolbox-wrap .toolbox>* {
    line-height: 37px;
}

.toolbox-wrap .toolbox-layout {
    display: flex;
}

.toolbox-wrap option {
    text-transform: none;
}

.toolbox-wrap .toolbox-layout {
    display: flex;
}

.toolbox-wrap .sidebar-content .widget-title {
    padding-bottom: 1rem;
}

.sticky-toolbox.fixed {
    position: fixed;
    background-color: #fff;
    padding: 1rem 2rem 0;
}

.widget .widget-body .filter-actions {
    display: block;
    padding: 0 0 1.5rem 0.2rem;
}

.filter-price-text {
    font-size: 1.3rem;
    color: #222;
}

.filter-items a::before {
    content: "";
    position: absolute;
    border: 1px solid #999;
    width: 18px;
    height: 18px;
    border-radius: 2px;
    top: 50%;
    transform: translateY(-50%);
    left: -2.7rem;
    font-size: 0.7em;
    font-weight: 900;
    font-family: "Font Awesome 5 Free";
    text-align: center;
    text-indent: 1px;
    line-height: 1.7em;
}

@media (-webkit-min-device-pixel-ratio: 1.5),
(min--moz-device-pixel-ratio: 1.5),
(min-device-pixel-ratio: 1.5) {
    .filter-items a::before {
        text-indent: 0;
    }
}

.toolbox .btn-link {
    padding: 0;
}

.filter-clean {
    font-size: 1.4rem;
    margin-left: auto;
    color: #222;
    transition: color 0.3s;
}

.filter-clean:hover {
    color: #4dae67;
}

.shop-sidebar {
    position: relative;
}

.shop-sidebar .btn-outline:not(:hover) {
    color: #4dae67;
    background-color: #fff;
}

.shop-sidebar .sidebar-toggle-btn,
.shop-sidebar .left-sidebar-toggle {
    padding: 0.8em 1.04em;
}

.shop-sidebar .filter-actions {
    display: flex;
    align-items: center;
    padding-right: 0.6rem;
    margin-bottom: 2rem;
}

.btn-icon-right i {
    margin-left: 0.8rem;
    font-size: 1.8rem;
}

@media (min-width: 576px) {
    .toolbox-horizontal .toolbox-left {
        margin-right: auto;
    }

    .mr-sm-auto {
        margin-right: auto !important;
    }
}

@media (min-width: 992px) {
    .sidebar-fixed {
        transition: margin 0.4s;
    }

    .sidebar-fixed .filter-actions:first-child {
        padding-top: 3rem;
    }

    .sidebar-fixed+.main-content {
        transition: flex-basis 0.4s, max-width 0.4s;
    }

    .sticky-sidebar-wrapper.closed {
        height: 0;
    }

    .closed.sidebar {
        margin-left: -25%;
    }

    .closed.right-sidebar {
        margin-right: -25%;
    }

    .closed+.main-content {
        max-width: 100%;
        flex-basis: 100%;
    }

    .main-content-wrap {
        overflow: hidden;
    }

    .sidebar-toggle-remain .toggle-remain {
        position: absolute;
        transition: 0.4s;
        left: 0;
    }
}

@media (min-width: 992px) and (-ms-high-contrast: active),
(min-width: 992px) and (-ms-high-contrast: none) {
    .sidebar-toggle-remain .toggle-remain {
        top: 2.7rem;
    }
}

@media (min-width: 992px) {
    .sidebar-toggle-remain .filter-clean {
        line-height: 3.7rem;
    }
}

@media (min-width: 992px) {
    .sidebar-toggle-remain.sidebar .toggle-remain i {
        transition: transform 0.3s;
    }

    .sidebar-toggle-remain.sidebar.closed .toggle-remain {
        left: calc(100% + 30px);
    }

    .sidebar-toggle-remain.sidebar.closed .toggle-remain i {
        transform: rotateY(180deg);
    }

    .sidebar-toggle-remain.sidebar.closed+*>.toolbox:first-child {
        margin-left: 10.8rem;
    }

    .sidebar-toggle-remain.sidebar .toggle-remain::before {
        content: "";
        position: absolute;
        background-color: #fff;
        right: calc(100% + 2px);
        width: 20px;
        bottom: -2px;
        top: -2px;
    }
}

@media (min-width: 992px) {
    .sidebar-toggle-remain.right-sidebar.closed .toggle-remain {
        left: -3rem;
        transform: translateX(-100%);
    }

    .sidebar-toggle-remain.right-sidebar.closed .toggle-remain i::before {
        content: "";
    }

    .sidebar-toggle-remain.right-sidebar.closed+*>.toolbox:first-child {
        padding-right: 11rem;
    }
}

@media (min-width: 992px) {
    .toolbox-wrap .sidebar-content {
        border-top: 3px solid #eee;
        transition: border 0.3s;
    }

    .toolbox-wrap .sidebar-content>div {
        border-bottom: 3px solid #eee;
    }

    .toolbox-wrap .sidebar-content .widget {
        border: none;
    }
}

@media (min-width: 992px) {
    .shop-sidebar .filter-actions {
        margin-bottom: 0;
    }
}

@media (min-width: 992px) {
    .toolbox .sidebar-content {
        max-width: none;
    }
}

@media (min-width: 992px) {
    .toolbox-horizontal .sidebar-fixed .sidebar-content {
        padding-bottom: 0;
    }

    .toolbox-horizontal .widget {
        position: relative;
        margin-bottom: 1rem;
        color: #222;
        border-bottom: none;
    }

    .toolbox-horizontal .widget .widget-title {
        position: relative;
        border: 1px solid #ebebeb;
        padding: 8px 4rem 8px 1.4rem;
        margin-bottom: 0;
        font-size: 1.2rem;
        line-height: 1.5;
        font-weight: 400;
        text-transform: capitalize;
        border-radius: 2px;
    }

    .toolbox-horizontal .widget::before {
        content: "";
        font-family: "Font Awesome 5 free";
        position: absolute;
        top: 50%;
        right: 1.25em;
        margin-top: 1px;
        transform: translateY(-50%);
        font-size: 1.2rem;
        font-weight: 900;
    }

    .toolbox-horizontal .widget.opened>ul {
        opacity: 1;
        visibility: visible;
    }

    .toolbox-horizontal .widget ul,
    .toolbox-horizontal .widget ul::before {
        content: "";
        position: absolute;
        border: 1px solid #eee;
    }

    .toolbox-horizontal .widget ul {
        top: 100%;
        z-index: 1000;
        width: 21rem;
        margin-top: 1rem;
        padding: 1rem 1.4rem;
        background-color: #fff;
        opacity: 0;
        visibility: hidden;
    }

    .toolbox-horizontal .widget ul::before {
        top: -6px;
        left: 22px;
        height: 11px;
        width: 11px;
        border-width: 1px 0 0 1px;
        background-color: #fff;
        transform: rotate(45deg);
    }

    .toolbox-horizontal .widget ul li {
        display: flex;
        justify-content: space-between;
    }
}

@media (min-width: 992px) {
    .toolbox-wrap .filter-actions:first-child {
        padding: 0;
    }

    .toolbox-wrap .sidebar-toggle-btn {
        display: none;
    }

    .toolbox-wrap>.closed {
        margin: 0;
    }

    .toolbox-wrap>.closed .sidebar-content {
        display: none;
        border-color: transparent;
        background: transparent;
    }

    .toolbox-wrap .filter-clean {
        display: none;
        position: absolute;
        right: 0;
        top: -40px;
    }
}

@media (min-width: 1200px) {
    .select-menu:not(:last-child):not(.toolbox-show) {
        margin-right: 2rem;
    }

    .toolbox .shop-sidebar {
        margin-right: 2rem;
    }
}

@media (max-width: 991px) {
    .toolbox .sidebar-fixed {
        margin-right: 0;
    }

    .toolbox .sidebar-content {
        display: block;
    }

    .sidebar-content .select-menu {
        display: block;
        margin: 0;
        font-size: 1.4rem;
    }

    .sidebar-content .select-menu ul {
        position: static;
        width: 100%;
        padding: 0;
        border: 0;
        color: #666;
        margin: 0 0 1.1rem;
        opacity: 1;
        visibility: visible;
    }

    .sidebar-content .select-menu ul a::before {
        width: 18px;
        height: 18px;
        margin-top: 0;
    }

    .sidebar-content .select-menu::before,
    .sidebar-content .select-menu ul::before,
    .sidebar-content .select-menu>a::before {
        content: none;
    }

    .sidebar-content .select-menu-toggle {
        display: block;
        border-width: 3px 0 0;
        padding: 2rem 2px 1.6rem;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: capitalize;
    }

    .sidebar-content .toolbox-item:first-child a {
        border: none;
    }
}

@media (max-width: 767px) {

    .toolbox-item label,
    .toolbox-item>span,
    .toolbox-item.show-info {
        display: none;
    }
}

@media (max-width: 575px) {
    .toolbox-item.select-box {
        margin-right: 0;
    }

    .toolbox-item.select-box~.toolbox-item.right-sidebar-toggle {
        margin-left: 1rem;
    }

    .toolbox-item.toolbox-layout {
        display: none;
    }

    .toolbox .toolbox-left .left-sidebar-toggle,
    .sticky-sidebar .filter-actions .sidebar-toggle-btn {
        margin-right: 1rem;
    }

    .toolbox .toolbox-left {
        margin: 0;
    }

    .toolbox .left-sidebar-toggle {
        margin-right: 0.5rem;
    }

    .toolbox .toolbox-sort .form-control {
        max-width: 12.8rem;
    }

    .toolbox-horizontal {
        justify-content: flex-start;
        flex-direction: row;
    }

    .toolbox-horizontal .toolbox-left {
        margin-right: auto;
    }

    .toolbox-horizontal .toolbox-right {
        margin-left: auto;
    }

    .toolbox-horizontal .toolbox-item.toolbox-show {
        margin-right: 0;
    }

    .toolbox-pagination {
        flex-direction: column;
    }

    .toolbox-pagination>.show-info {
        margin-right: 0;
    }

    .toolbox-pagination>*:not(:last-child) {
        margin-bottom: 1rem;
    }
}

@media (max-width: 479px) {
    .sticky-toolbox.fixed {
        padding: 1rem 1.5rem 0;
    }

    .toolbox {
        padding-right: 0;
        padding-left: 0;
    }

    .toolbox .toolbox-sort .form-control {
        max-width: 12.5rem;
        padding-left: 1rem;
    }

    .toolbox select {
        padding-top: 0.63rem;
        padding-bottom: 0.63rem;
        padding-right: 2rem;
    }

    .toolbox .btn.toolbox-item,
    .toolbox .btn-sm.toolbox-item {
        font-size: 1.2rem;
        padding: 0.61em 0.92em 0.61em;
    }
}

/* 9. Demos */
/* Demo Food2 */
@media (min-width: 992px) {
    .text-lg-end {
        text-align: right !important;
    }

    .text-lg-start {
        text-align: left !important;
    }

    .ml-lg-auto {
        margin-left: auto !important;
    }

    .offset-lg-7 {
        margin-left: calc(700% / 12);
    }
}

h2 {
    /* font-family: Kalam, sans-serif; */
    font-weight: 700;
    line-height: 1.2;
}

.icon-arrow-right i {
    margin-left: 7px;
}

.drop-shadow {
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.07);
}

.header-top .header-right .btn {
    padding: 0.693em 1.4em;
}

.header-top .dropdown {
    font-size: 1.2rem;
}

.header-top .dropdown>a::after {
    font-size: 0.7em;
}

.divider {
    background-color: #e1e1e1;
}

.cart-dropdown.type2 .cart-count {
    top: 0;
    right: -1.1rem;
    width: 1.9rem;
    height: 1.9rem;
    font-size: 1.1rem;
}

.header-right i {
    margin-bottom: 2px;
}

.product.product-cart .btn-close i {
    margin-top: 1px;
}

.search-toggle i {
    font-size: 2rem;
}

.cart-dropdown i {
    font-size: 2.3rem;
}

.btn-md-ellipse {
    /*padding: 1.216em 2.646em;*/
    padding: 10px;
}

.submenu>a::after {
    font-size: 1rem;
    margin-left: 0.9rem;
}

.menu>li>a::after {
    margin-left: 0.9rem;
}

.dropdown-box {
    z-index: 1002;
}

/* .intro-section img {
    min-height: 54rem;
    object-fit: cover;
} */

.intro-section .banner-subtitle {
    font-size: 2rem;
    letter-spacing: 2px;
}

.intro-section .banner-title {
    font-size: 6rem;
    line-height: 1.13em;
}

.intro-section p {
    font-size: 1.6rem;
    line-height: 1.625em;
    margin-bottom: 8px;
}

/*.intro-slide1 .banner-content 
{
    margin: 1.9rem 0 0 2px;
}*/
.intro-slide1 sup {
    font-size: 0.58em;
}

.intro-slide2 .btn {
    padding: 1.43em 3.41em;
}

.intro-slide3 h3 {
    font-size: 4.4em;
    letter-spacing: -0.4px;
}

.owl-nav-arrow .owl-nav .owl-next {
    right: 4.75%;
    color: rgba(34, 34, 34, 0.5);
}

.owl-nav-arrow .owl-nav .owl-prev {
    left: 4.75%;
    color: rgba(34, 34, 34, 0.5);
}

.service-carousel {
    margin-right: -2px;
    width: calc(100% + 2px);
}

.service-carousel .icon-box {
    padding: 3rem 0 2.8rem;
}

.service-carousel .owl-item:not(:last-child)::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    border-left: 1px solid #eee;
    height: 3.7rem;
    transform: translateY(-50%);
}

.service-carousel .icon-box-icon {
    font-size: 3.7rem;
}

.service-carousel .icon-box-icon.d-icon-truck {
    font-size: 4.6rem;
}

.service-carousel p {
    margin-top: -6px;
}

.category-section .category-content {
    padding: 2.7rem 0;
}

.category-section .category-name {
    font-size: 2rem;
    color: #222;
}

.category-section .category-count {
    font-size: 1.4rem;
}

.category-section .category img {
    transition: transform 0.35s;
}

.category-section .category:hover img {
    transform: translateY(-7px);
}

/*.arrivals-section {
    margin-bottom: 3.7rem;
}*/

.product-details {
    padding-top: 1.6rem;
}

.product-details .product-price {
    margin-bottom: 2px !important;
}

.product-details .btn-cart {
    height: 4rem;
}

.ratings-full+.rating-reviews {
    margin-left: 7px;
}

.ratings-container {
    letter-spacing: 0.22em;
    /*margin-bottom: 1.8rem;*/
}

.banner-section h2 {
    font-size: 2.5rem;
}

.banner-section span {
    font-size: 3.8rem;
}

.banner-section img {
    min-height: 45rem;
    object-fit: cover;
}

.banner-section .text-bottom {
    bottom: 2.9rem;
}

.banner-section .category img {
    border-radius: 0;
}

.banner-section .category h4 {
    font-size: 2rem;
}

.banner-section .banner-subtitle {
    margin-bottom: 8px;
}

.about-circle .icon-box-title {
    font-size: 2rem;
    letter-spacing: -0.2px;
}

.about-circle .icon-box-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8.5rem;
    height: 8.5rem;
    margin-bottom: 1.4rem;
    border-radius: 50%;
}

.about-circle .row {
    padding-top: 1.8rem;
}

.about-circle .icon-box3 {
    margin-top: -4px;
}

.instagram {
    border-radius: 1rem;
}

.post-slider .owl-dots {
    margin-top: 0 !important;
}

.post-slider .btn {
    font-size: 1.3rem;
}

.owl-shadow-carousel .owl-stage-outer {
    margin: -1.8rem -2rem -2rem;
    padding: 2rem;
}

.brand-section .owl-stage {
    display: flex;
    align-items: center;
}

.brand-section .owl-stage img {
    width: auto;
    margin-left: auto;
    margin-right: auto;
}

.footer-middle .widget a {
    width: -webkit-fit-content;
    width: fit-content;
    font-family: var(--font-family-poppins);
    font-size: 16px;
    color: #fff !important;
    font-weight: 100 !important;
}

.footer-middle .col-account .widget-body li {
    color: #a6a6a6;
    margin-bottom: 11px;
}

.footer-middle .widget-newsletter {
    margin-bottom: 3.9rem;
}

.footer-middle .widget-newsletter .input-wrapper-inline {
    height: 4.9rem;
}

@media (min-width: 576px) {
    .header-middle .header-right {
        flex: 0;
    }
}

@media (min-width: 992px) {
    .footer-middle .col-lg-2 {
        max-width: 17.85%;
        flex: 0 0 17.85%;
    }

    .footer-middle .col-lg-3.col-contact {
        max-width: 21.05%;
        flex: 0 0 21.05%;
    }

    .footer-middle .col-lg-3.col-account {
        max-width: 19.5%;
        flex: 0 0 19.5%;
    }

    .footer-middle .col-lg-4 {
        max-width: 41.6%;
        flex: 0 0 41.6%;
    }
}

@media (max-width: 991px) {
    .intro-slide3 img {
        object-position: left 3%;
    }
}

.shop-page .banner-title {
    font-size: 5.5rem;
}

.shop-page .breadcrumb {
    padding-top: 1.1rem;
}

.shop-page .product-with-qty .btn-cart {
    max-width: 14rem;
}

.shop-page .product-with-qty .btn-cart i {
    display: inline-flex;
}

.shop-page .btn-sm {
    padding: 0.7em 1em;
}

.btn-layout {
    font-size: 19px;
    color: #ccc;
}

.pagination {
    margin-right: -2rem;
}

.product-single .product-details .btn-cart {
    height: 4.5rem;
}

.single-product.border-top {
    border-top: 1px solid #eee;
}

span.categories_title_name h5 {
    font-size: 20px;
    font-weight: 100 !important;
    color: #333;
    font-family: var(--font-family-poppins);
}

.container-fluid.p-0 {
    padding: 0px !important;
}

.megamenu ul li a,
.menu li a {
    color: #000 !important;
}

.header-right .mx-0.dropdown {
    padding: 0px 50px;
}

.header-user-details {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.header-user-details .user-name {
    padding: 0px 10px;
}